import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArray',
})
export class JoinArrayPipe implements PipeTransform {
  public transform<T>(array: T[], delimiter?: string): string {
    return array.filter((item) => !!item).join(delimiter || ', ');
  }
}
