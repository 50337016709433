import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { SvgModule } from '@ui/svg/svg.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { DragAndDropUploaderComponent } from './drag-and-drop-uploader.component';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { UploaderComponent } from './uploader.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    DirectivesModule,
    PipesModule,
    SvgModule,
    ButtonsModule,
    TooltipModule,
  ],
  declarations: [UploaderComponent, DragAndDropDirective, DragAndDropUploaderComponent],
  exports: [UploaderComponent, DragAndDropUploaderComponent],
})
export class UploaderModule {}
