import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { filter, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { contentAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { InputState } from '../input.state';
import { DateToggle, DatepickerPage } from './datepicker.types';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-datepicker app-bg-main',
  },
})
export class DatepickerComponent extends BaseObject implements OnInit, AfterViewInit {
  @Input() public datepicker: MatDatepicker<Date>;

  @Input() public maxDate: Date;
  @Input() public minDate: Date;

  @Output() public closeEvent = new EventEmitter<Date | void>();

  public readonly DateToggle = DateToggle;
  public readonly DatepickerPage = DatepickerPage;

  public toggleControl = new UntypedFormControl();
  public timeControl = new UntypedFormControl(this.dateHelper.now());
  public today = this.dateHelper.now();
  public page: DatepickerPage = DatepickerPage.Calendar;
  public previousPage: DatepickerPage = DatepickerPage.Calendar;
  public selected: Date;

  public dateToggles: { value: DateToggle; name: string }[] = [
    {
      value: DateToggle.Today,
      name: 'Today',
    },
    {
      value: DateToggle.Tomorrow,
      name: 'Tomorrow',
    },
    {
      value: DateToggle.Yesterday,
      name: 'Yesterday',
    },
  ];

  constructor(
    private el: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    private dateHelper: DateHelper,
    public inputState: InputState,
  ) {
    super();

    this.inputState.calendarClasses$.next(['app-datepicker__calendar']);

    this.listenToToggleChange();
  }

  public ngOnInit(): void {
    this.listenToCalendarOpen();
    this.listenToCalendarChangeByCellClick();
  }

  public ngAfterViewInit(): void {
    this.inputState.datepickerElement = this.el.nativeElement;
  }

  public get canSetTime(): boolean {
    return this.inputState.inputType === 'datetime';
  }

  private setDateAndUpdateView(date: Date): void {
    const calendar = this.inputState.calendar$.value;
    this.selected = calendar.selected = date;
    calendar._goToDateInView(this.selected, 'month');
  }

  private setPage(toggleValue: DateToggle): void {
    switch (toggleValue) {
      case DateToggle.Today:
      case DateToggle.Tomorrow:
      case DateToggle.Yesterday: {
        this.page = DatepickerPage.Calendar;
        break;
      }
    }
  }

  private resetPage(): void {
    this.page = DatepickerPage.Calendar;
  }

  private listenToToggleChange(): void {
    this.toggleControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((toggleValue: DateToggle) => {
        switch (toggleValue) {
          case DateToggle.Today: {
            this.setDateAndUpdateView(this.today);
            break;
          }

          case DateToggle.Tomorrow: {
            this.setDateAndUpdateView(this.dateHelper.add(this.today, 1, 'Days'));
            break;
          }

          case DateToggle.Yesterday: {
            this.setDateAndUpdateView(this.dateHelper.sub(this.today, 1, 'Days'));
            break;
          }
        }

        this.setPage(toggleValue);
      });
  }

  private listenToCalendarOpen(): void {
    this.datepicker.openedStream.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toggleControl.setValue(null, { emitEvent: false });
      this.resetPage();
    });
  }

  private listenToCalendarChangeByCellClick(): void {
    this.datepicker.openedStream
      .pipe(
        switchMap(() => this.inputState.calendar$),
        filter((calendar) => !!calendar),
        switchMap((calendar) => calendar.selectedChange.pipe(startWith(calendar.selected as Date))),
        takeUntil(this.destroy$),
      )
      .subscribe((selected) => {
        this.selected = selected;
        this.cd.detectChanges();

        if (this.canSetTime) {
          if (this.selected) {
            if (
              this.dateHelper.hasTime(this.timeControl.value) &&
              !this.dateHelper.hasTime(this.selected)
            ) {
              this.dateHelper.setTime(this.timeControl.value, this.selected);
            }

            this.timeControl.setValue(this.selected);
          } else {
            this.timeControl.setValue(this.today);
          }
        }

        if (this.selected && this.toggleControl.value) {
          switch (this.toggleControl.value as DateToggle) {
            case DateToggle.Today: {
              const isEqual = this.dateHelper.isEqual(this.selected, this.today);

              this.toggleControl.setValue(isEqual ? DateToggle.Today : null, {
                emitEvent: !isEqual,
              });

              break;
            }

            case DateToggle.Tomorrow: {
              const tomorrow = this.dateHelper.add(this.today, 1, 'Days');
              const isEqual = this.dateHelper.isEqual(this.selected, tomorrow);

              this.toggleControl.setValue(isEqual ? DateToggle.Tomorrow : null, {
                emitEvent: !isEqual,
              });
              break;
            }

            case DateToggle.Yesterday: {
              const yesterday = this.dateHelper.sub(this.today, 1, 'Days');
              const isEqual = this.dateHelper.isEqual(this.selected, yesterday);

              this.toggleControl.setValue(isEqual ? DateToggle.Yesterday : null, {
                emitEvent: !isEqual,
              });
              break;
            }
          }
        }
      });
  }

  public _onApplyClick(): void {
    if (this.canSetTime) {
      this.dateHelper.setTime(this.timeControl.value, this.selected);
    }

    this.datepicker.select(this.selected);
    this.datepicker.close();
    this.closeEvent.next(this.selected);
  }

  public _onApplyTimeClick(): void {
    this.page = this.previousPage;
  }

  public _onCancelClick(): void {
    this.datepicker.close();
    this.closeEvent.next();
  }

  public _onResetTimeClick(): void {
    this.page = this.previousPage;
    this.timeControl.setValue(this.today);
  }

  public _onEditTimeClick(): void {
    this.previousPage = this.page;
    this.page = DatepickerPage.TimeEditor;
  }
}
