import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceArray',
})
export class SliceArrayPipe implements PipeTransform {
  public transform<T>(array: T[], start: number, end: number): T[] {
    return array?.slice(start, end);
  }
}
