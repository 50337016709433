import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/helpers/date-helper.service';

@Pipe({
  name: 'getDateByDaysMove',
})
export class GetDateByDaysMovePipe implements PipeTransform {
  constructor(private dateHelper: DateHelper) {}

  public transform(count: number, move?: 'past' | 'future'): Date {
    const date = this.dateHelper.startOf('Today');

    switch (move) {
      case 'past':
        return this.dateHelper.sub(date, Math.abs(count), 'Days');

      case 'future':
        return this.dateHelper.add(date, Math.abs(count), 'Days');

      default: {
        switch (Math.sign(count)) {
          case 1:
            return this.dateHelper.add(date, Math.abs(count), 'Days');

          case -1:
            return this.dateHelper.sub(date, Math.abs(count), 'Days');

          default:
            return date;
        }
      }
    }
  }
}
