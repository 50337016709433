export interface ConfirmationData {
  title: string;
  description?: string;
  subType?: 'success' | 'warning' | 'danger';
  closeBtnText?: string;
  confirmBtnText?: string;
}

export interface SubscriptionData {
  title: string;
}

export interface ConfirmationResult {
  closeType: ConfirmationTypeResult;
}

export enum ConfirmationTypeResult {
  Yes = 'YES',
  Close = 'CLOSE',
}
