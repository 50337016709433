import {
  ChangeDetectionStrategy,
  Component,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  ControlContainer,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseControl } from '@shared/base/base-control';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
  host: {
    class: 'app-timepicker app-bg-block-0',
  },
})
export class TimepickerComponent extends BaseControl<UntypedFormControl> implements OnInit {
  @Input() public formControl: UntypedFormControl;
  @Input() public formControlName: string;

  @Input() public showLabel: boolean = true;

  public formGroup: UntypedFormGroup;
  public hControl = new UntypedFormControl(null, Validators.required);
  public mControl = new UntypedFormControl(null, Validators.required);
  public sControl = new UntypedFormControl(null, Validators.required);

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,

    private fb: UntypedFormBuilder,
  ) {
    super();

    this.formGroup = this.fb.group({
      hour: [null, Validators.required],
      minute: [null, Validators.required],
      second: [null, Validators.required],
    });

    this.listenToTimeChange();
  }

  public ngOnInit(): void {
    if (!this.formControl && this.controlContainer) {
      this.formControl = this.controlContainer.control.get(
        this.formControlName,
      ) as UntypedFormControl;
    }

    if (this.formControl.value) {
      const date: Date = this.formControl.value;
      const timeParts: number[] = [date.getHours(), date.getMinutes(), date.getSeconds()];

      this.formGroup.patchValue({
        hour: `${timeParts[0]}`.padStart(2, '0'),
        minute: `${timeParts[1]}`.padStart(2, '0'),
        second: `${timeParts[2]}`.padStart(2, '0'),
      });
    }
  }

  private listenToTimeChange(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { hour: string; minute: string; second: string }) => {
        if (this.formGroup.valid) {
          const currentvalue: Date = new Date(this.getValue());

          if (this._onChange) {
            currentvalue.setHours(Number.parseInt(data.hour));
            currentvalue.setMinutes(Number.parseInt(data.minute));
            currentvalue.setSeconds(Number.parseInt(data.second));

            this._onChange(currentvalue);
          }
        }
      });
  }

  public _onChangeTimePartByClick(timePartType: 'hour' | 'minute' | 'second', up: boolean): void {
    const timePart = Number.parseInt(this.formGroup.controls[timePartType].value);

    this.formGroup.patchValue({
      [timePartType]: up ? `${timePart + 1}`.padStart(2, '0') : `${timePart - 1}`.padStart(2, '0'),
    });
  }
}
