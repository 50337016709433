import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ccySymbol',
})
export class CcySymbolPipe implements PipeTransform {
  public transform(ccyCode?: string): string {
    if (!ccyCode) {
      return '';
    }

    return getCurrencySymbol(ccyCode, 'narrow');
  }
}
