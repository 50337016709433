import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/helpers/date-helper.service';

@Pipe({
  name: 'compareDate',
})
export class CompareDatePipe implements PipeTransform {
  constructor(private dateHelper: DateHelper) {}

  public transform(testDate: Date, fromDate: Date): boolean {
    return this.dateHelper.isBefore(testDate, fromDate);
  }
}
