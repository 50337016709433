import { Injectable } from '@angular/core';

export interface BrowserInfo {
  os?: string;
  browser?: string;
  mobile?: boolean;
  screenSize?: string;
  userAgent?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BrowserInfoService {
  private _browserInfo: BrowserInfo;

  constructor() {
    this._browserInfo = {
      os: this.getOS(),
      browser: this.getBrowser(),
      screenSize: this.getScreen(),
      mobile: this.issMobile(),
      userAgent: navigator.userAgent,
    };
  }

  public get browserInfo(): BrowserInfo {
    return this._browserInfo;
  }

  private getOS(): string {
    let oSName: string = 'OS unknown';
    if (navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
      oSName = 'Windows 10';
    }
    if (navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
      oSName = 'Windows 8';
    }
    if (navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
      oSName = 'Windows 7';
    }
    if (navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
      oSName = 'Windows Vista';
    }
    if (navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
      oSName = 'Windows XP';
    }
    if (navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
      oSName = 'Windows 2000';
    }
    if (navigator.userAgent.indexOf('Mac') !== -1) {
      oSName = 'Mac/iOS';
    }
    if (navigator.userAgent.indexOf('X11') !== -1) {
      oSName = 'UNIX';
    }
    if (navigator.userAgent.indexOf('Linux') !== -1) {
      oSName = 'Linux';
    }

    return oSName;
  }

  private issMobile(): boolean {
    const appVer = navigator.appVersion;
    return /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(appVer);
  }

  private getBrowser(): string {
    const userAgent = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset;
    let verOffset;
    let ix;

    // Opera
    if ((verOffset = userAgent.indexOf('Opera')) !== -1) {
      browser = 'Opera';
      version = userAgent.substring(verOffset + 6);
      if ((verOffset = userAgent.indexOf('Version')) !== -1) {
        version = userAgent.substring(verOffset + 8);
      }
    }

    // MSIE
    else if ((verOffset = userAgent.indexOf('MSIE')) !== -1) {
      browser = 'Microsoft Internet Explorer';
      version = userAgent.substring(verOffset + 5);
    } else if (browser === 'Netscape' && userAgent.indexOf('Trident/') !== -1) {
      browser = 'Microsoft Internet Explorer';
      version = userAgent.substring(verOffset + 5);
      if ((verOffset = userAgent.indexOf('rv:')) !== -1) {
        version = userAgent.substring(verOffset + 3);
      }
    }

    // Chrome
    else if ((verOffset = userAgent.indexOf('Chrome')) !== -1) {
      browser = 'Chrome';
      version = userAgent.substring(verOffset + 7);
    }

    // Safari
    else if ((verOffset = userAgent.indexOf('Safari')) !== -1) {
      browser = 'Safari';
      version = userAgent.substring(verOffset + 7);
      if ((verOffset = userAgent.indexOf('Version')) !== -1) {
        version = userAgent.substring(verOffset + 8);
      }

      // Chrome on iPad identifies itself as Safari. Actual results do not match what Google claims
      //  at: https://developers.google.com/chrome/mobile/docs/user-agent?hl=ja
      //  No mention of chrome in the user agent string. However it does mention CriOS, which presumably
      //  can be keyed on to detect it.
      if (userAgent.indexOf('CriOS') !== -1) {
        browser = 'Chrome';
      }
    }

    // Firefox
    else if ((verOffset = userAgent.indexOf('Firefox')) !== -1) {
      browser = 'Firefox';
      version = userAgent.substring(verOffset + 8);
    }

    // Other browsers
    else if (
      (nameOffset = userAgent.lastIndexOf(' ') + 1) < (verOffset = userAgent.lastIndexOf('/'))
    ) {
      browser = userAgent.substring(nameOffset, verOffset);
      version = userAgent.substring(verOffset + 1);
      if (browser.toLowerCase() === browser.toUpperCase()) {
        browser = navigator.appName;
      }
    }

    // trim the version string
    if ((ix = version.indexOf(';')) !== -1) {
      version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(' ')) !== -1) {
      version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(')')) !== -1) {
      version = version.substring(0, ix);
    }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
      version = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    return `${browser} ${version}`;
  }

  private getScreen(): string {
    let screenSize = '';
    let width;
    let height;

    if (screen.width) {
      width = screen.width ? screen.width : '';
      height = screen.height ? screen.height : '';
      screenSize = `${width} x ${height}`;
    }

    return screenSize;
  }
}
