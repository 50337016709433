import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper, DateUnit } from '@shared/helpers/date-helper.service';

@Pipe({
  name: 'changeDate',
})
export class ChangeDatePipe implements PipeTransform {
  constructor(private dateHelper: DateHelper) {}

  public transform(date: Date, changeType: 'add' | 'sub', amount: number, unit: DateUnit): Date {
    switch (changeType) {
      case 'add':
        return this.dateHelper.add(date, amount, unit);

      case 'sub':
        return this.dateHelper.sub(date, amount, unit);

      default:
        return date;
    }
  }
}
