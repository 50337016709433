<div class="app-layout__navbar">
  <app-navbar></app-navbar>
</div>

<ng-container *ngLet="layoutState.showPageHeaderBackground$|async as _showPageHeaderBackground">
  <div *ngIf="_showPageHeaderBackground"
       class="app-layout__header-background app-bg-block-main">
  </div>

  <div *ngIf="layoutState.showPageLoader$|async"
       class="app-widget-spinner"
       style="position: fixed; z-index: 4;"
       [style.top]="(_showPageHeaderBackground ?
                      layoutState.navbarHeight + layoutState.headerHeight :
                      layoutState.navbarHeight
                    ) + 'px'">
  </div>
</ng-container>

<div *ngLet="layoutState.canShowFullScreen && (layoutState.fullScreen$|async) as _fullScreen"
     class="app-layout__page">
  <div [class.app-page-container]="!_fullScreen"
       [class.app-layout__page--full-screen]="_fullScreen"
       *ngLet="layoutState.subscriptionNotificationData$|async as _subscriptionNotificationData">
    <app-subscription-notification *ngIf="_subscriptionNotificationData"
                                   class="app-bg-block-main app-border-r6"
                                   [title]="_subscriptionNotificationData.title"
                                   [height]="'calc(100vh - 90px)'"
                                   style="margin-top: 15px;">
    </app-subscription-notification>

    <router-outlet *ngIf="!_subscriptionNotificationData"></router-outlet>
  </div>
</div>

<div class="app-layout__footer">
  <app-footer>
    <app-site-info-outlet class="body-3 app-text-color-25"
                          [data]="footerData$|async"
                          [showExpander]="true">
    </app-site-info-outlet>
  </app-footer>
</div>

<app-fixed-controls *ngIf="layoutState.showFixedControls$|async"></app-fixed-controls>