import { Injectable } from '@angular/core';
import { Observable, first, from, switchMap, zip } from 'rxjs';
import { IndexDb } from './index-db';

/** store collections */
export enum StoreCollections {
  Tables = 'tables',
  TableViews = 'table-views',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStore {
  private readonly StoreDbName = 'app-store-db';
  private readonly StoreDbVersion = 1; // increment if add collections / update collection name

  private readonly db = new IndexDb();

  public clearTablesStoreAfterInit = false;

  constructor() {
    // eslint-disable-next-line no-console
    console.log('%cLocalStore ctor', 'color: gray');
  }

  public init(): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('%cLocalStore init', 'color: gray');

    return this.db
      .init(this.StoreDbName, this.StoreDbVersion, [
        StoreCollections.Tables,
        StoreCollections.TableViews,
      ])
      .then(() => {
        if (this.clearTablesStoreAfterInit) {
          this.clearTablesStoreAfterInit = false;
          this.clearTablesStore();
        }
      });
  }

  public get<T>(collection: StoreCollections, key: string): Observable<T> {
    return from(this.db.get<T>(collection, key));
  }

  public set<T>(collection: StoreCollections, key: string, value: T): Observable<void> {
    return from(this.db.set<T>(collection, key, value));
  }

  public delete(collection: StoreCollections, key: string): Observable<void> {
    return from(this.db.delete(collection, key));
  }

  public clearTablesStore(): void {
    zip(
      from(this.db.getAllKeys(StoreCollections.Tables)).pipe(
        switchMap((keys) =>
          zip(keys.map((key) => from(this.db.delete(StoreCollections.Tables, key)))),
        ),
      ),
      from(this.db.getAllKeys(StoreCollections.TableViews)).pipe(
        switchMap((keys) =>
          zip(keys.map((key) => from(this.db.delete(StoreCollections.TableViews, key)))),
        ),
      ),
    )
      .pipe(first())
      .subscribe();
  }
}
