import { MEDIA_FILE_TYPES, MediaType } from '@shared/constants/media-file.type';

export function getMediaFileType(mimeType: string): MediaType {
  return (
    MEDIA_FILE_TYPES.find(
      (type) =>
        type.mimeTypes.find((mt) => mt.includes(mimeType)) ||
        type.resolutions.find((res) => res.includes(mimeType)),
    )?.type || null
  );
}
