/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GlobalAllocationTagDTO } from './global-allocation-tag-dto';

export interface GlobalAllocationTemplateDTO {
  createdAt?: string;
  createdBy?: string;
  description?: string;
  id?: string;
  name?: string;
  status?: GlobalAllocationTemplateDTO.StatusEnum;
  tags?: Array<GlobalAllocationTagDTO>;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace GlobalAllocationTemplateDTO {
  export type StatusEnum = 'ACTIVE' | 'CLOSED' | 'DRAFT' | 'ON_REVIEW' | 'PUBLISHED';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Closed: 'CLOSED' as StatusEnum,
    Draft: 'DRAFT' as StatusEnum,
    OnReview: 'ON_REVIEW' as StatusEnum,
    Published: 'PUBLISHED' as StatusEnum,
  };
}
