/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CriteriaDTO } from './criteria-dto';

export interface RiskProfileRestrictionDTO {
  createdAt?: string;
  createdBy?: string;
  criteria?: Array<CriteriaDTO>;
  id?: string;
  max?: number;
  min?: number;
  name?: string;
  restrictionTypes?: Array<RiskProfileRestrictionDTO.RestrictionTypesEnum>;
  restrictions?: Array<RiskProfileRestrictionDTO>;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace RiskProfileRestrictionDTO {
  export type RestrictionTypesEnum = 'BUY' | 'HOLD' | 'SELL' | 'TRANSFER';
  export const RestrictionTypesEnum = {
    Buy: 'BUY' as RestrictionTypesEnum,
    Hold: 'HOLD' as RestrictionTypesEnum,
    Sell: 'SELL' as RestrictionTypesEnum,
    Transfer: 'TRANSFER' as RestrictionTypesEnum,
  };
}
