import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DtoInfoService } from '@api/dto-info.service';
import { ProcessDTO, RelationDTO } from '@shared/dto/gateway-secured/models';
import { EntityFieldDTO } from '@shared/dto/meta-info/models';

@Pipe({
  name: 'getEntityFieldEnumValues',
})
export class GetEntityFieldEnumValuesPipe implements PipeTransform {
  constructor(private dtoInfoService: DtoInfoService) {}

  public transform(
    field: EntityFieldDTO,
    entityFields: EntityFieldDTO[],
    rootEntityType: RelationDTO.EntityTypeEnum | ProcessDTO.EntityTypeEnum,
  ): Observable<string[]> {
    return field &&
      entityFields?.length &&
      (field.entityFieldType === 'ENUM' || field.entityFieldType === 'ENUM_ARRAY') &&
      rootEntityType
      ? this.dtoInfoService.getEnumValues(
          entityFields.find((f) => f.field === field.field)?.parentEntityType || rootEntityType,
          field.field.split('.').pop(),
        )
      : of([]);
  }
}
