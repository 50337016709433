export enum DatepickerPage {
  Calendar = 1,
  TimeEditor,
}

export enum DateRangePeriod {
  Last7Days = 1,
  Next7Days,
  ThisMonth,
  ThreeMonths,
  YTD,
  Year,
  ThreeYears,
  ThisQuarter,
  PastQuarter,
  AllTime,
}

export const DATE_RANGE_PERIOD_NAMES: Record<DateRangePeriod, string> = {
  [DateRangePeriod.Last7Days]: 'Last 7 Days',
  [DateRangePeriod.Next7Days]: 'Next 7 Days',
  [DateRangePeriod.ThisMonth]: 'This Month',
  [DateRangePeriod.ThreeMonths]: '3 Months',
  [DateRangePeriod.YTD]: 'YTD',
  [DateRangePeriod.Year]: 'Year',
  [DateRangePeriod.ThreeYears]: '3 Years',
  [DateRangePeriod.ThisQuarter]: 'This Quarter',
  [DateRangePeriod.PastQuarter]: 'Past Quarter',
  [DateRangePeriod.AllTime]: 'All Time',
};
