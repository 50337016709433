/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ActionDTO {
  id?: string;
  type: ActionDTO.TypeEnum;
  value: string;
}
export namespace ActionDTO {
  export type TypeEnum = 'EXCEPTION' | 'FIELD_UPDATE' | 'NOTIFICATION' | 'SCRIPT';
  export const TypeEnum = {
    Exception: 'EXCEPTION' as TypeEnum,
    FieldUpdate: 'FIELD_UPDATE' as TypeEnum,
    Notification: 'NOTIFICATION' as TypeEnum,
    Script: 'SCRIPT' as TypeEnum,
  };
}
