import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { BaseObject } from '@shared/base/base-object';
import { InputState } from '@ui/input/input.state';

@Component({
  selector: 'app-datepicker-calendar-header',
  templateUrl: './datepicker-calendar-header.component.html',
  styleUrls: ['./datepicker-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerCalendarHeaderComponent extends BaseObject {
  constructor(
    public calendar: MatCalendar<Date | DateRange<Date>>,
    private inputState: InputState,
  ) {
    super();

    this.inputState.calendar$.next(this.calendar as MatCalendar<Date>);
    this.destroy$.subscribe(() => this.inputState.calendar$.next(null));
  }
}
