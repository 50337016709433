import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankAccountsService } from '@shared/api/bank-accounts.service';
import { BankAccountTinyDTO, CustodianDTO } from '@shared/dto/accounts/models';

@Pipe({
  name: 'getTtcaBankAccounts',
})
export class GetTtcaBankAccountsPipe implements PipeTransform {
  constructor(private bankAccountsService: BankAccountsService) {}

  public transform(
    custodian: CustodianDTO,
    companyId: string = null,
  ): Observable<BankAccountTinyDTO[]> {
    if (custodian) {
      return this.bankAccountsService.getTtcaBankAccounts(custodian.id, companyId).pipe(
        map((bankAccounts) => {
          const bankAccountsMap = bankAccounts.map((bankAccount) => {
            const ret: BankAccountTinyDTO = {
              id: bankAccount.id,
              name: bankAccount.name,
            };
            return ret;
          });

          return bankAccountsMap;
        }),
      );
    }

    return of(null);
  }
}
