import { Directive, ElementRef, NgZone } from '@angular/core';

@Directive({
  selector: '[appScrollToBottom]',
  exportAs: 'appScrollToBottom',
})
export class ScrollToBottomDirective {
  constructor(
    private zone: NgZone,
    private el: ElementRef<HTMLElement>,
  ) {}

  public scroll(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
      });
    });
  }
}
