import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartSkeletonComponent } from '@ui/chart-skeleton/chart-skeleton.component';
import { NotificationModule } from '@ui/notification/notification.module';
import { SvgModule } from '@ui/svg/svg.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SvgModule, NotificationModule],
  declarations: [ChartSkeletonComponent],
  exports: [ChartSkeletonComponent],
})
export class ChartSkeletonModule {}
