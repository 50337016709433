import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { GridComponent } from './grid.component';
import { toTilePadding } from './grid.helper';
import { TilePadding } from './grid.types';

export type DisplayType = 'row' | 'column';

export type AlignType =
  | 'LeftTop'
  | 'LeftCenter'
  | 'LeftBottom'
  | 'RightTop'
  | 'RightCenter'
  | 'RightBottom'
  | 'CenterTop'
  | 'CenterCenter'
  | 'CenterBottom';

@Component({
  selector: 'app-grid-tile',
  template: '<ng-content></ng-content>',
  styleUrls: ['./grid-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-grid-tile',
    '[style.--colspan]': 'colspan',
    '[attr.blank]': 'blank',
    '[class]': `_align`,
    '[class.--row]': `_display === 'row'`,
    '[class.--column]': `_display === 'column'`,
  },
})
export class GridTileComponent implements OnChanges {
  /**
   * показывать или нет выступающие за границу тайла элементы
   */
  @Input() public overflowVisible: boolean = false;

  @HostBinding('style.overflow')
  public get overflowStyle(): 'hidden' | 'visible' {
    return this.overflowVisible ? 'visible' : 'hidden';
  }

  /** число столбцов, которые занимает ячейка */
  @Input() public colspan: number = 1;

  /** направление рисования контента ячейки */
  @Input() public display: DisplayType = 'column';

  /** выравнивание контента ячейки по горизонтале и вертикале */
  @Input() public align: AlignType = 'LeftTop';

  /** флаг ячейки с пустым контентом.
   * иногда нужно отображать заглушку вместо контента
   */
  @Input() public blank: boolean = false;

  public get _align(): string {
    return this.align ? `--${this.align}` : null;
  }

  public get _display(): DisplayType {
    return this.display || 'column';
  }

  private _selfPadding: TilePadding;

  constructor(
    /** собственный размер паддинга внутри ячейки сетки */
    @Attribute('selfPadding') public selfPadding: string = null,
    public el: ElementRef,
    private r: Renderer2,
    private grid: GridComponent,
  ) {
    this.colspan = this.colspan || 1;
    this._selfPadding = this.selfPadding ? toTilePadding(this.selfPadding) : null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.colspan &&
      changes.colspan.previousValue &&
      changes.colspan.previousValue !== changes.colspan.currentValue
    ) {
      this.grid.setGridItemsStyles();
    }
  }

  /**
   * Динамически устанавливает размеры ячейки сетки.
   * @param rowHeight высота строки сетки
   * @param tilePadding размер паддинга внутри ячейки сетки
   */
  public setStyles(rowHeight: number, tilePadding: TilePadding): void {
    if (rowHeight) {
      this.r.setStyle(this.el.nativeElement, 'max-height', `${rowHeight}px`);
      this.r.setStyle(this.el.nativeElement, 'min-height', `${rowHeight}px`);
    }

    const _tilePadding: TilePadding = this._selfPadding ? this._selfPadding : tilePadding;

    if (_tilePadding.top) {
      this.r.setStyle(this.el.nativeElement, 'padding-top', `${_tilePadding.top}px`);
    }

    if (_tilePadding.bottom) {
      this.r.setStyle(this.el.nativeElement, 'padding-bottom', `${_tilePadding.bottom}px`);
    }

    if (_tilePadding.left) {
      this.r.setStyle(this.el.nativeElement, 'padding-left', `${_tilePadding.left}px`);
    }

    if (_tilePadding.right) {
      this.r.setStyle(this.el.nativeElement, 'padding-right', `${_tilePadding.right}px`);
    }
  }
}
