/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface OrderTransactionTypeInfo {
  groupType?: OrderTransactionTypeInfo.GroupTypeEnum;
  subTypes?: Array<OrderTransactionTypeInfo.SubTypesEnum>;
  type?: OrderTransactionTypeInfo.TypeEnum;
}
export namespace OrderTransactionTypeInfo {
  export type GroupTypeEnum = 'COMMON' | 'CORP_ACTION' | 'NON_TRADE' | 'TRADE';
  export const GroupTypeEnum = {
    Common: 'COMMON' as GroupTypeEnum,
    CorpAction: 'CORP_ACTION' as GroupTypeEnum,
    NonTrade: 'NON_TRADE' as GroupTypeEnum,
    Trade: 'TRADE' as GroupTypeEnum,
  };
  export type SubTypesEnum =
    | 'ADMINISTRATION'
    | 'ADR_FEE'
    | 'AMENDMENT_FEE'
    | 'BLOCKING'
    | 'BUY'
    | 'BUY_FX'
    | 'BUY_ISSUE_IPO'
    | 'CASH_TRANSFER_FEE'
    | 'COMMISSION'
    | 'COUPON'
    | 'CREDIT'
    | 'CUSTODY'
    | 'DEBIT'
    | 'DIRECT'
    | 'DIVIDEND'
    | 'EXCHANGE_FEE'
    | 'EXPOSURE_FEE'
    | 'FULL'
    | 'HANDLE_FEE'
    | 'IN'
    | 'INCENTIVE'
    | 'INTEREST_CALCULATION'
    | 'INTERNAL'
    | 'INTERNAL_BUY'
    | 'INTERNAL_SELL'
    | 'MANAGEMENT'
    | 'OTHER'
    | 'OTHER_TAX'
    | 'OUT'
    | 'OVERNIGHT_POSITION_FEE'
    | 'PARTIAL'
    | 'PHYSICAL_SETTLEMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIBE'
    | 'PUBLIC_OFFER'
    | 'REIMBURSEMENT'
    | 'RETROCESSION'
    | 'REVENUE'
    | 'REVERSE'
    | 'SALES_TAX'
    | 'SELL'
    | 'SELL_FX'
    | 'SERVICE_CHARGE_CALCULATION'
    | 'SETTLEMENT_FEE'
    | 'STOCK_TRANSFER_FEE'
    | 'SUBSCRIPTION'
    | 'SUCCESS'
    | 'UNBLOCKING'
    | 'WITHDRAWAL_FEE'
    | 'WITHHOLDING_TAX';
  export const SubTypesEnum = {
    Administration: 'ADMINISTRATION' as SubTypesEnum,
    AdrFee: 'ADR_FEE' as SubTypesEnum,
    AmendmentFee: 'AMENDMENT_FEE' as SubTypesEnum,
    Blocking: 'BLOCKING' as SubTypesEnum,
    Buy: 'BUY' as SubTypesEnum,
    BuyFx: 'BUY_FX' as SubTypesEnum,
    BuyIssueIpo: 'BUY_ISSUE_IPO' as SubTypesEnum,
    CashTransferFee: 'CASH_TRANSFER_FEE' as SubTypesEnum,
    Commission: 'COMMISSION' as SubTypesEnum,
    Coupon: 'COUPON' as SubTypesEnum,
    Credit: 'CREDIT' as SubTypesEnum,
    Custody: 'CUSTODY' as SubTypesEnum,
    Debit: 'DEBIT' as SubTypesEnum,
    Direct: 'DIRECT' as SubTypesEnum,
    Dividend: 'DIVIDEND' as SubTypesEnum,
    ExchangeFee: 'EXCHANGE_FEE' as SubTypesEnum,
    ExposureFee: 'EXPOSURE_FEE' as SubTypesEnum,
    Full: 'FULL' as SubTypesEnum,
    HandleFee: 'HANDLE_FEE' as SubTypesEnum,
    In: 'IN' as SubTypesEnum,
    Incentive: 'INCENTIVE' as SubTypesEnum,
    InterestCalculation: 'INTEREST_CALCULATION' as SubTypesEnum,
    Internal: 'INTERNAL' as SubTypesEnum,
    InternalBuy: 'INTERNAL_BUY' as SubTypesEnum,
    InternalSell: 'INTERNAL_SELL' as SubTypesEnum,
    Management: 'MANAGEMENT' as SubTypesEnum,
    Other: 'OTHER' as SubTypesEnum,
    OtherTax: 'OTHER_TAX' as SubTypesEnum,
    Out: 'OUT' as SubTypesEnum,
    OvernightPositionFee: 'OVERNIGHT_POSITION_FEE' as SubTypesEnum,
    Partial: 'PARTIAL' as SubTypesEnum,
    PhysicalSettlement: 'PHYSICAL_SETTLEMENT' as SubTypesEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as SubTypesEnum,
    ProductUnsubscribe: 'PRODUCT_UNSUBSCRIBE' as SubTypesEnum,
    PublicOffer: 'PUBLIC_OFFER' as SubTypesEnum,
    Reimbursement: 'REIMBURSEMENT' as SubTypesEnum,
    Retrocession: 'RETROCESSION' as SubTypesEnum,
    Revenue: 'REVENUE' as SubTypesEnum,
    Reverse: 'REVERSE' as SubTypesEnum,
    SalesTax: 'SALES_TAX' as SubTypesEnum,
    Sell: 'SELL' as SubTypesEnum,
    SellFx: 'SELL_FX' as SubTypesEnum,
    ServiceChargeCalculation: 'SERVICE_CHARGE_CALCULATION' as SubTypesEnum,
    SettlementFee: 'SETTLEMENT_FEE' as SubTypesEnum,
    StockTransferFee: 'STOCK_TRANSFER_FEE' as SubTypesEnum,
    Subscription: 'SUBSCRIPTION' as SubTypesEnum,
    Success: 'SUCCESS' as SubTypesEnum,
    Unblocking: 'UNBLOCKING' as SubTypesEnum,
    WithdrawalFee: 'WITHDRAWAL_FEE' as SubTypesEnum,
    WithholdingTax: 'WITHHOLDING_TAX' as SubTypesEnum,
  };
  export type TypeEnum =
    | 'ADJUSTMENT'
    | 'BLOCK'
    | 'BOND_REDEMPTION'
    | 'BUY'
    | 'CASH_TRANSFER'
    | 'CROSS_TRADE'
    | 'CUSTOM'
    | 'DECREASE_CLOSE_DEPOSIT'
    | 'DECREASE_CLOSE_LOAN'
    | 'EXERCISE_OPTION'
    | 'EXPIRE_OPTION'
    | 'FEE'
    | 'FX'
    | 'FX_PRODUCT'
    | 'INCOME'
    | 'INCREASE_DEPOSIT'
    | 'INCREASE_LOAN'
    | 'INTEREST'
    | 'INTERNAL_PAYMENT'
    | 'INTERNAL_SWITCH'
    | 'INTERNAL_TRANSFER'
    | 'INVESTMENT'
    | 'ISIN_CHANGE'
    | 'MARGIN'
    | 'OPEN_DEPOSIT'
    | 'OPEN_LOAN'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIPTION'
    | 'REPO'
    | 'REVERSAL'
    | 'SECURITY_TRANSFER'
    | 'SELL'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'TAX'
    | 'TRANSFER'
    | 'VIRTUAL_SECURITY_TRANSFER'
    | 'WITHDRAWAL';
  export const TypeEnum = {
    Adjustment: 'ADJUSTMENT' as TypeEnum,
    Block: 'BLOCK' as TypeEnum,
    BondRedemption: 'BOND_REDEMPTION' as TypeEnum,
    Buy: 'BUY' as TypeEnum,
    CashTransfer: 'CASH_TRANSFER' as TypeEnum,
    CrossTrade: 'CROSS_TRADE' as TypeEnum,
    Custom: 'CUSTOM' as TypeEnum,
    DecreaseCloseDeposit: 'DECREASE_CLOSE_DEPOSIT' as TypeEnum,
    DecreaseCloseLoan: 'DECREASE_CLOSE_LOAN' as TypeEnum,
    ExerciseOption: 'EXERCISE_OPTION' as TypeEnum,
    ExpireOption: 'EXPIRE_OPTION' as TypeEnum,
    Fee: 'FEE' as TypeEnum,
    Fx: 'FX' as TypeEnum,
    FxProduct: 'FX_PRODUCT' as TypeEnum,
    Income: 'INCOME' as TypeEnum,
    IncreaseDeposit: 'INCREASE_DEPOSIT' as TypeEnum,
    IncreaseLoan: 'INCREASE_LOAN' as TypeEnum,
    Interest: 'INTEREST' as TypeEnum,
    InternalPayment: 'INTERNAL_PAYMENT' as TypeEnum,
    InternalSwitch: 'INTERNAL_SWITCH' as TypeEnum,
    InternalTransfer: 'INTERNAL_TRANSFER' as TypeEnum,
    Investment: 'INVESTMENT' as TypeEnum,
    IsinChange: 'ISIN_CHANGE' as TypeEnum,
    Margin: 'MARGIN' as TypeEnum,
    OpenDeposit: 'OPEN_DEPOSIT' as TypeEnum,
    OpenLoan: 'OPEN_LOAN' as TypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as TypeEnum,
    ProductUnsubscription: 'PRODUCT_UNSUBSCRIPTION' as TypeEnum,
    Repo: 'REPO' as TypeEnum,
    Reversal: 'REVERSAL' as TypeEnum,
    SecurityTransfer: 'SECURITY_TRANSFER' as TypeEnum,
    Sell: 'SELL' as TypeEnum,
    SpinOff: 'SPIN_OFF' as TypeEnum,
    Split: 'SPLIT' as TypeEnum,
    Tax: 'TAX' as TypeEnum,
    Transfer: 'TRANSFER' as TypeEnum,
    VirtualSecurityTransfer: 'VIRTUAL_SECURITY_TRANSFER' as TypeEnum,
    Withdrawal: 'WITHDRAWAL' as TypeEnum,
  };
}
