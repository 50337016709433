import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
} from '@angular/material/core';
import {
  MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
  MatSlideToggleDefaultOptions,
} from '@angular/material/slide-toggle';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { SettingsState } from '@shared/states/settings.state';
import { ThemeState } from '@shared/states/theme.state';
import { TranslateService } from '@shared/translates/translate.service';
import { TranslatesModule } from '@shared/translates/translates.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { AppInjector } from 'app/config/app-injector';
import { GlobalErrorHandler } from 'app/config/error-handler';
import { HttpErrorInterceptor } from 'app/interceptors/http-error.interceptor';
import { TokenInterceptor } from 'app/interceptors/token.interceptor';
import { LayoutModule } from 'app/layout/layout.module';
import { NotificationsModule } from 'app/notifications/notifications.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomHammerGestureConfig } from './config/app-hammer-gesture.config';
import { DEFAULT_DATE_FORMATS, GlobalDateAdapter } from './config/global-date-adapter';
import { globalRippleConfig } from './config/global-ripple-config';
import { initApp } from './initializers/init-app';
import { initAppSettings } from './initializers/init-app-settings';
import { InitAppService } from './initializers/init-app.service';
import { initLanguage } from './initializers/init-language';
import { initTheme } from './initializers/init-theme';

@NgModule({
  imports: [
    CommonModule,
    TranslatesModule,
    MatNativeDateModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DirectivesModule,
    LayoutModule,
    NotificationsModule,
    PipesModule,
    HammerModule,
    SpinnerModule,
  ],
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initApp, multi: true, deps: [InitAppService] },
    { provide: APP_INITIALIZER, useFactory: initAppSettings, multi: true, deps: [SettingsState] },
    { provide: APP_INITIALIZER, useFactory: initLanguage, multi: true, deps: [TranslateService] },
    { provide: APP_INITIALIZER, useFactory: initTheme, multi: true, deps: [ThemeState] },
    {
      provide: DateAdapter,
      useClass: GlobalDateAdapter,
    },
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_DATE_FORMATS },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerGestureConfig },
    // { provide: LOCALE_ID, useValue: 'en-US' },
    // { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: { hideIcon: true } as MatSlideToggleDefaultOptions,
    },
  ],
})
export class AppModule {
  constructor(translateService: TranslateService, injector: Injector) {
    AppInjector.Injector = injector;
    translateService.changeLang(translateService.defaultLang.code);
  }
}
