import { Injector, Pipe, PipeTransform } from '@angular/core';
import { SELECT_OPTION, SelectOption } from './select.types';

@Pipe({
  name: 'getSelectOptionInjector',
})
export class GetSelectOptionInjectorPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  public transform(option: SelectOption): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: SELECT_OPTION, useValue: option }],
    });
  }
}
