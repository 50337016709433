import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BankAccountsService } from '@shared/api/bank-accounts.service';
import { BankAccountTinyDTO, CustodianDTO } from '@shared/dto/accounts/models';

export interface StateOfOmnibusBankAccounts {
  omnibusBankAccounts$: BehaviorSubject<BankAccountTinyDTO[]>;
}

@Pipe({
  name: 'getOmnibusBankAccounts',
})
export class GetOmnibusBankAccountsPipe implements PipeTransform {
  constructor(private bankAccountsService: BankAccountsService) {}

  public transform(
    custodian: CustodianDTO,
    companyId: string = null,
    state: StateOfOmnibusBankAccounts = null,
  ): Observable<BankAccountTinyDTO[]> {
    if (custodian) {
      if (state?.omnibusBankAccounts$?.value) {
        state.omnibusBankAccounts$.next(null);
      }

      return this.bankAccountsService.getOmnibusBankAccounts(custodian.id, companyId).pipe(
        map((bankAccounts) => {
          const bankAccountsMap = bankAccounts.map((bankAccount) => {
            const ret: BankAccountTinyDTO = {
              id: bankAccount.id,
              name: bankAccount.name,
            };
            return ret;
          });

          if (state?.omnibusBankAccounts$) {
            state.omnibusBankAccounts$.next(bankAccountsMap || []);
          }

          return bankAccountsMap;
        }),
      );
    }

    return of(null);
  }
}
