import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isNotEmptyString } from '@shared/base/core';
import { SiteInfoDTO } from '@shared/dto/accounts/models';
import { SettingsState } from '@shared/states/settings.state';

@Injectable({
  providedIn: 'root',
})
export class SiteInfoService {
  constructor(
    private http: HttpClient,
    private settingService: SettingsState,
  ) {}

  public getAll(): Observable<SiteInfoDTO[]> {
    const url = `${this.settingService.apiPath}/site-info`;
    return this.http.get<SiteInfoDTO[]>(url).pipe(catchError(() => of(null)));
  }

  public getById(id: string): Observable<SiteInfoDTO> {
    const url = `${this.settingService.apiPath}/site-info/${id}`;
    return this.http.get<SiteInfoDTO>(url).pipe(catchError(() => of(null)));
  }

  public getByType(type: SiteInfoDTO.TypeEnum): Observable<SiteInfoDTO> {
    const url = `${this.settingService.apiPath}/site-info/by-type`;
    const params: { [param: string]: string } = {};

    if (isNotEmptyString(type)) {
      params.type = `${type}`;
    } else {
      return of(null);
    }

    return this.http.get<SiteInfoDTO>(url, { params }).pipe(catchError(() => of(null)));
  }

  public create(data: SiteInfoDTO): Observable<SiteInfoDTO> {
    const url = `${this.settingService.apiPath}/site-info`;
    return this.http.post<SiteInfoDTO>(url, data).pipe(catchError(() => of(null)));
  }

  public update(data: SiteInfoDTO): Observable<SiteInfoDTO> {
    const url = `${this.settingService.apiPath}/site-info`;
    return this.http.put<SiteInfoDTO>(url, data).pipe(catchError(() => of(null)));
  }
}
