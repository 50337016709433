import { Pipe, PipeTransform } from '@angular/core';
import { AlternativeInvestmentDTO } from '@shared/dto/alternative-assets/models';

@Pipe({
  name: 'coverPreviewForAssetType',
})
export class CoverPreviewForAssetTypePipe implements PipeTransform {
  public transform(
    assetType: AlternativeInvestmentDTO.AssetTypeEnum,
    assetSubType?: AlternativeInvestmentDTO.AssetSubTypeEnum,
  ): string {
    let imgName: string = null;

    switch (assetType) {
      case 'TANGIBLE_ASSET':
        switch (assetSubType) {
          case 'AIRPLANE':
            imgName = 'airplane.jpg';
            break;

          case 'CAR':
            imgName = 'car.jpg';
            break;

          case 'REAL_ESTATE':
            imgName = 'real-estate.jpg';
            break;

          case 'HELICOPTER':
            imgName = 'helicopter.jpg';
            break;

          case 'ART_AND_COLLECTIBLES':
            imgName = 'art-and-collectibles.jpg';
            break;

          case 'BUILDINGS':
            imgName = 'buildings.jpg';
            break;

          case 'LAND':
            imgName = 'land.jpg';
            break;

          default:
            imgName = null;
            break;
        }
        break;
      case 'COMMODITIES':
        imgName = 'commodities.jpg';
        break;

      case 'PRIVATE_EQUITY':
        imgName = 'private-equity.jpg';
        break;

      case 'CRYPTO':
        imgName = 'crypto.jpg';
        break;

      case 'HEDGE_FUND':
        imgName = 'hedge-fund.jpg';
        break;

      case 'GRANTED_LOAN':
        imgName = 'granted-loan.jpg';
        break;

      default:
        imgName = null;
        break;
    }

    return imgName ? `/assets/img/private-assets/default-covers/${imgName}` : null;
  }
}
