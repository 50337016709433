/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleTinyDTO } from './role-tiny-dto';
import { ProfileTinyDTO } from './profile-tiny-dto';

export interface ClientDTO {
  access?: boolean;
  birthDate: string;
  ccpt: string;
  concat?: string;
  country: string;
  createdAt?: string;
  createdBy?: string;
  email: string;
  firstName: string;
  gender?: ClientDTO.GenderEnum;
  id?: string;
  lastLogin?: string;
  lastName: string;
  login?: string;
  name?: string;
  nidn?: string;
  phone?: string;
  profile?: ProfileTinyDTO;
  role?: RoleTinyDTO;
  status: ClientDTO.StatusEnum;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace ClientDTO {
  export type GenderEnum = 'FEMALE' | 'MALE';
  export const GenderEnum = {
    Female: 'FEMALE' as GenderEnum,
    Male: 'MALE' as GenderEnum,
  };
  export type StatusEnum = 'Active' | 'Deactivated';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
  };
}
