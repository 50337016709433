import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UploaderService } from '@shared/helpers/uploader.service';
import { FileData } from '@ui/uploader/uploader.types';

@Pipe({
  name: 'convertFileToBase64',
})
export class ConvertFileToBase64Pipe implements PipeTransform {
  constructor(private uploaderService: UploaderService) {}

  public transform(file: FileData<File>, accept?: string): Observable<string> {
    if (!file) {
      return of(null);
    }

    if (accept) {
      if (accept.includes(this.getFileExtensionFromName(file.name))) {
        return this.uploaderService.toBase64(file.file);
      } else {
        return of(null);
      }
    } else {
      return this.uploaderService.toBase64(file.file);
    }
  }

  private getFileExtensionFromName(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  }
}
