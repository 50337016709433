/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RiskProfileRestrictionDTO } from './risk-profile-restriction-dto';

export interface RiskProfileDTO {
  createdAt?: string;
  createdBy?: string;
  description?: string;
  id?: string;
  name?: string;
  restrictions?: Array<RiskProfileRestrictionDTO>;
  riskLevel?: number;
  status?: RiskProfileDTO.StatusEnum;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace RiskProfileDTO {
  export type StatusEnum = 'ACTIVE' | 'DEACTIVATED' | 'DRAFT';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Deactivated: 'DEACTIVATED' as StatusEnum,
    Draft: 'DRAFT' as StatusEnum,
  };
}
