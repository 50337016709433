import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService as NGXTranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { SeoService } from '@shared/helpers/seo.service';
import { Language } from './language';

const LANG_LIST: Language[] = [
  { code: 'ru', name: 'Русский', culture: 'ru-RU' },
  { code: 'en', name: 'English', culture: 'en-US' },
  { code: 'ar', name: 'Arabic UAE', culture: 'ar-AE' },
];
const LANG_DEFAULT: Language = LANG_LIST[1];

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(NGXTranslateService) private ngxTranslateService: NGXTranslateService,
    private seoService: SeoService,
  ) {}

  public readonly defaultLang: Language = LANG_DEFAULT;

  /**
   * Изменение языка
   */
  public changeLang(code: string): void {
    const lang = this.getFindLang(code);
    if (!lang || lang.code === this.ngxTranslateService.currentLang) {
      return;
    }

    localStorage.setItem(LocalStorageConstants.LangCode, lang.code);
    this.setLanguage(lang);
  }

  public get(key: string): string {
    return this.ngxTranslateService.instant(key);
  }

  public get$(key: string): Observable<string> {
    return this.ngxTranslateService.get(key);
  }

  /**
   * Возвращает список языков
   */
  public getLangList(): Observable<Language[]> {
    return of(LANG_LIST);
  }

  /**
   * Возвращает текущий язык
   */
  public getCurrentLang(): string {
    return this.ngxTranslateService.currentLang;
  }

  /**
   * Устанавливает язык по-умолчанию
   */
  public initLanguage(): Promise<void> {
    return new Promise((resolve): void => {
      this.ngxTranslateService.addLangs(LANG_LIST.map((lang: Language) => lang.code));
      const language = this.getLanguage();

      if (language) {
        this.ngxTranslateService.setDefaultLang(language.code);
      } else {
        this.ngxTranslateService.setDefaultLang(LANG_DEFAULT.code);
      }

      this.setLanguage(language);

      resolve();
    });
  }

  /**
   * служебный метод для возврата языка
   */
  private getLanguage(): Language {
    let language = this.getFindLang(localStorage.getItem(LocalStorageConstants.LangCode));
    if (language) {
      return language;
    }

    language = LANG_DEFAULT;
    localStorage.setItem(LocalStorageConstants.LangCode, language.code);
    return language;
  }

  private getFindLang(code: string): Language | null {
    return code ? LANG_LIST.find((lang: Language) => lang.code === code) : null;
  }

  private setLanguage(lang: Language): void {
    this.ngxTranslateService.use(lang.code).subscribe(() => {
      this.seoService.setMetaTags([{ property: 'og:locale', content: lang.culture }]);
      this.document.documentElement.lang = lang.code;
    });
  }
}
