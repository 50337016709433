import { Injectable } from '@angular/core';
import { Observable, share } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { RecalculatedInstrumentDTO } from '@shared/dto/positions/models';
import { WebSocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class PriceStreamsService extends BaseObject {
  private currencyPairsStreams = new Map<string, Observable<RecalculatedInstrumentDTO>>();
  private instrumentsStreams = new Map<string, Observable<RecalculatedInstrumentDTO>>();

  constructor(private ws: WebSocketService) {
    super();
  }

  public getInstrumentPrice(instrumentId: string): Observable<RecalculatedInstrumentDTO> {
    if (!this.instrumentsStreams.has(instrumentId)) {
      this.instrumentsStreams.set(
        instrumentId,
        this.instrumentSocketPrice(instrumentId).pipe(share()),
      );
    }

    return this.instrumentsStreams.get(instrumentId);
  }

  public getCurrencyPairPrice(pair: string): Observable<RecalculatedInstrumentDTO> {
    if (!this.currencyPairsStreams.has(pair)) {
      this.currencyPairsStreams.set(pair, this.instrumentSocketPrice(pair).pipe(share()));
    }

    return this.currencyPairsStreams.get(pair);
  }

  private instrumentSocketPrice(
    instrumentId: string,
    stopListenEvents?: Observable<unknown>[],
  ): Observable<RecalculatedInstrumentDTO> {
    const headers = {
      instrumentId,
    };

    return this.ws.listenChannel<RecalculatedInstrumentDTO>(
      `/topic/instruments.${instrumentId}.price`,
      stopListenEvents,
      headers,
    );
  }
}
