/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortfolioManagerDTO } from './portfolio-manager-dto';
import { CompanyTinyDTO } from './company-tiny-dto';
import { PortfolioNameDTO } from './portfolio-name-dto';
import { BankAccountAndAccountDTO } from './bank-account-and-account-dto';
import { ViewMandateDTO } from './view-mandate-dto';

export interface PortfolioDetailsDTO {
  accountId?: string;
  accountName?: string;
  bankAccounts?: Array<BankAccountAndAccountDTO>;
  ccy?: string;
  company?: CompanyTinyDTO;
  consolidatedPortfolioNames?: Array<PortfolioNameDTO>;
  consolidatedPortfolios?: Array<string>;
  createdAt?: string;
  createdBy?: string;
  globalAllocationId?: string;
  id?: string;
  internalName?: string;
  managerCommissions?: { [key: string]: number };
  mandate?: ViewMandateDTO;
  name?: string;
  newCalc?: boolean;
  notePrice100?: boolean;
  opportunityId?: string;
  portfolioManagers?: Array<PortfolioManagerDTO>;
  privateAccess?: boolean;
  startDate?: string;
  status?: PortfolioDetailsDTO.StatusEnum;
  type: PortfolioDetailsDTO.TypeEnum;
  updatedAt?: string;
  updatedBy?: string;
  withIban?: boolean;
}
export namespace PortfolioDetailsDTO {
  export type StatusEnum = 'Active' | 'Closed' | 'Deactivated' | 'Draft';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Closed: 'Closed' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
    Draft: 'Draft' as StatusEnum,
  };
  export type TypeEnum = 'CLIENT' | 'COMPANY' | 'CONSOLIDATED' | 'VIRTUAL';
  export const TypeEnum = {
    Client: 'CLIENT' as TypeEnum,
    Company: 'COMPANY' as TypeEnum,
    Consolidated: 'CONSOLIDATED' as TypeEnum,
    Virtual: 'VIRTUAL' as TypeEnum,
  };
}
