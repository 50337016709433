import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, interval, timer } from 'rxjs';
import { finalize, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { AuthService, TOKEN_EXPIRATION_BEFORE_SEC } from '@shared/helpers/auth.service';

@Component({
  selector: 'app-session-expiration',
  templateUrl: './session-expiration.component.html',
  styleUrls: ['./session-expiration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpirationComponent extends BaseObject implements OnInit {
  private secondsLeft = TOKEN_EXPIRATION_BEFORE_SEC / 2;
  public secondsLeft$: BehaviorSubject<number>;

  constructor(
    private dialogRef: MatDialogRef<SessionExpirationComponent, boolean>,
    private auth: AuthService,
  ) {
    super();

    this.secondsLeft$ = new BehaviorSubject<number>(this.secondsLeft);
  }

  public ngOnInit(): void {
    this.auth.sessionInFinalCountdown = true;

    interval(1000)
      .pipe(
        takeWhile(() => this.secondsLeft > 0),
        tap(() => (this.secondsLeft -= 1)),
        map(() => this.secondsLeft),
        finalize(() => {
          if (this.secondsLeft === 0) {
            this.dialogRef.close(false);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((secondsLeft) => {
        this.secondsLeft$.next(secondsLeft);
      });

    this.dialogRef.afterClosed().subscribe((continueWork) => {
      if (!continueWork) {
        if (this.secondsLeft) {
          timer(this.secondsLeft * 1000).subscribe(() => {
            this.auth.sessionInFinalCountdown = false;
            this.auth.logout();
          });
        } else {
          this.auth.sessionInFinalCountdown = false;
          this.auth.logout();
        }
      } else {
        this.auth.sessionInFinalCountdown = false;
      }
    });
  }

  public _onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public _onContinueClick(): void {
    this.dialogRef.close(true);
  }
}
