/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InstrumentCompanyTagDTO } from './instrument-company-tag-dto';
import { FieldFullDTO } from './field-full-dto';

export interface InstrumentFieldsFullDTO {
  assetClassification?: InstrumentFieldsFullDTO.AssetClassificationEnum;
  assetSubType?: InstrumentFieldsFullDTO.AssetSubTypeEnum;
  assetType?: InstrumentFieldsFullDTO.AssetTypeEnum;
  bloombergId?: string;
  ccyField?: string;
  coupon?: number;
  couponType?: string;
  createdAt?: string;
  createdBy?: string;
  fields?: Array<FieldFullDTO>;
  fullName?: string;
  id?: string;
  instrumentCompanyTags?: Array<InstrumentCompanyTagDTO>;
  instrumentId?: string;
  isin?: string;
  maturity?: string;
  minAmountField?: number;
  minIncrementField?: number;
  name?: string;
  noteType?: InstrumentFieldsFullDTO.NoteTypeEnum;
  oldId?: string;
  priceField?: number;
  status?: InstrumentFieldsFullDTO.StatusEnum;
  statusDate?: string;
  statusReason?: InstrumentFieldsFullDTO.StatusReasonEnum;
  tags?: Array<string>;
  updatedAt?: string;
  updatedBy?: string;
  vpId?: string;
}
export namespace InstrumentFieldsFullDTO {
  export type AssetClassificationEnum =
    | 'DIVERSIFIED_STRATEGIES'
    | 'DIVERSIFIED_STRATEGIES_LBO_VC'
    | 'EVENT_DRIVEN'
    | 'INFRASTRUCTURE'
    | 'OPPORTUNISTIC_MACRO'
    | 'OTHER_HEDGE_FUNDS'
    | 'REIT'
    | 'RELATIVE_VALUE_CREDIT';
  export const AssetClassificationEnum = {
    DiversifiedStrategies: 'DIVERSIFIED_STRATEGIES' as AssetClassificationEnum,
    DiversifiedStrategiesLboVc: 'DIVERSIFIED_STRATEGIES_LBO_VC' as AssetClassificationEnum,
    EventDriven: 'EVENT_DRIVEN' as AssetClassificationEnum,
    Infrastructure: 'INFRASTRUCTURE' as AssetClassificationEnum,
    OpportunisticMacro: 'OPPORTUNISTIC_MACRO' as AssetClassificationEnum,
    OtherHedgeFunds: 'OTHER_HEDGE_FUNDS' as AssetClassificationEnum,
    Reit: 'REIT' as AssetClassificationEnum,
    RelativeValueCredit: 'RELATIVE_VALUE_CREDIT' as AssetClassificationEnum,
  };
  export type AssetSubTypeEnum =
    | 'AIRPLANE'
    | 'ALTERNATIVE_ASSETS'
    | 'ART_AND_COLLECTIBLES'
    | 'BASKET'
    | 'BOND'
    | 'BUILDINGS'
    | 'CAR'
    | 'CASH'
    | 'COMMODITIES'
    | 'CURRENCY'
    | 'DEBT'
    | 'DEPOSIT'
    | 'DIRECT'
    | 'EQUITIES'
    | 'FIXED_INCOME'
    | 'FUNDS'
    | 'FUTURES'
    | 'FX'
    | 'GRANTED_LOAN'
    | 'HARD_ASSETS'
    | 'HEDGEFUND'
    | 'HELICOPTER'
    | 'INDEX'
    | 'INTEREST_RATE'
    | 'LAND'
    | 'LOAN'
    | 'NOTE'
    | 'OPTION'
    | 'OTHER'
    | 'PRIVATE_EQUITY'
    | 'REAL_ESTATE'
    | 'REAL_ESTATE_INFRASTRUCTURE'
    | 'REVERSE'
    | 'STOCK_DIVIDEND'
    | 'SWAP'
    | 'TREASURY_BILL'
    | 'WARRANT';
  export const AssetSubTypeEnum = {
    Airplane: 'AIRPLANE' as AssetSubTypeEnum,
    AlternativeAssets: 'ALTERNATIVE_ASSETS' as AssetSubTypeEnum,
    ArtAndCollectibles: 'ART_AND_COLLECTIBLES' as AssetSubTypeEnum,
    Basket: 'BASKET' as AssetSubTypeEnum,
    Bond: 'BOND' as AssetSubTypeEnum,
    Buildings: 'BUILDINGS' as AssetSubTypeEnum,
    Car: 'CAR' as AssetSubTypeEnum,
    Cash: 'CASH' as AssetSubTypeEnum,
    Commodities: 'COMMODITIES' as AssetSubTypeEnum,
    Currency: 'CURRENCY' as AssetSubTypeEnum,
    Debt: 'DEBT' as AssetSubTypeEnum,
    Deposit: 'DEPOSIT' as AssetSubTypeEnum,
    Direct: 'DIRECT' as AssetSubTypeEnum,
    Equities: 'EQUITIES' as AssetSubTypeEnum,
    FixedIncome: 'FIXED_INCOME' as AssetSubTypeEnum,
    Funds: 'FUNDS' as AssetSubTypeEnum,
    Futures: 'FUTURES' as AssetSubTypeEnum,
    Fx: 'FX' as AssetSubTypeEnum,
    GrantedLoan: 'GRANTED_LOAN' as AssetSubTypeEnum,
    HardAssets: 'HARD_ASSETS' as AssetSubTypeEnum,
    Hedgefund: 'HEDGEFUND' as AssetSubTypeEnum,
    Helicopter: 'HELICOPTER' as AssetSubTypeEnum,
    Index: 'INDEX' as AssetSubTypeEnum,
    InterestRate: 'INTEREST_RATE' as AssetSubTypeEnum,
    Land: 'LAND' as AssetSubTypeEnum,
    Loan: 'LOAN' as AssetSubTypeEnum,
    Note: 'NOTE' as AssetSubTypeEnum,
    Option: 'OPTION' as AssetSubTypeEnum,
    Other: 'OTHER' as AssetSubTypeEnum,
    PrivateEquity: 'PRIVATE_EQUITY' as AssetSubTypeEnum,
    RealEstate: 'REAL_ESTATE' as AssetSubTypeEnum,
    RealEstateInfrastructure: 'REAL_ESTATE_INFRASTRUCTURE' as AssetSubTypeEnum,
    Reverse: 'REVERSE' as AssetSubTypeEnum,
    StockDividend: 'STOCK_DIVIDEND' as AssetSubTypeEnum,
    Swap: 'SWAP' as AssetSubTypeEnum,
    TreasuryBill: 'TREASURY_BILL' as AssetSubTypeEnum,
    Warrant: 'WARRANT' as AssetSubTypeEnum,
  };
  export type AssetTypeEnum =
    | 'ALTERNATIVE_ASSETS'
    | 'CASH'
    | 'CERTIFICATE'
    | 'COMMODITIES'
    | 'CRYPTO'
    | 'DEPOSIT'
    | 'DERIVATIVE'
    | 'EQUITIES'
    | 'ETF'
    | 'FIXED_INCOME'
    | 'FUNDS'
    | 'FUTURES'
    | 'FX'
    | 'GRANTED_LOAN'
    | 'HEDGE_FUND'
    | 'INDEX'
    | 'INTANGIBLE_ASSET'
    | 'LOAN'
    | 'MUTUAL_FUND'
    | 'OPTIONS'
    | 'OTHER'
    | 'PRIVATE_EQUITY'
    | 'RECOMMENDATION'
    | 'REPO'
    | 'SP'
    | 'STRATEGY'
    | 'TANGIBLE_ASSET';
  export const AssetTypeEnum = {
    AlternativeAssets: 'ALTERNATIVE_ASSETS' as AssetTypeEnum,
    Cash: 'CASH' as AssetTypeEnum,
    Certificate: 'CERTIFICATE' as AssetTypeEnum,
    Commodities: 'COMMODITIES' as AssetTypeEnum,
    Crypto: 'CRYPTO' as AssetTypeEnum,
    Deposit: 'DEPOSIT' as AssetTypeEnum,
    Derivative: 'DERIVATIVE' as AssetTypeEnum,
    Equities: 'EQUITIES' as AssetTypeEnum,
    Etf: 'ETF' as AssetTypeEnum,
    FixedIncome: 'FIXED_INCOME' as AssetTypeEnum,
    Funds: 'FUNDS' as AssetTypeEnum,
    Futures: 'FUTURES' as AssetTypeEnum,
    Fx: 'FX' as AssetTypeEnum,
    GrantedLoan: 'GRANTED_LOAN' as AssetTypeEnum,
    HedgeFund: 'HEDGE_FUND' as AssetTypeEnum,
    Index: 'INDEX' as AssetTypeEnum,
    IntangibleAsset: 'INTANGIBLE_ASSET' as AssetTypeEnum,
    Loan: 'LOAN' as AssetTypeEnum,
    MutualFund: 'MUTUAL_FUND' as AssetTypeEnum,
    Options: 'OPTIONS' as AssetTypeEnum,
    Other: 'OTHER' as AssetTypeEnum,
    PrivateEquity: 'PRIVATE_EQUITY' as AssetTypeEnum,
    Recommendation: 'RECOMMENDATION' as AssetTypeEnum,
    Repo: 'REPO' as AssetTypeEnum,
    Sp: 'SP' as AssetTypeEnum,
    Strategy: 'STRATEGY' as AssetTypeEnum,
    TangibleAsset: 'TANGIBLE_ASSET' as AssetTypeEnum,
  };
  export type NoteTypeEnum = 'ALL_IN' | 'CONDITIONAL' | 'FIXED';
  export const NoteTypeEnum = {
    AllIn: 'ALL_IN' as NoteTypeEnum,
    Conditional: 'CONDITIONAL' as NoteTypeEnum,
    Fixed: 'FIXED' as NoteTypeEnum,
  };
  export type StatusEnum = 'CLOSED' | 'OPEN';
  export const StatusEnum = {
    Closed: 'CLOSED' as StatusEnum,
    Open: 'OPEN' as StatusEnum,
  };
  export type StatusReasonEnum =
    | 'CALLED'
    | 'CREATED'
    | 'DEFAULT'
    | 'DELISTED'
    | 'EARLY_REDEMPTION'
    | 'ISIN_CHANGED'
    | 'MANUAL'
    | 'MATURED';
  export const StatusReasonEnum = {
    Called: 'CALLED' as StatusReasonEnum,
    Created: 'CREATED' as StatusReasonEnum,
    Default: 'DEFAULT' as StatusReasonEnum,
    Delisted: 'DELISTED' as StatusReasonEnum,
    EarlyRedemption: 'EARLY_REDEMPTION' as StatusReasonEnum,
    IsinChanged: 'ISIN_CHANGED' as StatusReasonEnum,
    Manual: 'MANUAL' as StatusReasonEnum,
    Matured: 'MATURED' as StatusReasonEnum,
  };
}
