/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Filter {
  fieldName: string;
  operator: Filter.OperatorEnum;
  values: Array<string>;
}
export namespace Filter {
  export type OperatorEnum =
    | 'between'
    | 'betweenFields'
    | 'betweenValue'
    | 'contains'
    | 'endAnd'
    | 'endOr'
    | 'endsWith'
    | 'equals'
    | 'equalsField'
    | 'equalsValue'
    | 'greaterThan'
    | 'greaterThanField'
    | 'greaterThanOrEqual'
    | 'greaterThanOrEqualField'
    | 'greaterThanOrEqualValue'
    | 'greaterThanValue'
    | 'in'
    | 'isNotNull'
    | 'isNull'
    | 'lessThan'
    | 'lessThanField'
    | 'lessThanOrEqual'
    | 'lessThanOrEqualField'
    | 'lessThanOrEqualValue'
    | 'lessThanValue'
    | 'like'
    | 'likeSensitive'
    | 'notBetween'
    | 'notBetweenValue'
    | 'notContains'
    | 'notEqual'
    | 'notEqualField'
    | 'notEqualValue'
    | 'notIn'
    | 'notLike'
    | 'notLikeSensitive'
    | 'startAnd'
    | 'startOr'
    | 'startsWith';
  export const OperatorEnum = {
    Between: 'between' as OperatorEnum,
    BetweenFields: 'betweenFields' as OperatorEnum,
    BetweenValue: 'betweenValue' as OperatorEnum,
    Contains: 'contains' as OperatorEnum,
    EndAnd: 'endAnd' as OperatorEnum,
    EndOr: 'endOr' as OperatorEnum,
    EndsWith: 'endsWith' as OperatorEnum,
    Equals: 'equals' as OperatorEnum,
    EqualsField: 'equalsField' as OperatorEnum,
    EqualsValue: 'equalsValue' as OperatorEnum,
    GreaterThan: 'greaterThan' as OperatorEnum,
    GreaterThanField: 'greaterThanField' as OperatorEnum,
    GreaterThanOrEqual: 'greaterThanOrEqual' as OperatorEnum,
    GreaterThanOrEqualField: 'greaterThanOrEqualField' as OperatorEnum,
    GreaterThanOrEqualValue: 'greaterThanOrEqualValue' as OperatorEnum,
    GreaterThanValue: 'greaterThanValue' as OperatorEnum,
    In: 'in' as OperatorEnum,
    IsNotNull: 'isNotNull' as OperatorEnum,
    IsNull: 'isNull' as OperatorEnum,
    LessThan: 'lessThan' as OperatorEnum,
    LessThanField: 'lessThanField' as OperatorEnum,
    LessThanOrEqual: 'lessThanOrEqual' as OperatorEnum,
    LessThanOrEqualField: 'lessThanOrEqualField' as OperatorEnum,
    LessThanOrEqualValue: 'lessThanOrEqualValue' as OperatorEnum,
    LessThanValue: 'lessThanValue' as OperatorEnum,
    Like: 'like' as OperatorEnum,
    LikeSensitive: 'likeSensitive' as OperatorEnum,
    NotBetween: 'notBetween' as OperatorEnum,
    NotBetweenValue: 'notBetweenValue' as OperatorEnum,
    NotContains: 'notContains' as OperatorEnum,
    NotEqual: 'notEqual' as OperatorEnum,
    NotEqualField: 'notEqualField' as OperatorEnum,
    NotEqualValue: 'notEqualValue' as OperatorEnum,
    NotIn: 'notIn' as OperatorEnum,
    NotLike: 'notLike' as OperatorEnum,
    NotLikeSensitive: 'notLikeSensitive' as OperatorEnum,
    StartAnd: 'startAnd' as OperatorEnum,
    StartOr: 'startOr' as OperatorEnum,
    StartsWith: 'startsWith' as OperatorEnum,
  };
}
