import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, CanMatchFn } from '@angular/router';
import { RoleState } from '@shared/states/role.state';
import { Authority } from '@shared/types/authority';

export const AuthoritiesCanActivateGuard: CanActivateFn = (route) => {
  return hasAuthorities(route.data?.authorities as Authority[]);
};

export const AuthoritiesCanActivateChildGuard: CanActivateChildFn = (route) => {
  return hasAuthorities(route.data?.authorities as Authority[]);
};

export const AuthoritiesCanMatchGuard: CanMatchFn = (route) => {
  return hasAuthorities(route.data?.authorities as Authority[]);
};

function hasAuthorities(authorities: Authority[]): boolean {
  if (authorities?.length) {
    return inject(RoleState).hasAuthorities(authorities);
  }

  return true;
}
