import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commonCase',
})
export class CommonCasePipe implements PipeTransform {
  public transform(str: string, lowerCase: boolean = true): string {
    if (!str) {
      return null;
    }

    // Проверка SNAKE_UPPERCASE строки из нескольких слов
    if (str.includes('_')) {
      return lowerCase ? str.toLowerCase().split('_').join(' ') : str.split('_').join(' ');
    }

    // Проверка обычной строки из нескольких слов
    if (str.includes(' ')) {
      return lowerCase ? str.toLowerCase() : str;
    }

    const strings = str.split(/(?=[A-Z])/g);
    // Проверка обычной строки из одного слова и строки, состоящей из одного UPPERCASE слова, т.к. у него нет символа _
    if (strings.length === str.length || strings.length < 2) {
      return lowerCase ? str.toLowerCase() : str;
    }

    // CamelCase строка по-умолчанию
    return str
      .split(/(?=[A-Z])/g)
      .join(' ')
      .toLowerCase();
  }
}
