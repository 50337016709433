import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { NotificationActionsService } from 'app/notifications/notifications.actions.service';
import { NotificationsService } from '../notifications.service';
import { FirebaseMessage } from '../notifications.types';

@Component({
  selector: 'app-notifications-container',
  templateUrl: './notifications-container.component.html',
  styleUrls: ['./notifications-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsContainerComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.updateNotificationPosition();
  }

  public messages: FirebaseMessage[] = [];

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private notificationsService: NotificationsService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private notificationActionsService: NotificationActionsService,
  ) {}

  public ngOnInit(): void {
    this.notificationsService.pushNotificationMessage$
      .pipe(filter((v) => !!v))
      .subscribe((message) => {
        this.updateNotifications(message);
      });
  }

  public removeNotification(index: number): void {
    this.messages.splice(index, 1);
    this.cd.detectChanges();
  }

  public showNotificationAction(
    message: Pick<FirebaseMessage['notification'], 'parameters' | 'type'>,
  ): void {
    this.notificationActionsService.execute(message.parameters, message.type);
  }

  private updateNotifications(message: FirebaseMessage): void {
    if (this.messages.length >= 4) {
      this.messages.pop();
    }
    this.updateNotificationPosition();
    this.messages.push(message);
    this.cd.detectChanges();
  }

  private updateNotificationPosition(): void {
    const container: HTMLElement = this.document.querySelector('.app-page-container');
    const windowWidth = this.document.documentElement.clientWidth;
    let right = 2;

    if (!container || !container.offsetWidth) {
      return;
    }

    if (container.offsetWidth < windowWidth) {
      right = windowWidth - (container.offsetLeft + container.offsetWidth);
    }

    this.renderer.setStyle(this.el.nativeElement, 'top', `${70}px`);
    this.renderer.setStyle(this.el.nativeElement, 'right', `${right}px`);
  }
}
