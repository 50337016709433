import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanHtmlTags',
})
export class CleanHtmlTagsPipe implements PipeTransform {
  public transform(value: string): string {
    return value ? value.replace(/(<([^>]+)>)/gi, '') : null;
  }
}
