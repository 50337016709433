import { Pipe, PipeTransform } from '@angular/core';
import { FieldFullDTO, FieldMetaDTO } from '@shared/dto/alternative-assets/models';

@Pipe({
  name: 'fieldValueForType',
})
export class FieldValueForTypePipe implements PipeTransform {
  public transform(value: FieldFullDTO): string | number | boolean {
    switch (value.meta.type) {
      case FieldMetaDTO.TypeEnum.String:
        return value.stringValue ?? '-';

      case FieldMetaDTO.TypeEnum.Int:
        return value.intValue ?? '-';

      case FieldMetaDTO.TypeEnum.Double:
        return value.doubleValue ?? '-';

      case FieldMetaDTO.TypeEnum.Date:
        return value.dateValue ?? '-';

      case FieldMetaDTO.TypeEnum.Boolean:
        return value.booleanValue ?? '-';

      case FieldMetaDTO.TypeEnum.Long:
        return value.longValue ?? '-';

      case FieldMetaDTO.TypeEnum.Timestamp:
        return value.timestampValue ?? '-';

      default:
        return '-';
    }
  }
}
