<span>{{'msg.info.cookiePolicy'|translate}}</span>

<span class="app-cookie-accept__link"
      (click)="goToCookiePolicy()">
  {{'msg.info.cookiePolicy2'|translate}}
</span>

<app-button type="secondary"
            (click)="onYesClick()">
  {{ 'common.yes'|translate }}
</app-button>