/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientDTO } from './client-dto';

export interface MandateAuthorizedPersonDTO {
  client: ClientDTO;
  createdAt?: string;
  createdBy?: string;
  externalUserId?: string;
  id?: string;
  main?: boolean;
  mandateId: string;
  read?: boolean;
  status: MandateAuthorizedPersonDTO.StatusEnum;
  subscribeForEmails?: boolean;
  updatedAt?: string;
  updatedBy?: string;
  write?: boolean;
}
export namespace MandateAuthorizedPersonDTO {
  export type StatusEnum = 'ACCESS' | 'NO_ACCESS';
  export const StatusEnum = {
    Access: 'ACCESS' as StatusEnum,
    NoAccess: 'NO_ACCESS' as StatusEnum,
  };
}
