import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { contentAnimation } from '@shared/animations/animations';
import { BaseObject } from '@shared/base/base-object';

@Component({
  selector: 'app-no-data-notification',
  templateUrl: './no-data-notification.component.html',
  styleUrls: ['./no-data-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [contentAnimation],
  host: {
    '[@contentAnimation]': 'true',
    '[class.app-text-danger]': 'isErrorState',
    '[attr.is-shadow]': '!!shadow',
    '[attr.absolute]': '!!absolute',
    '[attr.is-small]': '!!small',
  },
})
export class NoDataNotificationComponent extends BaseObject {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public isErrorState: boolean;
  @Input() public isEmptyState: boolean = true;
  @Input() public shadow: boolean = true;
  @Input() public small: boolean = false;
  @Input() public absolute: boolean = true;

  constructor() {
    super();
  }
}
