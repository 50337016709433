import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationData, ConfirmationResult, ConfirmationTypeResult } from './confirmation.types';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationData,
    private dialogRef: MatDialogRef<ConfirmationComponent, ConfirmationResult>,
  ) {}

  public _onCloseClick(): void {
    this.dialogRef.close({ closeType: ConfirmationTypeResult.Close });
  }

  public _onConfirmClick(): void {
    this.dialogRef.close({ closeType: ConfirmationTypeResult.Yes });
  }
}
