import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FileData } from '@ui/uploader/uploader.types';

@Injectable({
  providedIn: 'root',
})
export class UploaderService {
  constructor(private sanitizer: DomSanitizer) {}

  public toBase64(file: Blob, needSanitize: boolean = false): Observable<string> {
    const res = new ReplaySubject<string>(1);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (needSanitize) {
        res.next(this.sanitizer.bypassSecurityTrustUrl(`${reader.result}`) as string);
      } else {
        res.next(reader.result as string);
      }
    };
    reader.onerror = (error) => res.error(error);

    return res.pipe(take(1));
  }

  public fileToBase64(file: File): Observable<FileData<string>> {
    const needSanitize = file.type === 'image/svg+xml';

    return this.toBase64(file, needSanitize).pipe(
      map((data) => {
        return {
          name: file.name,
          file: data,
        } as FileData<string>;
      }),
    );
  }
}
