import { Authorities } from '@shared/constants/authorities';
import { NavbarItem } from './navbar.types';

export const ALL_NAVBAR_ITEMS: NavbarItem[] = [
  {
    name: 'pages.dashboard.name',
    url: '/dashboard',
    authorities: [Authorities.DashboardView],
  },
  {
    name: 'pages.portfolios.name',
    url: '/portfolios',
    authorities: [Authorities.PortfoliosView],
  },
  {
    name: 'pages.positions.name',
    url: '/positions',
    authorities: [Authorities.PositionsView],
  },
  {
    name: 'pages.products.name',
    url: '/products',
    authorities: [Authorities.StrategiesView, Authorities.RecommendationView],
  },
  {
    name: 'pages.transactions.name',
    url: '/transactions',
    authorities: [Authorities.TransactionsView],
  },
  {
    name: 'pages.instruments.name',
    url: '/instruments',
    authorities: [Authorities.InstrumentsView],
  },
  {
    name: 'pages.screener.name',
    url: '/screener',
    authorities: [Authorities.ModelPortfoliosView],
  },
  {
    name: 'pages.risks.name',
    url: '/risks',
    authorities: [
      Authorities.MandateRiskProfileView,
      Authorities.RestrictionAlertView,
      Authorities.RiskReportView,
    ],
  },
  {
    name: 'marketplace.name',
    url: '/marketplace',
    authorities: [Authorities.MarketplaceView],
  },
  {
    name: 'pages.accounts.name',
    url: '/accounts',
    authorities: [
      Authorities.AccountsView,
      Authorities.BankAccountsView,
      Authorities.CompaniesView,
      Authorities.ClientsView,
      Authorities.MandatesView,
      Authorities.ManagersView,
    ],
  },
  {
    name: 'pages.reports.name',
    url: '/reports',
    authorities: [Authorities.ReportsView],
  },
  {
    name: 'common.import',
    url: '/import',
    authorities: [
      Authorities.ManualImportPositionsView,
      Authorities.ManualImportTransactionsView,
      Authorities.TransactionLoaderView,
    ],
  },
];
