import { Pipe, PipeTransform } from '@angular/core';
import sumBy from 'lodash-es/sumBy';

@Pipe({
  name: 'sumArray',
})
export class SumArrayPipe implements PipeTransform {
  public transform<T>(array: T[], fn: (item: T) => number): number {
    return sumBy(array, fn);
  }
}
