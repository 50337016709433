import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { BehaviorSubject } from 'rxjs';
import { MenuRef } from '../menu/menu-ref';
import { MenuItem } from './menu-item';
import { MenuItemTemplateContext } from './menu-panel.types';

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-menu-panel',
  },
})
export class MenuPanelComponent {
  public readonly menuItemTemplateContext: MenuItemTemplateContext;

  @Input() public set items(value: MenuItem[]) {
    this.items$.next(value);
  }

  @Input() public menuItemTemplate: TemplateRef<MenuItemTemplateContext>;

  public items$ = new BehaviorSubject<MenuItem[]>([]);

  @ViewChild(MatMenu, { static: true }) public readonly menu: MatMenu;

  constructor(public menuRef: MenuRef) {}

  public onSelect(item: MenuItem): void {
    this.menuRef.selected.next(item);
  }
}
