/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CriteriaDTO } from './criteria-dto';

export interface RequestApproverDTO {
  approveOrder?: number;
  approverId?: string;
  approverName?: string;
  approverType?: RequestApproverDTO.ApproverTypeEnum;
  criteria?: Array<CriteriaDTO>;
  criteriaPattern?: string;
  fieldName?: string;
  id?: string;
  type?: RequestApproverDTO.TypeEnum;
}
export namespace RequestApproverDTO {
  export type ApproverTypeEnum = 'SIMPLE' | 'SMS';
  export const ApproverTypeEnum = {
    Simple: 'SIMPLE' as ApproverTypeEnum,
    Sms: 'SMS' as ApproverTypeEnum,
  };
  export type TypeEnum = 'DEPARTMENT' | 'FIELD' | 'ROLE' | 'USER';
  export const TypeEnum = {
    Department: 'DEPARTMENT' as TypeEnum,
    Field: 'FIELD' as TypeEnum,
    Role: 'ROLE' as TypeEnum,
    User: 'USER' as TypeEnum,
  };
}
