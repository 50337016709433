import { Pipe, PipeTransform } from '@angular/core';
import { RoleState } from '@shared/states/role.state';

@Pipe({
  name: 'hasRoleCodes',
})
export class HasRoleCodesPipe implements PipeTransform {
  constructor(private roleState: RoleState) {}

  public transform(roleCodes: string[]): boolean {
    if (!roleCodes?.length) {
      return true;
    }

    return !!roleCodes.find((roleCode) => roleCode === this.roleState.role$.value.code);
  }
}
