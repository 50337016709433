import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogsData } from './logs.types';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LogsData,
    private dialogRef: MatDialogRef<LogsComponent, void>,
  ) {}

  public _onCloseClick(): void {
    this.dialogRef.close();
  }
}
