import { Pipe, PipeTransform } from '@angular/core';

type UIBankAccountPositionDTO = { id: string; name: string };
type UIPortfolioPositionDTO = {
  portfolio: {
    bankAccounts:
      | { bankAccount: UIBankAccountPositionDTO; main: boolean }[]
      | { bankAccount: UIBankAccountPositionDTO; main: boolean };
  };
};

@Pipe({
  name: 'getMainBankAccount',
})
export class GetMainBankAccountPipe implements PipeTransform {
  public transform(value: UIPortfolioPositionDTO): UIBankAccountPositionDTO {
    if (Array.isArray(value.portfolio.bankAccounts)) {
      return value.portfolio.bankAccounts.find((bankAccount) => bankAccount.main).bankAccount;
    } else {
      return value.portfolio.bankAccounts.bankAccount;
    }
  }
}
