import { Directive, Input, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

@Directive({
  selector: '[appInputAutofocus]',
})
export class InputAutofocusDirective implements OnInit {
  @Input() public appInputAutofocus: boolean = true;

  constructor(private matInput: MatInput) {}

  public ngOnInit(): void {
    if (this.appInputAutofocus) {
      setTimeout(() => this.matInput.focus());
    }
  }
}
