/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CriteriaDTO {
  fieldName?: string;
  type?: CriteriaDTO.TypeEnum;
  values?: Array<string>;
}
export namespace CriteriaDTO {
  export type TypeEnum =
    | 'between'
    | 'betweenFields'
    | 'contains'
    | 'endsWith'
    | 'equals'
    | 'equalsField'
    | 'greaterThan'
    | 'greaterThanField'
    | 'greaterThanOrEqual'
    | 'greaterThanOrEqualField'
    | 'in'
    | 'inField'
    | 'isNotNull'
    | 'isNull'
    | 'lessThan'
    | 'lessThanField'
    | 'lessThanOrEqual'
    | 'lessThanOrEqualField'
    | 'like'
    | 'likeSensitive'
    | 'notBetween'
    | 'notContains'
    | 'notEqual'
    | 'notEqualField'
    | 'notIn'
    | 'notInField'
    | 'notLike'
    | 'notLikeSensitive'
    | 'prevContains'
    | 'prevNotContains'
    | 'startsWith';
  export const TypeEnum = {
    Between: 'between' as TypeEnum,
    BetweenFields: 'betweenFields' as TypeEnum,
    Contains: 'contains' as TypeEnum,
    EndsWith: 'endsWith' as TypeEnum,
    Equals: 'equals' as TypeEnum,
    EqualsField: 'equalsField' as TypeEnum,
    GreaterThan: 'greaterThan' as TypeEnum,
    GreaterThanField: 'greaterThanField' as TypeEnum,
    GreaterThanOrEqual: 'greaterThanOrEqual' as TypeEnum,
    GreaterThanOrEqualField: 'greaterThanOrEqualField' as TypeEnum,
    In: 'in' as TypeEnum,
    InField: 'inField' as TypeEnum,
    IsNotNull: 'isNotNull' as TypeEnum,
    IsNull: 'isNull' as TypeEnum,
    LessThan: 'lessThan' as TypeEnum,
    LessThanField: 'lessThanField' as TypeEnum,
    LessThanOrEqual: 'lessThanOrEqual' as TypeEnum,
    LessThanOrEqualField: 'lessThanOrEqualField' as TypeEnum,
    Like: 'like' as TypeEnum,
    LikeSensitive: 'likeSensitive' as TypeEnum,
    NotBetween: 'notBetween' as TypeEnum,
    NotContains: 'notContains' as TypeEnum,
    NotEqual: 'notEqual' as TypeEnum,
    NotEqualField: 'notEqualField' as TypeEnum,
    NotIn: 'notIn' as TypeEnum,
    NotInField: 'notInField' as TypeEnum,
    NotLike: 'notLike' as TypeEnum,
    NotLikeSensitive: 'notLikeSensitive' as TypeEnum,
    PrevContains: 'prevContains' as TypeEnum,
    PrevNotContains: 'prevNotContains' as TypeEnum,
    StartsWith: 'startsWith' as TypeEnum,
  };
}
