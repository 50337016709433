import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { UploaderService } from '@shared/helpers/uploader.service';
import { FileData } from './uploader.types';

@Directive({
  selector: '[appDragAndDrop]',
  host: {
    class: 'app-drag-and-drop',
  },
})
export class DragAndDropDirective extends BaseObject {
  @HostBinding('class.app-drag-and-drop--file-over') public fileOver: boolean;

  @Input() public format: 'base64' | 'file' = 'file';
  @Input() public multiple: boolean = true;

  @Input()
  @HostBinding('class.app-drag-and-drop--disabled')
  public disabled: boolean = false;

  @Output() public filesDropped = new EventEmitter<FileData<File | string>[]>();

  constructor(private uploaderService: UploaderService) {
    super();
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  public ondrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    this.emit(files);
  }

  private emit(files: DataTransfer['files']): void {
    if (!files.length) {
      return;
    }

    const allFiles = this.multiple ? (Object.values(files) as File[]) : [files[0]];

    switch (this.format) {
      case 'base64': {
        const observableFiles = allFiles.map((file) => this.uploaderService.fileToBase64(file));

        combineLatest(observableFiles)
          .pipe(takeUntil(this.destroy$))
          .subscribe((filesToUpload) => {
            this.filesDropped.emit(filesToUpload);
          });

        break;
      }

      default:
        this.filesDropped.emit(allFiles.map((file) => ({ name: file.name, file })));
        break;
    }
  }
}
