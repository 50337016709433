import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorData } from './error.types';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ErrorData,
    private dialogRef: MatDialogRef<ErrorComponent, void>,
  ) {}

  public _onCloseClick(): void {
    this.dialogRef.close();
  }
}
