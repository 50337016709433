<mat-menu yPosition="below">
  <ng-container *ngFor="let item of (items$|async)">
    <span *ngIf="!item.children?.length; else showSubMenu"
          class="app-menu-panel_item"
          mat-menu-item
          [attr.id]="item.id"
          [disabled]="item.disabled$|async"
          [class]="item.class"
          (click)="onSelect(item)">
      <ng-container *ngTemplateOutlet="menuItemTemplate || defaultMenuItemTemplate; context: { item: item }">
      </ng-container>
    </span>

    <ng-template #showSubMenu>
      <span [matMenuTriggerFor]="!(item.disabled$|async) ? subMenu.menu : null"
            mat-menu-item
            [attr.id]="item.id"
            [disabled]="item.disabled$|async"
            [class]="item.class"
            class="app-menu-panel_item">
        <ng-container *ngTemplateOutlet="menuItemTemplate || defaultMenuItemTemplate; context: { item: item }">
        </ng-container>
      </span>

      <app-menu-panel #subMenu
                      [items]="item.children"
                      [menuItemTemplate]="menuItemTemplate">
      </app-menu-panel>
    </ng-template>
  </ng-container>
</mat-menu>

<ng-template #defaultMenuItemTemplate
             [ngTemplateContextType]="menuItemTemplateContext"
             let-item="item">
  {{ item.name }}
</ng-template>