import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageData } from './message.types';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MessageData,
    private dialogRef: MatDialogRef<MessageComponent, void>,
  ) {}

  public _onCloseClick(): void {
    this.dialogRef.close();
  }
}
