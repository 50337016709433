import { Pipe, PipeTransform } from '@angular/core';
import chunk from 'lodash-es/chunk';

@Pipe({
  name: 'splitArray',
})
export class SplitArrayPipe implements PipeTransform {
  public transform<T>(value: T[], splitByNumber: number = 2): T[][] {
    return chunk(value, splitByNumber);
  }
}
