import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { RerdirectService } from '@shared/helpers/redirect.service';

@Component({
  selector: 'app-cookie-accept',
  templateUrl: './cookie-accept.component.html',
  styleUrls: ['./cookie-accept.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-cookie-accept',
  },
})
export class CookieAcceptComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<CookieAcceptComponent>,
    private rerdirectService: RerdirectService,
  ) {}

  public onYesClick(): void {
    this.snackBarRef.dismiss();
  }

  public goToCookiePolicy(): void {
    this.rerdirectService.goToCookiePolicy();
  }
}
