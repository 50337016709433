import { InjectionToken } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ColDef, IRowNode, IServerSideGetRowsRequest } from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { IconNames } from '@shared/constants/icons';
import { PreferenceLayout } from '@shared/dto/accounts/models';
import { Filter, RelationDTO } from '@shared/dto/gateway-secured/models';
import { ViewColumnDTO, ViewDTO, ViewFilterDTO } from '@shared/dto/meta-info/models';
import { Authority } from '@shared/types/authority';
import { CustomGroupItem } from './group-selector/group-selector.component';
import { ColumnTypes } from './table-column';
import { TableStateObject } from './table-state-object';

export const TABLE_STATE = new InjectionToken<TableStateObject>('TABLE_STATE');
export const TABLE_ALIAS = new InjectionToken<string>('TABLE_ALIAS');
export const TABLE_DIALOG_ADDITIONAL_DATA = new InjectionToken<unknown>(
  'TABLE_DIALOG_ADDITIONAL_DATA',
);

export const TABLE_CONFIG = 'TABLE_CONFIG';

export type LazyRequest = Partial<IServerSideGetRowsRequest>;

export interface LazyData<RowData = unknown> {
  rows: RowData[];
  rowCount: number | -1;
  errorResponse?: boolean;
}

export interface LazyLoader<RowData = unknown> {
  load(lazyRequest: LazyRequest): Observable<LazyData<RowData>>;
}

export interface TableSelectionEvent<RowData = unknown> {
  tableAlias: string;
  selection: RowData[];
}

export interface TableSortModel {
  colId: string;
  sort: SortConditionEnum;
}

export enum SortConditionEnum {
  reset,
  asc = 'asc',
  desc = 'desc',
}

export enum FixedPositionEnum {
  left = 'left',
  right = 'right',
}

export interface SortState {
  colId: string;
  condition: SortConditionEnum;
}

export type FilterCondition = Extract<
  Filter.OperatorEnum,
  | 'contains'
  | 'notContains'
  | 'startsWith'
  | 'endsWith'
  | 'equals'
  | 'notEqual'
  | 'lessThan'
  | 'greaterThan'
  | 'lessThanOrEqual'
  | 'greaterThanOrEqual'
  | 'between'
  | 'isNull'
  | 'isNotNull'
>;

export enum SearchValueType {
  VALUE_STRING = 'VALUE_STRING',
  COLUMN_FIELD_STRING = 'COLUMN_FIELD_STRING',
  CUSTOM_STRING = 'CUSTOM_STRING',
}

export interface TableFieldFilter {
  condition?: Filter.OperatorEnum;
  searchValue?: string;
  searchValueType?: SearchValueType;
}

export interface TableSearchModel {
  [field: string]: TableFieldFilter[];
}

export interface FilterMenuItem {
  condition: FilterCondition;
  iconName: IconNames;
  title: string;
}

export interface TableConfigPreference extends Omit<PreferenceLayout, 'json'> {
  json: {
    favoriteViewsIds?: string[];
    currentViewId?: string;
  };
}

export interface TableView extends Omit<PreferenceLayout, 'json'> {
  json: ViewDTO;
}

export interface ViewFilter extends Omit<ViewFilterDTO, 'condition'> {
  condition: Filter.OperatorEnum;
}

export interface ChangeCellValueEvent<TRowData = unknown, TValue = unknown> {
  rowIndex: number;
  oldValue: TValue;
  newValue: TValue;
  node: IRowNode;
  colDef: ColDef;
  rowNewData: TRowData;
  eventName?: string;
}

export interface TableFilter<T = unknown> {
  list$?: BehaviorSubject<T[]>;
  selected$: BehaviorSubject<T>;
}

export interface TableSelectorItem<T = string> {
  label: T;
  translatedLabel?: string;
}

export interface TableBuilder {
  entityType: RelationDTO.EntityTypeEnum;
}

export type ViewColumnsMap = Record<string, ViewColumnDTO>;

export interface ViewColumn extends Omit<ViewColumnDTO, 'type'> {
  type?: ColumnTypes;
}

export interface FrontDefaultView {
  alias: string;
  name: string;
  fields: string[];
}

export interface StoreTable {
  groupsExpandedState?: Record<string, boolean>;
}

export interface StoreView {
  layout: { [field: string]: { width?: number } };
}

export interface TableDialogOptions extends MatDialogConfig {
  authorities?: Authority[];
  editable?: boolean;
  rowEditable?: (row: unknown) => boolean;
}

export interface DialogCloseEvent<D = unknown, R = unknown> {
  row: D;
  result: R;
}

export type PnlStateType = 'up' | 'down' | 'flat';

/**
 * Интерфейс основных фильтров таблицы
 * для view, группировки и сабгруппировки
 */
export interface TableFilters {
  /** view для отображения столбцов таблицы и применения параметров фильтрации строк и сортировки*/
  view?: TableFilter<ViewDTO>;
  /** для показа группировки 1 уровня */
  group?: TableFilter<ViewColumnDTO>;
  /** для показа группировки 2 уровня */
  subgroup?: TableFilter<ViewColumnDTO>;
  /** для показа кастомных группировок */
  customGroup?: TableFilter<CustomGroupItem>;
}

export interface TableGroupRowDataWithGroupMeta {
  _hasChildren?: boolean;
  _groupKey?: string;
}

export interface TableRowDataWithHierarchy {
  _hierarchy?: string[];
}
