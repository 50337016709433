import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, filter, takeUntil } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { Authorities } from '@shared/constants/authorities';
import { ClientDTO, ManagerDTO } from '@shared/dto/accounts/models';
import { AuthService } from '@shared/helpers/auth.service';
import { RoleState } from '@shared/states/role.state';
import { SettingsState } from '@shared/states/settings.state';
import { DialogService } from '@ui/dialog/dialog.service';
import { UserSettingsDialogFactory } from 'app/dialogs/user-settings/user-settings-dialog.factory';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent extends BaseObject {
  public userFullName$ = new BehaviorSubject<string>(null);

  public readonly Authorities = Authorities;

  constructor(
    private dialogRef: MatDialogRef<ProfileMenuComponent, void>,
    public roleState: RoleState,
    public authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    public settingsState: SettingsState,
  ) {
    super();

    (
      (this.roleState.userIsClient
        ? this.roleState.client$
        : this.roleState.manager$) as Observable<ClientDTO | ManagerDTO>
    )
      .pipe(
        filter((v) => !!v),
        takeUntil(this.destroy$),
      )
      .subscribe((user) => {
        this.userFullName$.next(`${user.firstName} ${user.lastName}`);
      });
  }

  public _onEditProfileClick(): void {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogService.openLazy<void, void>({ factory: UserSettingsDialogFactory });
    });
  }

  public _onSettingsClick(): void {
    this.router.navigate(['system-settings']);
  }

  public _onLogoutClick(): void {
    this.authService.logout(false, true);
  }

  public _onLoginClick(): void {
    this.router.navigate(['login']);
  }
}
