import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyErrorDirective } from './lazy-error/lazy-error.directive';
import { LazyOutletComponent } from './lazy-outlet/lazy-outlet.component';
import { LazyProgressDirective } from './lazy-progress/lazy-progress.directive';
import { OutletDirective } from './outlet/outlet.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [LazyOutletComponent, OutletDirective, LazyProgressDirective, LazyErrorDirective],
  exports: [LazyOutletComponent, OutletDirective, LazyProgressDirective, LazyErrorDirective],
})
export class CoreModule {}
