import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionData } from '../confirmation/confirmation.types';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionData,
    private dialogRef: MatDialogRef<SubscriptionComponent, void>,
  ) {}

  public _onCloseClick(): void {
    this.dialogRef.close();
  }
}
