export enum LocalStorageConstants {
  Version = 'version',
  TokenHeader = 'X-Auth-Token',
  Token = 'token',
  CurrentTheme = 'current_theme',
  Authorities = 'authorities',
  Role = 'role',
  UserId = 'user_id',
  UserName = 'user_name',
  Name = 'name',
  Email = 'email',
  SessionInFinalCountdown = 'session_in_final_countdown',
  FutureObservations = 'future_observations',
  FullScreen = 'full_screen',
  LastPage = 'last_page',
  LangCode = 'lang_code',
  ConnectorsCurrentDateRange = 'connectors_current_date_range',
  PortfolioId = 'portfolioId',
}
