import { Injectable } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BaseObject } from './base-object';

@Injectable()
export abstract class BaseControl<
    TControl extends AbstractControl = FormControl,
    TValue = TControl['value'],
  >
  extends BaseObject
  implements ControlValueAccessor, Validator
{
  public _onValidatorChange: () => void;
  public _onTouched: () => void;
  public _onChange: <T = TValue>(data: T) => void;
  public disabled: boolean;
  public formControl: TControl;
  public formControlName: string;

  constructor() {
    super();
  }

  public registerOnChange(fn: (value: unknown) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(value: boolean): void {
    this.disabled = value;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return control.errors;
  }

  public writeValue<T extends TValue>(_: T): void {}

  public getValue<T = TValue>(): T {
    return this.formControl.value;
  }

  public getValid(): boolean {
    return this.formControl.valid;
  }
}
