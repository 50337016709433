<span *ngIf="showLabel"
      class="app-timepicker__label subtitle-4 app-text-color-50">
  Time
</span>

<div class="app-timepicker__control">
  <div class="app-timepicker__inputs"
       [formGroup]="formGroup">
    <div class="app-timepicker__block">
      <div class="app-timepicker__input">
        <app-svg [icon]="'ArrowShortDown_16'"
                 class="app-timepicker__icon --up app-bg-10"
                 [disabled]="formGroup.controls.hour.invalid || formGroup.controls.hour.value >= 23"
                 (click)="_onChangeTimePartByClick('hour', true)">
        </app-svg>

        <mat-form-field floatLabel="always">
          <input matInput
                 type="text"
                 mask="Hh"
                 [showMaskTyped]="true"
                 placeHolderCharacter="-"
                 [dropSpecialCharacters]="false"
                 [leadZeroDateTime]="true"
                 autocomplete="off"
                 formControlName="hour">
        </mat-form-field>

        <app-svg [icon]="'ArrowShortDown_16'"
                 class="app-timepicker__icon app-bg-10"
                 [disabled]="formGroup.controls.hour.invalid || formGroup.controls.hour.value < 1"
                 (click)="_onChangeTimePartByClick('hour', false)">
        </app-svg>
      </div>

      <span class="app-timepicker__dots field-value">:</span>
    </div>


    <div class="app-timepicker__block">
      <div class="app-timepicker__input">
        <app-svg [icon]="'ArrowShortDown_16'"
                 class="app-timepicker__icon --up app-bg-10"
                 [disabled]="formGroup.controls.minute.invalid || formGroup.controls.minute.value >= 59"
                 (click)="_onChangeTimePartByClick('minute', true)">
        </app-svg>

        <mat-form-field floatLabel="always">
          <input matInput
                 type="text"
                 mask="m0"
                 [showMaskTyped]="true"
                 placeHolderCharacter="-"
                 [dropSpecialCharacters]="false"
                 [leadZeroDateTime]="true"
                 autocomplete="off"
                 formControlName="minute">
        </mat-form-field>

        <app-svg [icon]="'ArrowShortDown_16'"
                 class="app-timepicker__icon app-bg-10"
                 [disabled]="formGroup.controls.minute.invalid || formGroup.controls.minute.value < 1"
                 (click)="_onChangeTimePartByClick('minute', false)">
        </app-svg>
      </div>

      <span class="app-timepicker__dots field-value">:</span>
    </div>

    <div class="app-timepicker__input">
      <app-svg [icon]="'ArrowShortDown_16'"
               class="app-timepicker__icon --up app-bg-10"
               [disabled]="formGroup.controls.second.invalid || formGroup.controls.second.value >= 59"
               (click)="_onChangeTimePartByClick('second', true)">
      </app-svg>

      <mat-form-field floatLabel="always">
        <input matInput
               type="text"
               mask="m0"
               [showMaskTyped]="true"
               placeHolderCharacter="-"
               [dropSpecialCharacters]="false"
               [leadZeroDateTime]="true"
               autocomplete="off"
               formControlName="second">
      </mat-form-field>

      <app-svg [icon]="'ArrowShortDown_16'"
               class="app-timepicker__icon app-bg-10"
               [disabled]="formGroup.controls.second.invalid || formGroup.controls.second.value < 1"
               (click)="_onChangeTimePartByClick('second', false)">
      </app-svg>
    </div>
  </div>
</div>