import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FirebaseMessage } from '../notifications.types';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-bg-main app-border-05',
  },
})
export class PushNotificationComponent {
  @Input() public message: FirebaseMessage;

  @Output() public closeClick = new EventEmitter<void>();
  @Output() public showClick = new EventEmitter<
    Pick<FirebaseMessage['notification'], 'parameters' | 'type'>
  >();

  constructor() {}

  public onClose(): void {
    this.closeClick.emit();
  }

  public onShow(): void {
    this.showClick.emit({
      parameters: this.message.notification.parameters,
      type: this.message.notification.type,
    });
  }
}
