import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { DateRangePeriod } from './datepicker/daterange-picker.types';
import { InputCustomDateType, InputType } from './input.types';

@Injectable()
export class InputState extends BaseObject {
  public inputType: InputType;
  public customDate: InputCustomDateType;
  public calendar$ = new BehaviorSubject<MatCalendar<Date>>(null);
  public calendarClasses$ = new BehaviorSubject<string[]>(null);
  public datepickerElement: HTMLElement;
  public dateRangePeriod$ = new BehaviorSubject<DateRangePeriod>(null);
  public dateRangeGroup: FormGroup<{ start: FormControl<Date>; end: FormControl<Date> }>;

  constructor() {
    super();
  }
}
