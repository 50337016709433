import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchRequestDTO } from '@shared/dto/gateway-secured/search-request-dto';
import { PushNotificationDTO } from '@shared/dto/notification/models';
import { SettingsState } from '@shared/states/settings.state';
import { LazyData } from '@ui/table/table-types';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public getNotifications(request: SearchRequestDTO): Observable<LazyData<PushNotificationDTO>> {
    return this.http
      .post<PushNotificationDTO[]>(
        `${this.settingsState.apiPath}/push-notifications/search`,
        request,
        {
          observe: 'response',
        },
      )
      .pipe(
        map((res) => {
          return {
            rows: res.body,
            rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
          } as LazyData<PushNotificationDTO>;
        }),
        catchError(() =>
          of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<PushNotificationDTO>),
        ),
      );
  }

  public getActiveNotificationsCount(): Observable<number> {
    return this.http.get<number>(
      `${this.settingsState.apiPath}/push-notifications/count-not-close`,
    );
  }

  public getUnreadPushNotificationsCount(): Observable<number> {
    return this.http.get<number>(`${this.settingsState.apiPath}/push-notifications/count-open`);
  }

  public subscribeToNotifications(firebaseToken: string): Observable<void> {
    return this.http.post<void>(
      `${this.settingsState.apiPath}/push-notification-subscriptions`,
      null,
      {
        params: {
          token: firebaseToken,
        },
      },
    );
  }

  public unsubscribeFromNotifications(firebaseToken: string): Observable<void> {
    return this.http.delete<void>(`${this.settingsState.apiPath}/push-notification-subscriptions`, {
      params: {
        token: firebaseToken,
      },
    });
  }

  public openNotification(notification: PushNotificationDTO): Observable<void> {
    return this.http.put<void>(
      `${this.settingsState.apiPath}/push-notifications/${notification.id}/open`,
      notification,
    );
  }

  public closeNotification(notification: PushNotificationDTO): Observable<void> {
    return this.http.put<void>(
      `${this.settingsState.apiPath}/push-notifications/${notification.id}/close`,
      notification,
    );
  }
}
