/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CriteriaDTO } from './criteria-dto';

export interface MandateFeeDTO {
  ccy: string;
  createdAt?: string;
  createdBy?: string;
  criteria?: Array<CriteriaDTO>;
  criteriaPattern?: string;
  frequency?: MandateFeeDTO.FrequencyEnum;
  id?: string;
  minAmount?: number;
  rate?: number;
  type: MandateFeeDTO.TypeEnum;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace MandateFeeDTO {
  export type FrequencyEnum = 'ANNUALLY' | 'MONTHLY' | 'ONE_TIME' | 'QUARTERLY' | 'SEMI_ANNUAL';
  export const FrequencyEnum = {
    Annually: 'ANNUALLY' as FrequencyEnum,
    Monthly: 'MONTHLY' as FrequencyEnum,
    OneTime: 'ONE_TIME' as FrequencyEnum,
    Quarterly: 'QUARTERLY' as FrequencyEnum,
    SemiAnnual: 'SEMI_ANNUAL' as FrequencyEnum,
  };
  export type TypeEnum =
    | 'ADMINISTRATION'
    | 'ADR_FEE'
    | 'AMENDMENT_FEE'
    | 'ASSIMILATION'
    | 'AUCTION_TENDER_OFFER'
    | 'BLOCKING'
    | 'BLOCKING_OF_CERTIFICATE'
    | 'BUY'
    | 'BUY_ISSUE_IPO'
    | 'CALLED'
    | 'CAPITAL_CALL'
    | 'CAPITAL_INCREASE'
    | 'CASH_TRANSFER_FEE'
    | 'COMMISSION'
    | 'COMPENSATION'
    | 'CONSENT'
    | 'CORP_ACTION'
    | 'COUPON'
    | 'CREDIT'
    | 'CUSTODY'
    | 'DEBIT'
    | 'DEPOSIT'
    | 'DIVIDEND'
    | 'DVP'
    | 'EXCHANGE_FEE'
    | 'EXCHANGE_OF_SECURITES'
    | 'EXECUTION'
    | 'EXPIRY_OF_SECURITY'
    | 'EXPOSURE_FEE'
    | 'FRACTIONAL_SHARE'
    | 'FROM_SPIN_OFF'
    | 'FULL'
    | 'HANDLE_FEE'
    | 'IN'
    | 'INCENTIVE'
    | 'INFO'
    | 'INTEREST_CALCULATION'
    | 'INTERNAL'
    | 'INVOICE'
    | 'ISIN_CHANGE'
    | 'LIQUIDATION'
    | 'LOAN'
    | 'MANAGEMENT'
    | 'MERGE'
    | 'OPERATION'
    | 'OPTIONAL_DIVIDEND'
    | 'ORDER'
    | 'OTHER'
    | 'OTHER_TAX'
    | 'OUT'
    | 'OVERNIGHT_POSITION_FEE'
    | 'PARTIAL'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIBE'
    | 'PUBLIC_OFFER'
    | 'PURCHASE_OFFER'
    | 'REIMBURSEMENT'
    | 'REPO'
    | 'RETRO'
    | 'RETROCESSION'
    | 'REVENUE'
    | 'REVERSE_SPLIT'
    | 'SALES_TAX'
    | 'SECURITIES_LANDING'
    | 'SECURITY_EXCHANGE'
    | 'SELL'
    | 'SERVICE_CHARGE_CALCULATION'
    | 'SETTLEMENT_FEE'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'SPLIT_ISIN_CHANGE'
    | 'STOCK_TRANSFER_FEE'
    | 'SUBSCRIPTION'
    | 'SUCCESS'
    | 'TECHNICAL_CORRECTION'
    | 'TRADING'
    | 'UNBLOCKING'
    | 'UNBLOCKING_BLOCKING'
    | 'VARIATION_MARGIN'
    | 'WITHDRAWAL_FEE'
    | 'WITHHOLDING_TAX';
  export const TypeEnum = {
    Administration: 'ADMINISTRATION' as TypeEnum,
    AdrFee: 'ADR_FEE' as TypeEnum,
    AmendmentFee: 'AMENDMENT_FEE' as TypeEnum,
    Assimilation: 'ASSIMILATION' as TypeEnum,
    AuctionTenderOffer: 'AUCTION_TENDER_OFFER' as TypeEnum,
    Blocking: 'BLOCKING' as TypeEnum,
    BlockingOfCertificate: 'BLOCKING_OF_CERTIFICATE' as TypeEnum,
    Buy: 'BUY' as TypeEnum,
    BuyIssueIpo: 'BUY_ISSUE_IPO' as TypeEnum,
    Called: 'CALLED' as TypeEnum,
    CapitalCall: 'CAPITAL_CALL' as TypeEnum,
    CapitalIncrease: 'CAPITAL_INCREASE' as TypeEnum,
    CashTransferFee: 'CASH_TRANSFER_FEE' as TypeEnum,
    Commission: 'COMMISSION' as TypeEnum,
    Compensation: 'COMPENSATION' as TypeEnum,
    Consent: 'CONSENT' as TypeEnum,
    CorpAction: 'CORP_ACTION' as TypeEnum,
    Coupon: 'COUPON' as TypeEnum,
    Credit: 'CREDIT' as TypeEnum,
    Custody: 'CUSTODY' as TypeEnum,
    Debit: 'DEBIT' as TypeEnum,
    Deposit: 'DEPOSIT' as TypeEnum,
    Dividend: 'DIVIDEND' as TypeEnum,
    Dvp: 'DVP' as TypeEnum,
    ExchangeFee: 'EXCHANGE_FEE' as TypeEnum,
    ExchangeOfSecurites: 'EXCHANGE_OF_SECURITES' as TypeEnum,
    Execution: 'EXECUTION' as TypeEnum,
    ExpiryOfSecurity: 'EXPIRY_OF_SECURITY' as TypeEnum,
    ExposureFee: 'EXPOSURE_FEE' as TypeEnum,
    FractionalShare: 'FRACTIONAL_SHARE' as TypeEnum,
    FromSpinOff: 'FROM_SPIN_OFF' as TypeEnum,
    Full: 'FULL' as TypeEnum,
    HandleFee: 'HANDLE_FEE' as TypeEnum,
    In: 'IN' as TypeEnum,
    Incentive: 'INCENTIVE' as TypeEnum,
    Info: 'INFO' as TypeEnum,
    InterestCalculation: 'INTEREST_CALCULATION' as TypeEnum,
    Internal: 'INTERNAL' as TypeEnum,
    Invoice: 'INVOICE' as TypeEnum,
    IsinChange: 'ISIN_CHANGE' as TypeEnum,
    Liquidation: 'LIQUIDATION' as TypeEnum,
    Loan: 'LOAN' as TypeEnum,
    Management: 'MANAGEMENT' as TypeEnum,
    Merge: 'MERGE' as TypeEnum,
    Operation: 'OPERATION' as TypeEnum,
    OptionalDividend: 'OPTIONAL_DIVIDEND' as TypeEnum,
    Order: 'ORDER' as TypeEnum,
    Other: 'OTHER' as TypeEnum,
    OtherTax: 'OTHER_TAX' as TypeEnum,
    Out: 'OUT' as TypeEnum,
    OvernightPositionFee: 'OVERNIGHT_POSITION_FEE' as TypeEnum,
    Partial: 'PARTIAL' as TypeEnum,
    Payment: 'PAYMENT' as TypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as TypeEnum,
    ProductUnsubscribe: 'PRODUCT_UNSUBSCRIBE' as TypeEnum,
    PublicOffer: 'PUBLIC_OFFER' as TypeEnum,
    PurchaseOffer: 'PURCHASE_OFFER' as TypeEnum,
    Reimbursement: 'REIMBURSEMENT' as TypeEnum,
    Repo: 'REPO' as TypeEnum,
    Retro: 'RETRO' as TypeEnum,
    Retrocession: 'RETROCESSION' as TypeEnum,
    Revenue: 'REVENUE' as TypeEnum,
    ReverseSplit: 'REVERSE_SPLIT' as TypeEnum,
    SalesTax: 'SALES_TAX' as TypeEnum,
    SecuritiesLanding: 'SECURITIES_LANDING' as TypeEnum,
    SecurityExchange: 'SECURITY_EXCHANGE' as TypeEnum,
    Sell: 'SELL' as TypeEnum,
    ServiceChargeCalculation: 'SERVICE_CHARGE_CALCULATION' as TypeEnum,
    SettlementFee: 'SETTLEMENT_FEE' as TypeEnum,
    SpinOff: 'SPIN_OFF' as TypeEnum,
    Split: 'SPLIT' as TypeEnum,
    SplitIsinChange: 'SPLIT_ISIN_CHANGE' as TypeEnum,
    StockTransferFee: 'STOCK_TRANSFER_FEE' as TypeEnum,
    Subscription: 'SUBSCRIPTION' as TypeEnum,
    Success: 'SUCCESS' as TypeEnum,
    TechnicalCorrection: 'TECHNICAL_CORRECTION' as TypeEnum,
    Trading: 'TRADING' as TypeEnum,
    Unblocking: 'UNBLOCKING' as TypeEnum,
    UnblockingBlocking: 'UNBLOCKING_BLOCKING' as TypeEnum,
    VariationMargin: 'VARIATION_MARGIN' as TypeEnum,
    WithdrawalFee: 'WITHDRAWAL_FEE' as TypeEnum,
    WithholdingTax: 'WITHHOLDING_TAX' as TypeEnum,
  };
}
