<div class="app-daterange-picker__body"
     *ngLet="range$|async as _range">
  <div class="app-daterange-picker__side app-border-right-10">
    <div *ngIf="canSetTime && page === DatepickerPage.TimeEditor"
         class="app-daterange-picker__backdrop app-bg-backdrop"
         @contentAnimation>
    </div>

    <mat-button-toggle-group class="--snack app-daterange-picker__snacks app-scrollbar-block"
                             [class.--m-height]="canSetTime && !_range"
                             [class.--s-height]="canSetTime && !!_range"
                             [formControl]="toggleControl">
      <span class="app-daterange-picker__snacks-group subtitle-4 app-text-color-75">
        Popular
      </span>

      <ng-container *ngFor="let period of dateRangePeriods">
        <mat-button-toggle *ngIf="period.value | canShowRangeToggle:minDate:maxDate"
                           [value]="period.value">
          {{ period.name }}
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>

    <ng-container *ngIf="canSetTime">
      <div *ngIf="_range && _range.start && _range.end"
           class="app-daterange-picker__time-card app-bg-block-0">
        <div *ngIf="!!_range"
             class="app-daterange-picker__time-card__body">
          <div class="app-daterange-picker__time-card__from">
            <span class="subtitle-4 app-text-color-50"
                  style="padding-bottom: 8px;">
              From
            </span>

            <span class="subtitle-4"
                  style="padding-bottom: 5px;">
              {{ _range.start | appClone | appTime }}
            </span>
            <span class="subtitle-4"
                  style="padding-bottom: 8px;">
              {{ _range.start | appDate }}
            </span>
          </div>

          <div class="app-daterange-picker__time-card__to">
            <span class="subtitle-4 app-text-color-50"
                  style="padding-bottom: 8px;">
              To
            </span>

            <span class="subtitle-4"
                  style="padding-bottom: 5px;">
              {{ _range.end | appClone | appTime }}
            </span>
            <span class="subtitle-4"
                  style="padding-bottom: 8px;">
              {{ _range.end | appDate }}
            </span>
          </div>
        </div>

        <app-button type="secondary"
                    (click)="_onEditTimeClick()">
          Edit Time
        </app-button>
      </div>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="page">
    <ng-container *ngSwitchCase="DatepickerPage.Calendar">
      <div class="app-daterange-picker__page --without-header"
           @contentAnimation>
        <div class="app-daterange-picker__page__body">
        </div>

        <div class="app-daterange-picker__page__footer">
          <app-button type="secondary"
                      (click)="_onCancelClick()"
                      style="margin-right: 8px;">
            {{'common.cancel'|translate}}
          </app-button>

          <app-button (click)="_onApplyClick()">
            {{'common.apply'|translate}}
          </app-button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="DatepickerPage.TimeEditor">
      <div class="app-daterange-picker__page app-bg-main"
           style="z-index: 1;"
           [formGroup]="rangeGroup"
           @contentAnimation>
        <div class="app-daterange-picker__page__header app-border-bottom-10">
          <span class="heading-4"
                style="margin-top: auto;">
            Edit Time
          </span>
        </div>

        <div class="app-daterange-picker__page__body">
          <div class="app-daterange-picker__time-editor">
            <div class="app-daterange-picker__time-editor__from">
              <span class="subtitle-4 app-text-color-50"
                    style="padding-bottom: 8px;">
                From
              </span>

              <span class="control-body-2"
                    style="padding-bottom: 5px;">
                {{ rangeGroup.controls.start | getChanges | async | appDate }}
              </span>

              <app-timepicker formControlName="start"
                              [showLabel]="false"
                              style="margin-right: 18px;">
              </app-timepicker>
            </div>

            <div class="app-daterange-picker__time-editor__to">
              <span class="subtitle-4 app-text-color-50"
                    style="padding-bottom: 8px;">
                To
              </span>

              <span class="control-body-2"
                    style="padding-bottom: 5px;">
                {{ rangeGroup.controls.end | getChanges | async | appDate }}
              </span>

              <app-timepicker formControlName="end"
                              [showLabel]="false">
              </app-timepicker>
            </div>
          </div>
        </div>

        <div class="app-daterange-picker__page__footer">
          <app-button type="secondary"
                      style="margin-right: 8px;"
                      (click)="_onResetTimeClick()">
            {{'common.reset'|translate}}
          </app-button>

          <app-button (click)="_onApplyTimeClick()">
            {{'common.apply'|translate}}
          </app-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>