import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  /**
   * Перехватывает ошибку при поиске перевода
   */
  public handle(params: MissingTranslationHandlerParams): string {
    if (
      params.key.match(/\w+\.\w+/) &&
      params.translateService.translations['ru'] &&
      !params.translateService.translations['ru'][params.key]
    ) {
      console.warn(`Translation not found for "${params.key}"`);
    }

    return params.key;
  }
}
