import { inject } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { TranslateService } from '@shared/translates/translate.service';

export const LocaleCanActivateGuard: CanActivateFn = () => {
  applyLocale();
  return true;
};

export const LocaleCanActivateChildGuard: CanActivateChildFn = () => {
  applyLocale();
  return true;
};

function applyLocale(): void {
  const locale = 'en-US';
  const lang = 'en';

  inject(TranslateService).changeLang(lang);
  inject(DateAdapter).setLocale(locale);
}
