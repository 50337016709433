export type MediaType = 'text' | 'image' | 'video';

export interface MediaFileType {
  type: MediaType;
  mimeTypes: string[];
  resolutions: string[];
}

export const MEDIA_FILE_TYPES: MediaFileType[] = [
  {
    type: 'text',
    mimeTypes: ['text/plain', 'text/xml', 'text/csv'],
    resolutions: ['txt'],
  },
  {
    type: 'image',
    mimeTypes: ['image/jpeg', 'image/png', 'image/webp'],
    resolutions: ['jpg'],
  },
  {
    type: 'video',
    mimeTypes: ['video/mp4', 'video/webm', 'video/mpeg'],
    resolutions: [],
  },
];
