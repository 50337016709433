import { Directive, Input, TemplateRef } from '@angular/core';
import { SelectComponent } from './select.component';

export interface SelectValueDefContext<TOption> {
  $implicit: TOption;
}

@Directive({
  selector: '[appSelectValueDef]',
})
export class SelectValueDefDirective<TOption> {
  // (Optional) for select options typing inside template.
  // Get current option type from given select component and set the type to SelectValueDefContext
  @Input('appSelectValueDefFor') public for: SelectComponent<TOption>;

  constructor(public template: TemplateRef<SelectValueDefContext<TOption>>) {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard<TOption>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectValueDefDirective<TOption>,
    ctx: unknown,
  ): ctx is SelectValueDefContext<TOption> {
    return true;
  }
}
