import { getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY_REPLACER } from '@shared/constants/table/grid-constants';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { CurrencyCodes, SymbolPositions, THOUSAND_SEPARATOR } from '@shared/types/preferences';
import { AppInjector } from 'app/config/app-injector';
import { GetSignLiteral, NumberPipe } from './number.pipe';

@Pipe({
  name: 'appCcy',
})
export class CcyPipe implements PipeTransform {
  private numberPipe = new NumberPipe();

  public transform(
    value: number,
    ccy?: CurrencyCodes | string,
    decimalsCount?: number,
    showSign: boolean = false,
    isSpaceBetween: boolean = false,
  ): string {
    if (isNaN(value)) {
      return EMPTY_REPLACER;
    }

    const userSettingsState = AppInjector.Injector.get(UserSettingsState);
    const symbolPosition = userSettingsState.preferences.symbolPosition;
    const currency = userSettingsState.preferences.currency;

    const ccySymbol: string = ccy
      ? getCurrencySymbol(CurrencyCodes[ccy.toUpperCase()] || ccy, 'narrow')
      : getCurrencySymbol(currency, 'narrow') || '$';

    const valueStr = this.numberPipe.transform(value, decimalsCount);
    const formattedValue = (valueStr || '0').split(',').join(THOUSAND_SEPARATOR).replace('-', '');
    const signLiteral = GetSignLiteral(value, showSign);
    const additionalSpace = isSpaceBetween ? ' ' : '';

    switch (symbolPosition) {
      case SymbolPositions.Right:
        return `${signLiteral}${formattedValue}${additionalSpace}${ccySymbol}`;

      case SymbolPositions.Left:
      default:
        return `${signLiteral}${ccySymbol}${additionalSpace}${formattedValue}`;
    }
  }
}
