import { Pipe, PipeTransform } from '@angular/core';
import { SNAKE_UPPERCASE_TO_STR } from '@shared/helpers/functions';

@Pipe({
  name: 'snakeUpperCase',
})
export class SnakeUppercasePipe implements PipeTransform {
  public transform(value: string): string {
    return SNAKE_UPPERCASE_TO_STR(value);
  }
}
