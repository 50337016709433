import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { SubscriptionNotificationComponent } from '@ui/notification/subscription-notification/subscription-notification.component';

export interface LayoutRouteData {
  canShowFullScreen?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutState extends BaseObject {
  /** переключатель состояния страницы во весь экран */
  public readonly fullScreen$ = new BehaviorSubject<boolean>(this.fullScreen);

  public readonly showPageHeaderBackground$ = new BehaviorSubject<boolean>(false);
  public readonly showPageLoader$ = new BehaviorSubject<boolean>(false);
  /** видимость фиксированных инструментов приложения */
  public showFixedControls$ = new BehaviorSubject<boolean>(true);
  /** видимость кнопки нотификаций приложения */
  public showNotifications$ = new BehaviorSubject<boolean>(true);
  public canShowFullScreen: boolean = true;
  public subscriptionNotificationData$ = new BehaviorSubject<
    Pick<SubscriptionNotificationComponent, 'title'>
  >(null);

  public readonly navbarHeight = 60; // as SASS variable $navbar-height
  public readonly headerHeight = 50; // as SASS variable $header-height

  public set fullScreen(value: boolean) {
    localStorage.setItem(LocalStorageConstants.FullScreen, value ? '1' : '0');
    this.fullScreen$.next(value);
  }

  public get fullScreen(): boolean {
    return parseInt(localStorage.getItem(LocalStorageConstants.FullScreen), 10) ? true : false;
  }

  constructor() {
    super();
  }
}
