import { Pipe, PipeTransform } from '@angular/core';
import { PortfolioPnlPeriodDTO } from '@shared/dto/positions/models';

@Pipe({
  name: 'appPeriod',
})
export class PeriodPipe implements PipeTransform {
  public transform(periods: PortfolioPnlPeriodDTO[] = [], period: string): PortfolioPnlPeriodDTO {
    return periods.find((p) => p.period === period);
  }
}
