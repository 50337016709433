import { Env } from '../environment.type';

export const environment: Env = {
  enabledProdMode: true,
  apiPath: '/api',
  wsPath: '/websocket',
  metaWsPath: null,
  isReleaseSite: false,
  isDevSite: true,
  firebaseConfig: {
    apiKey: 'AIzaSyD0hUvBiJalnCq6fU0lTIpc-wVUmNRHjFg',
    authDomain: 'luna-c21c1.firebaseapp.com',
    databaseURL: 'https://luna-c21c1.firebaseio.com',
    projectId: 'luna-c21c1',
    storageBucket: 'luna-c21c1.appspot.com',
    messagingSenderId: '487690709203',
    appId: '1:487690709203:web:cc38ab079492485725cc26',
    measurementId: 'G-ZDEMXT89K4',
    vapidKey:
      'BGq_ssWQ3YYeo_JJUu4Y8H8kWRpF-vhOt-j_WUUQ2nv_2ijItNIbDqrTuFKRqHCrEXvzb8UbCeY42SgL7WIxqeI',
  },
};
