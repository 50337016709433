import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { DialogService } from '@ui/dialog/dialog.service';
import { FeedbackComponent } from 'app/dialogs/feedback/feedback.component';

@Component({
  selector: 'app-fixed-controls',
  templateUrl: './fixed-controls.component.html',
  styleUrls: ['./fixed-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedControlsComponent extends BaseObject {
  public feedbackButtonState$ = new BehaviorSubject<'closed' | 'opened'>('closed');

  constructor(private dialogService: DialogService) {
    super();
  }

  public _onClick(): void {
    this.feedbackButtonState$.next('opened');

    this.dialogService
      .open<void, void>(FeedbackComponent, null, {
        width: '450px',
        height: 'auto',
        maxHeight: '70vh',
        autoFocus: false,
        disableClose: true,
        backdropClass: 'app-dialog-backdrop',
      })
      .closed$.subscribe(() => {
        this.feedbackButtonState$.next('closed');
      });
  }
}
