import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BaseObject } from '@shared/base/base-object';

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'danger'
  | 'success'
  | 'warning'
  | 'highlight-warning'
  | 'highlight-success';

@Component({
  selector: 'app-button, button[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-button',
    '[class.--default]': 'size === "default"',
    '[class.--large]': 'size === "large"',
    '[class.--small]': 'size === "small"',
    '[class.--primary]': 'type === "primary"',
    '[class.--secondary]': 'type === "secondary"',
    '[class.--outline]': 'type === "outline"',
    '[class.--danger]': 'type === "danger"',
    '[class.--success]': 'type === "success"',
    '[class.--warning]': 'type === "warning"',
    '[class.--highlight-warning]': 'type === "highlight-warning"',
    '[class.--highlight-success]': 'type === "highlight-success"',
    '[attr.disabled]': 'disabled',
  },
})
export class ButtonComponent extends BaseObject {
  @Input() public type: ButtonTypes = 'primary';
  @Input() public disabled: boolean = false;

  constructor(
    @Attribute('size')
    public size: 'default' | 'large' | 'small',

    public el: ElementRef<HTMLElement>,
  ) {
    super();

    this.size = this.size || 'default';
  }
}
