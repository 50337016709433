import { Pipe, PipeTransform } from '@angular/core';
import { Observable, ObservableInputTuple, combineLatest } from 'rxjs';

@Pipe({
  name: 'combineLatest',
})
export class CombineLatestPipe implements PipeTransform {
  public transform<A extends unknown[]>(sources: [...ObservableInputTuple<A>]): Observable<A> {
    return combineLatest(sources);
  }
}
