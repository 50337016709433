import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountContactDTO } from '@shared/dto/accounts/account-contact-dto';
import { AccountContactIdDTO } from '@shared/dto/accounts/account-contact-id-dto';
import {
  AccountCompanyRequestDTO,
  AccountConciseDTO,
  AccountDTO,
  BankAccountDTO,
  SearchRequestDTO,
} from '@shared/dto/accounts/models';
import { UserBankAccountGroupDTO } from '@shared/dto/accounts/user-bank-account-group-dto';
import { ExcelTableRequestDTO } from '@shared/dto/gateway-secured/models';
import {
  OrderMobileDTO,
  PortfolioAumAndPnlPeriodsDTO,
  PortfolioSummaryDTO,
  Top5PortfolioChartPerformanceWrapperDTO,
  Top5PositionWidgetDTO,
  WidgetDataDTO,
} from '@shared/dto/positions/models';
import { FileLoaderService } from '@shared/helpers/file-loader.service';
import { SettingsState } from '@shared/states/settings.state';
import { PeriodEnum } from '@shared/types/period-enum';
import { LazyData } from '@ui/table/table-types';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  constructor(
    private settingsState: SettingsState,
    private fileLoaderService: FileLoaderService,
    private http: HttpClient,
  ) {}

  public update(account: AccountDTO): Observable<AccountDTO> {
    return this.http
      .put<AccountDTO>(`${this.settingsState.apiPath}/accounts`, account)
      .pipe(catchError(() => of(null)));
  }

  public updateStatus(account: AccountDTO): Observable<boolean> {
    return this.http
      .put<void>(`${this.settingsState.apiPath}/accounts/status/${account.id}`, {
        status: account.status,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public create(account: AccountDTO): Observable<AccountDTO> {
    switch (account.accountType) {
      case 'INDIVIDUAL':
        return this.http
          .post<AccountDTO>(`${this.settingsState.apiPath}/accounts/individual`, account)
          .pipe(catchError(() => of(null)));

      case 'ENTITY':
        return this.http
          .post<AccountDTO>(`${this.settingsState.apiPath}/accounts/entity`, account)
          .pipe(catchError(() => of(null)));

      default:
        return of(null);
    }
  }

  public getAll(): Observable<AccountDTO[]> {
    return this.http
      .get<AccountDTO[]>(`${this.settingsState.apiPath}/accounts`)
      .pipe(catchError(() => of([])));
  }

  public getChildAccounts(accountId: string): Observable<AccountDTO[]> {
    return this.http
      .get<AccountDTO[]>(`${this.settingsState.apiPath}/accounts/${accountId}/child-accounts`)
      .pipe(catchError(() => of([])));
  }

  public getOne(id: string): Observable<AccountDTO> {
    return this.http.get<AccountDTO>(`${this.settingsState.apiPath}/accounts/${id}`).pipe(
      map((data: AccountDTO) => ({ ...data, accountId: data.id })),
      catchError(() => of(null)),
    );
  }

  public getAllAvailableConcise(): Observable<AccountConciseDTO[]> {
    return this.http
      .get<AccountConciseDTO[]>(`${this.settingsState.apiPath}/accounts/available/concise`)
      .pipe(catchError(() => of(null)));
  }

  public getSummary(accountId: string): Observable<PortfolioSummaryDTO> {
    return this.http
      .get<PortfolioSummaryDTO>(
        `${this.settingsState.apiPath}/widgets/accounts/${accountId}/summary`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getSummaryPnlPeriod(accountId: string): Observable<PortfolioAumAndPnlPeriodsDTO> {
    return this.http
      .get<PortfolioAumAndPnlPeriodsDTO>(
        `${this.settingsState.apiPath}/widgets/accounts/${accountId}/pnl-period`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getOrders(accountId: string): Observable<OrderMobileDTO[]> {
    return this.http
      .get<OrderMobileDTO[]>(`${this.settingsState.apiPath}/orders-mobile/accounts/${accountId}`)
      .pipe(catchError(() => of([])));
  }

  public getAssets(accountId: string): Observable<WidgetDataDTO[]> {
    return this.http
      .get<WidgetDataDTO[]>(
        `${this.settingsState.apiPath}/widgets/accounts/${accountId}/asset-types`,
      )
      .pipe(catchError(() => of([])));
  }

  public getTop5(accountId: string): Observable<Top5PositionWidgetDTO[]> {
    return this.http
      .get<Top5PositionWidgetDTO[]>(
        `${this.settingsState.apiPath}/widgets/accounts/${accountId}/top-5-positions`,
      )
      .pipe(catchError(() => of([])));
  }

  public getRegions(accountId: string): Observable<WidgetDataDTO[]> {
    return this.http
      .get<WidgetDataDTO[]>(`${this.settingsState.apiPath}/widgets/accounts/${accountId}/regions`)
      .pipe(catchError(() => of([])));
  }

  public getCurrencies(accountId: string): Observable<WidgetDataDTO[]> {
    return this.http
      .get<WidgetDataDTO[]>(
        `${this.settingsState.apiPath}/widgets/accounts/${accountId}/currencies`,
      )
      .pipe(catchError(() => of([])));
  }

  public getTop5Portfolios(
    accountId: string,
    period: PeriodEnum = PeriodEnum.ALL_TIME,
  ): Observable<Top5PortfolioChartPerformanceWrapperDTO> {
    return this.http
      .get<Top5PortfolioChartPerformanceWrapperDTO>(
        `${this.settingsState.apiPath}/widgets/performance/top-5-portfolios/accounts/${accountId}`,
        {
          params: {
            period: period,
          },
        },
      )
      .pipe(catchError(() => of(null)));
  }

  public getAllConcise(): Observable<AccountDTO[]> {
    return this.http
      .get<AccountDTO[]>(`${this.settingsState.apiPath}/accounts/concise`)
      .pipe(catchError(() => of(null)));
  }

  public getAccountsWithPagination<T = AccountDTO>(
    request: SearchRequestDTO,
  ): Observable<LazyData<T>> {
    const url = `${this.settingsState.apiPath}/accounts/search`;

    return this.http.post<T[]>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<T>;
      }),
      catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<T>)),
    );
  }

  public getAccountContacts(accountId: string): Observable<AccountContactDTO[]> {
    return this.http
      .get<AccountContactDTO[]>(`${this.settingsState.apiPath}/accounts/${accountId}/contacts`)
      .pipe(catchError(() => of(null)));
  }

  public getAccountContactsWithPagination(
    request: SearchRequestDTO,
    accountId: string,
  ): Observable<LazyData<AccountContactDTO>> {
    const url = `${this.settingsState.apiPath}/accounts/${accountId}/contacts/search`;

    return this.http.post<AccountContactDTO[]>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<AccountContactDTO>;
      }),
      catchError(() =>
        of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<AccountContactDTO>),
      ),
    );
  }

  public getUserBankAccountsGroupsByPortfolioId(
    portfolioId: string,
  ): Observable<UserBankAccountGroupDTO[]> {
    return this.http
      .get<UserBankAccountGroupDTO[]>(
        `${this.settingsState.apiPath}/user-bank-account-group/portfolios/${portfolioId}`,
      )
      .pipe(catchError(() => of([])));
  }

  public createBankAccountsGroup(
    data: UserBankAccountGroupDTO,
  ): Observable<UserBankAccountGroupDTO> {
    return this.http
      .post<UserBankAccountGroupDTO>(`${this.settingsState.apiPath}/user-bank-account-group`, data)
      .pipe(catchError(() => of(null)));
  }

  public createAccountContactWithNewContact(
    contact: AccountContactDTO,
    accountId: string,
  ): Observable<AccountContactIdDTO> {
    return this.http
      .post<AccountContactIdDTO>(
        `${this.settingsState.apiPath}/accounts/${accountId}/contacts/new-contact`,
        contact,
      )
      .pipe(catchError(() => of(null)));
  }

  public createAccountContactWithExistsContact(
    contact: AccountContactIdDTO,
    accountId: string,
  ): Observable<AccountContactIdDTO> {
    return this.http
      .post<AccountContactIdDTO>(
        `${this.settingsState.apiPath}/accounts/${accountId}/contacts/exists-contact`,
        contact,
      )
      .pipe(catchError(() => of(null)));
  }

  public updateBankAccount(
    bankAccountId: string,
    data: { [x: string]: string } = null,
  ): Observable<BankAccountDTO> {
    return this.http
      .put<BankAccountDTO>(`${this.settingsState.apiPath}/bank-accounts/${bankAccountId}`, data)
      .pipe(catchError(() => of(null)));
  }

  public updateBankAccountsGroup(
    data: UserBankAccountGroupDTO,
  ): Observable<UserBankAccountGroupDTO> {
    return this.http
      .put<UserBankAccountGroupDTO>(`${this.settingsState.apiPath}/user-bank-account-group`, data)
      .pipe(catchError(() => of(null)));
  }

  public updateAccountContact(
    contact: AccountContactIdDTO,
    accountId: string,
  ): Observable<AccountContactIdDTO> {
    return this.http
      .put<AccountContactIdDTO>(
        `${this.settingsState.apiPath}/accounts/${accountId}/contacts`,
        contact,
      )
      .pipe(catchError(() => of(null)));
  }

  public deleteBankAccountsGroup(id: string): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.settingsState.apiPath}/user-bank-account-group/${id}`)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public loadExcel(fileName: string, searchRequest: ExcelTableRequestDTO): void {
    const url = `${this.settingsState.apiPath}/accounts/generate-excel-table`;

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName);
  }

  public isApprover(requestId: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${this.settingsState.apiPath}/request/account-company/${requestId}/is-approver`,
      )
      .pipe(catchError(() => of(null)));
  }

  public getActiveChangeRequest(
    accountId: string,
    companyId: string,
  ): Observable<AccountCompanyRequestDTO> {
    return this.http.get(
      `${this.settingsState.apiPath}/request/account-company/active/${accountId}/${companyId}`,
    );
  }

  public createChangeRequest(
    data: Partial<AccountCompanyRequestDTO>,
  ): Observable<AccountCompanyRequestDTO> {
    return this.http
      .post<AccountCompanyRequestDTO>(`${this.settingsState.apiPath}/request/account-company`, data)
      .pipe(catchError(() => of(null)));
  }

  public approveChangeRequest(requestId: string): Observable<void> {
    return this.http.put<void>(
      `${this.settingsState.apiPath}/request/account-company/${requestId}/approve/APPROVED`,
      {},
    );
  }

  public rejectChangeRequest(requestId: string, description: string): Observable<void> {
    let params: { [p: string]: string };

    if (description) {
      params = { reason: description };
    }

    return this.http.put<void>(
      `${this.settingsState.apiPath}/request/account-company/${requestId}/approve/REJECTED`,
      {},
      {
        params: { ...params },
      },
    );
  }

  public loadExcelTemplate(fileName?: string): void {
    const url = `${this.settingsState.apiPath}/accounts/import-xlsx-template`;

    this.fileLoaderService.loadAndSave(url, fileName);
  }

  public uploadCsvToServer(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${this.settingsState.apiPath}/accounts/import-xlsx`;

    return this.http.post<void>(url, formData);
  }
}
