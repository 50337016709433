<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'StatusAttentionRectangle_24'"
             [disabledHover]="true">
    </app-svg>

    <span class="heading-4-special">
      {{'dialogs.expirationDialog.title'|translate}}
    </span>

    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div class="app-dialog-notification__body">
    <span class="subtitle-2">
      {{'dialogs.expirationDialog.message'|translate}}
      {{ secondsLeft$|async }}
      {{'dialogs.expirationDialog.seconds'|translate}}
    </span>
  </div>

  <div class="app-dialog-notification__footer">
    <app-button type="secondary"
                (click)="_onCloseClick()"
                style="margin-right: 10px;">
      {{'common.close'|translate}}
    </app-button>

    <app-button (click)="_onContinueClick()">
      {{'dialogs.expirationDialog.continue'|translate}}
    </app-button>
  </div>
</div>