import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgSelectComponent } from '@ng-select/ng-select';
import get from 'lodash-es/get';
import { BehaviorSubject, Subject, fromEvent, of } from 'rxjs';
import { debounceTime, delay, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';
import * as uuid from 'uuid';
import { contentAnimation, listItemAnimation } from '@shared/animations/animations';
import { BaseControl } from '@shared/base/base-control';
import { isNotNull } from '@shared/base/core';
import { coordInsideRect, getElementRect } from '@shared/helpers/coordinate.helper';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { THOUSAND_SEPARATOR } from '@shared/types/preferences';
import { getDialogPosition } from '@ui/dialog/dialog-position';
import { MenuItemTemplateContext } from '@ui/menu/menu-panel/menu-panel.types';
import { AppScrollStrategy } from 'app/config/scroll-strategy';
import { SelectDropdownComponent } from './select-dropdown.component';
import { SelectGroupDefDirective } from './select-group-def.directive';
import { SelectHeaderDefDirective } from './select-header-def.directive';
import { SelectMultiValueDefDirective } from './select-multi-value-def.directive';
import { SelectOptionDefDirective } from './select-option-def.directive';
import { SelectRef } from './select-ref';
import { SelectValueDefDirective } from './select-value-def.directive';
import {
  SearchInputType,
  SelectGroupTemplateContext,
  SelectOption,
  SelectOptionTemplateContext,
  SelectScrollEvent,
} from './select.types';

export type SelectorMode = 'search' | 'input';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-select',
    '[class.--no-gap]': '!gap',
    '[class.--no-bg]': 'noBg',
  },
  animations: [contentAnimation, listItemAnimation],
  providers: [
    SelectRef,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<TOption = unknown, TGroup = unknown, TValue = unknown>
  extends BaseControl<UntypedFormControl>
  implements OnInit
{
  @Input() public formControl: FormControl<TValue>;
  @Input() public formControlName: string;
  @Input() public searchFieldType: SearchInputType = 'text';

  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public bindName: string;
  @Input() public bindValue: string;
  @Input() public bindGroupBy: string | ((item: TOption) => TGroup);
  @Input() public multiple: boolean = false;
  @Input() public addTag: boolean = false;
  @Input() public showSearch: boolean = true;
  @Input() public clearable: boolean = true;
  @Input() public closeOnSelect: boolean = true;
  @Input() public multipleCloseOnSelect: boolean = false;
  @Input() public multipleCloseOnSelectIfHasOneOption: boolean = true;
  @Input() public multipleValueShort: boolean = true;
  @Input() public lazySearch: boolean = false;

  @Input()
  public set loading(value: boolean) {
    this.loading$.next(value);
  }

  @Input() public optionsComparator: (optionA: TOption, optionB: TOption) => boolean;
  @Input() public searchFunction: (term: string, value: TOption) => boolean;
  @Input() public mode: SelectorMode = 'search';
  @Input() public searchFieldMaskPrefix: string = '';
  @Input() public gap = true;
  @Input() public noBg = false;
  @Input() public skeleton: string;
  @Input() public customMessage: string;
  @Input() public customTitle: string;
  @Input() public virtualScroll: boolean = true;
  @Input() public errorState: boolean = false;
  @Input() public groupHeight: string;
  @Input() public hideRequiredMarker: boolean = false;
  @Input() public isSmallSize: boolean = false;
  @Input() public dropdownWidth: string;
  @Input() public withoutMenu: boolean = false;

  @Input()
  public set options(value: TOption[]) {
    this.options$.next(value || []);
  }

  @Output() public readonly changeEvent = new EventEmitter<TValue>();
  @Output() public readonly lazyLoad = new EventEmitter<string>();
  @Output() public readonly scrollEvent = new EventEmitter<SelectScrollEvent>();
  @Output() public readonly openEvent = new EventEmitter<void>();
  @Output() public readonly closeEvent = new EventEmitter<void>();

  public options$ = new BehaviorSubject<TOption[]>([]);

  @ContentChild(SelectOptionDefDirective, { static: true })
  public selectOptionDef: SelectOptionDefDirective<TOption>;

  @ContentChild(SelectValueDefDirective, { static: true })
  public selectValueDef: SelectValueDefDirective<TOption>;

  @ContentChild(SelectMultiValueDefDirective, { static: true })
  public selectMultiValueDef: SelectMultiValueDefDirective<TOption>;

  @ContentChild(SelectHeaderDefDirective, { static: true })
  public selectHeaderDef: SelectHeaderDefDirective;

  @ContentChild(SelectGroupDefDirective, { static: true })
  public selectGroupDef: SelectGroupDefDirective<TOption, TGroup>;

  public searchValue$ = new Subject<string>();
  public isSelectOpened$ = new BehaviorSubject<boolean>(false);
  public searchTextControl = new UntypedFormControl();
  public addOptionEvent$ = new Subject<TOption>();
  public removeOptionEvent$ = new Subject<SelectOption<TOption>>();
  public clearEvent$ = new Subject<void>();
  public lazySearchEvent$ = new Subject<string>();
  public selected$ = new BehaviorSubject<TValue>(null);
  public hasSelected$ = new BehaviorSubject<boolean>(false);
  public loading$ = new BehaviorSubject<boolean>(false);

  private ngDropdown: HTMLElement;
  private selectDropdown: MatDialogRef<SelectDropdownComponent>;
  public readonly selectId: string;
  public readonly selectPanelClass: string;
  public readonly selectPanelSubClass: string;
  public readonly selectAppendTo: string;
  public readonly menuItemTemplateContext: MenuItemTemplateContext<TOption>;
  public readonly selectOptionTemplateContext: SelectOptionTemplateContext;
  public readonly selectGroupTemplateContext: SelectGroupTemplateContext<TGroup>;
  public numberInputMask: string;
  public thousandSeparator = THOUSAND_SEPARATOR;

  @ViewChild(NgSelectComponent) public ngSelect: NgSelectComponent;

  @HostListener('click')
  public _closeOrOpenSelect(): void {
    if (this.disabled) {
      return;
    }

    if (!this.ngSelect.isOpen) {
      this.selectDropdown = this.dialog.open<SelectDropdownComponent>(SelectDropdownComponent, {
        width: `${getElementRect(this.element.nativeElement).width}px`,
        position: getDialogPosition(this.element.nativeElement, 'left'),
        panelClass: [this.selectPanelClass, this.selectPanelSubClass],
        scrollStrategy: new AppScrollStrategy(),
      });
    }

    this.ngSelect.isOpen = !this.ngSelect.isOpen;
    this.ngSelect.detectChanges();

    if (this.ngSelect.isOpen) {
      this.ngDropdown = this.document.querySelector(`#${this.ngSelect.dropdownId}`);

      if (this.dropdownWidth) {
        this.ngDropdown.style.setProperty('width', this.dropdownWidth);
      }

      this.ngDropdown.style.setProperty('--group-height', this.groupHeight || '');

      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.selectDropdown.updateSize(
            `${this.ngDropdown.clientWidth + 2}px`,
            `${this.ngDropdown.clientHeight + 2}px`,
          );
        });
      });

      this._onOpenSelect();
    } else {
      this.selectDropdown.close();
      this.ngDropdown = null;
      this._onCloseSelect();
      this.cd.detectChanges();
    }
  }

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    @Inject(DOCUMENT) private document: Document,
    public selectRef: SelectRef,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    public element: ElementRef<HTMLElement>,
    private dialog: MatDialog,
    private userSettingsState: UserSettingsState,
  ) {
    super();

    const decimalsCount = this.userSettingsState.preferences.decimalsCount;

    this.numberInputMask = `separator.${decimalsCount}`;

    this.selectId = uuid.v4();
    this.selectPanelClass = 'app-select__dropdown';
    this.selectPanelSubClass = `app-select-id-${this.selectId}`;
    this.selectAppendTo = `.${this.selectPanelClass}.${this.selectPanelSubClass} .app-select-dropdown`;

    this.selectRef.component = this;

    this.listenToSearch();
    this.listenToDocumentClick();
    this.listenToDocumentKeyboard();
  }

  public ngOnInit(): void {
    if (!this.formControl && this.controlContainer) {
      this.formControl = this.controlContainer.control.get(
        this.formControlName,
      ) as UntypedFormControl;
    }

    this.formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.changeEvent.next(value));

    this.formControl.valueChanges
      .pipe(startWith(this.formControl.value), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.selected$.next(value);
        this.hasSelected$.next(this.multiple && Array.isArray(value) ? !!value?.length : !!value);
      });

    if (this.lazySearch) {
      this.lazySearchEvent$
        .pipe(
          filter((value) => isNotNull(value)),
          debounceTime(300),
          takeUntil(this.destroy$),
        )
        .subscribe((value) => {
          this.lazyLoad.next(value);
        });
    }
  }

  public validate(_: AbstractControl): ValidationErrors | null {
    return null;
  }

  public _getBindedFieldValue(option: TOption, bindName: string): TValue {
    return get(option, bindName);
  }

  private _onOpenSelect(): void {
    this.isSelectOpened$.next(true);
    this.openEvent.next();
  }

  private _onCloseSelect(): void {
    this.isSelectOpened$.next(false);
    this.closeEvent.next();
  }

  public _onChanged(option: TOption | TOption[]): void {
    if (this.multiple) {
      if (this.multipleCloseOnSelect) {
        if (!this.lazySearch) {
          this.searchTextControl.setValue(null);
        }

        this._closeOrOpenSelect();
      } else {
        // check search options and close select if has one option
        if (
          (option as TOption[]).length &&
          this.options$.value.length === 1 &&
          this.multipleCloseOnSelectIfHasOneOption
        ) {
          if (!this.lazySearch) {
            this.searchTextControl.setValue(null);
          }

          this._closeOrOpenSelect();
        }
      }
    } else {
      if (option) {
        if (!this.lazySearch) {
          this.searchTextControl.setValue(null);
        }

        if (this.closeOnSelect) {
          this._closeOrOpenSelect();
        }
      }
    }
  }

  public _onClearClick(): void {
    this.clearEvent$.next();
  }

  public _onRemoveOptionByCloseClick(
    removeOptionFunction: (item: TOption) => void,
    item: TOption,
    event: MouseEvent,
  ): void {
    removeOptionFunction(item);

    event.stopPropagation();
  }

  public _onAddOption(option: TOption): void {
    this.addOptionEvent$.next(option);
  }

  public _onRemoveOption(option: SelectOption<TOption>): void {
    this.removeOptionEvent$.next(option);
  }

  public _onScroll(event: { start: number; end: number }): void {
    this.scrollEvent.next({ at: 'middle', position: event });
  }

  public _onScrollToEnd(): void {
    this.scrollEvent.next({ at: 'end' });
  }

  public select(option: SelectOption): void {
    this.ngSelect.select(option);
  }

  public unselect(option: SelectOption): void {
    this.ngSelect.unselect(option);
  }

  public focus(): void {
    this._closeOrOpenSelect();
  }

  public get selectedItems(): SelectOption[] {
    return this.ngSelect.selectedItems;
  }

  private listenToSearch(): void {
    this.searchTextControl.valueChanges
      .pipe(debounceTime(this.lazySearch ? 300 : 0), takeUntil(this.destroy$))
      .subscribe((value: string) => {
        const searchValue = value?.toString();

        this.searchValue$.next(searchValue);
        this.ngSelect.filter(searchValue);
        this.ngSelect.detectChanges();
      });
  }

  private listenToDocumentClick(): void {
    this.isSelectOpened$
      .pipe(
        delay(100),
        switchMap((isSelectOpened) =>
          isSelectOpened
            ? fromEvent<MouseEvent>(this.document, 'click').pipe(
                takeUntil(this.isSelectOpened$.pipe(filter((isSelectOpened) => !isSelectOpened))),
              )
            : of<MouseEvent>(),
        ),
        filter((event) => !!event),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        if (
          !coordInsideRect({ x: event.clientX, y: event.clientY }, getElementRect(this.ngDropdown))
        ) {
          this._closeOrOpenSelect();
        }
      });
  }

  private listenToDocumentKeyboard(): void {
    this.isSelectOpened$
      .pipe(
        delay(100),
        switchMap((isSelectOpened) =>
          isSelectOpened
            ? fromEvent<KeyboardEvent>(this.document, 'keydown').pipe(
                takeUntil(this.isSelectOpened$.pipe(filter((isSelectOpened) => !isSelectOpened))),
              )
            : of<KeyboardEvent>(),
        ),
        filter((event) => !!event),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        if (event.code === 'Escape') {
          this._closeOrOpenSelect();
        }
      });
  }

  public clearSearch(): void {
    this.searchTextControl.setValue('');
    this._onClearClick();
  }
}
