import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { isNotNull } from '@shared/base/core';
import { WebSocketService } from '@shared/helpers/websocket.service';

type PriceChange = 'success' | 'danger';

interface RecalculatedSymbolInstrument {
  symbol: string;
  micCode: string;
  price: number;
}

interface PriceByMicCodeSocketData {
  price: number;
  percentChange: number;
  changeType: PriceChange;
  momentChangeType: PriceChange;
}
@Pipe({
  name: 'getInstrumentPriceByMicCode',
})
export class GetInstrumentPriceByMicCodePipe extends BaseObject implements PipeTransform {
  constructor(private ws: WebSocketService) {
    super();
  }

  public transform(
    price: number,
    symbol: string,
    micCode: string,
    percent: number = null,
  ): Observable<PriceByMicCodeSocketData> {
    const defaultInstrument: RecalculatedSymbolInstrument = {
      symbol: symbol,
      micCode: micCode,
      price: price || 0,
    };

    return this.getRecalculatedInstrument(symbol, micCode, [this.destroy$]).pipe(
      startWith(defaultInstrument),
      startWith(defaultInstrument),
      pairwise(),
      map(([previousData, currentData]) => {
        let changeType: PriceChange = null;
        let momentChangeType: PriceChange = null;
        let previousValue: RecalculatedSymbolInstrument;

        if (isNotNull(currentData?.price)) {
          if (isNotNull(previousData?.price)) {
            previousValue = previousData;
          } else {
            previousValue = defaultInstrument;
          }

          const lastPrice = isNotNull(percent) ? price / (percent / 100 + 1) : price;
          const percentChange = Math.abs(((currentData.price - lastPrice) / lastPrice) * 100);

          if (currentData.price > lastPrice) {
            changeType = 'success';
          }

          if (currentData.price < lastPrice) {
            changeType = 'danger';
          }

          if (currentData.price > previousValue.price) {
            momentChangeType = 'success';
          }

          if (currentData.price < previousValue.price) {
            momentChangeType = 'danger';
          }

          return {
            price: currentData.price,
            percentChange: percentChange,
            changeType: changeType,
            momentChangeType: momentChangeType,
          };
        } else if (isNotNull(previousData?.price)) {
          return {
            price: previousData.price,
            percentChange: null,
            changeType: changeType,
            momentChangeType: momentChangeType,
          };
        }

        return {
          price: price || 0,
          percentChange: 0,
          changeType: changeType,
          momentChangeType: momentChangeType,
        };
      }),
      catchError(() =>
        of({ price: price || 0, percentChange: 0, changeType: null, momentChangeType: null }),
      ),
      takeUntil(this.destroy$),
    );
  }

  private getRecalculatedInstrument(
    symbol: string,
    micCode: string,
    stopListenEvents: Observable<unknown>[],
  ): Observable<RecalculatedSymbolInstrument> {
    const headers = {
      symbol,
      micCode,
    };

    return this.ws.listenChannel<RecalculatedSymbolInstrument>(
      `/topic/instruments.symb.${symbol}.${micCode}.price`,
      stopListenEvents,
      headers,
    );
  }
}
