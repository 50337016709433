import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'formControlKeysArray',
})
export class FormControlKeysArrayPipe implements PipeTransform {
  public transform(value: { [key: string]: AbstractControl }): string[] {
    return Object.keys(value);
  }
}
