<ng-content select="app-dialog-header"></ng-content>

<ng-content select="app-dialog-sub-header"></ng-content>

<div class="app-dialog__body app-scrollbar-block">
  <div class="app-dialog__body__content">
    <ng-content></ng-content>
  </div>
</div>

<ng-content select="app-dialog-sub-footer"></ng-content>

<ng-content select="app-dialog-footer"></ng-content>

<div *ngIf="notificationOpen && notificationTemplate"
     class="app-dialog__notification-block app-dialog-backdrop"
     [@dropDownAnimation]="'enter-dropdown'">
  <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
</div>