import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { ButtonsModule } from '@ui/buttons/buttons.module';
import { ChartSkeletonModule } from '@ui/chart-skeleton/chart-skeleton.module';
import { GridModule } from '@ui/grid/grid.module';
import { InputModule } from '@ui/input/input.module';
import { NotificationModule } from '@ui/notification/notification.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { SvgModule } from '@ui/svg/svg.module';
import { ChangeApprovalComponent } from './change-approval/change-approval.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CookieAcceptComponent } from './cookie-accept/cookie-accept.component';
import { ErrorComponent } from './error/error.component';
import { LogsComponent } from './logs/logs.component';
import { MessageComponent } from './message/message.component';
import { NotificationsContainerComponent } from './push-notifications/notifications-container.component';
import { PushNotificationComponent } from './push-notifications/push-notification.component';
import { SessionExpirationComponent } from './session-expiration/session-expiration.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ToastComponent } from './toast/toast.component';
import { VersionUpdateComponent } from './version-update/version-update.component';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
    }),
    SvgModule,
    InputModule,
    ScrollingModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    PortalModule,
    TranslateModule,
    ButtonsModule,
    DirectivesModule,
    SpinnerModule,
    PipesModule,
    ChartSkeletonModule,
    GridModule,
    NotificationModule,
  ],
  declarations: [
    ToastComponent,
    PushNotificationComponent,
    NotificationsContainerComponent,
    SessionExpirationComponent,
    VersionUpdateComponent,
    CookieAcceptComponent,
    ChangeApprovalComponent,
    ConfirmationComponent,
    MessageComponent,
    ErrorComponent,
    LogsComponent,
    SubscriptionComponent,
  ],
  exports: [
    PushNotificationComponent,
    NotificationsContainerComponent,
    ChangeApprovalComponent,
    ConfirmationComponent,
    ErrorComponent,
  ],
})
export class NotificationsModule {}
