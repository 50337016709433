<div class="app-footer__link-wrapper">
  <span class="app-footer__link"
        (click)="goToTermsOfUse()"
        (mouseup)="onMouseupToTermsOfUse($event)">
    {{'pages.termsOfUse'|translate}}
  </span>

  <span class="app-footer__link"
        (click)="goToPrivacyPolicy()"
        (mouseup)="onMouseupToPrivacyPolicy($event)">
    {{'pages.privacyPolicy'|translate}}
  </span>
</div>

<div class="app-footer__disclaimer subtitle-4 app-text-color-25">
  <ng-content></ng-content>
</div>

<span class="app-footer__footer-copyright">
  <app-site-info-outlet class="subtitle-4 app-text-color-25"
                        [data]="copyrightData$|async">
  </app-site-info-outlet>
</span>