/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankAccountConciseDTO } from './bank-account-concise-dto';

export interface AccountChildAccountBankAccountsDTO {
  accountId?: string;
  accountName?: string;
  accountOwnerType?: AccountChildAccountBankAccountsDTO.AccountOwnerTypeEnum;
  accountType?: AccountChildAccountBankAccountsDTO.AccountTypeEnum;
  childAccounts?: Array<string>;
  conciseBankAccounts?: Array<BankAccountConciseDTO>;
  parent?: string;
  sharedIn?: number;
}
export namespace AccountChildAccountBankAccountsDTO {
  export type AccountOwnerTypeEnum = 'CO_OWNER' | 'OWNER';
  export const AccountOwnerTypeEnum = {
    CoOwner: 'CO_OWNER' as AccountOwnerTypeEnum,
    Owner: 'OWNER' as AccountOwnerTypeEnum,
  };
  export type AccountTypeEnum = 'ENTITY' | 'INDIVIDUAL';
  export const AccountTypeEnum = {
    Entity: 'ENTITY' as AccountTypeEnum,
    Individual: 'INDIVIDUAL' as AccountTypeEnum,
  };
}
