import { Directive, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template[ngTemplateContextType]',
})
export class TemplateContextTypeDirective<ContextType> {
  @Input('ngTemplateContextType') public type: ContextType;

  constructor() {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard<ContextType>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: TemplateContextTypeDirective<ContextType>,
    ctx: unknown,
  ): ctx is ContextType {
    return true;
  }
}
