import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  private units = ['b', 'kb', 'Mb', 'Gb', 'Tb', 'Pb'];

  public transform(bytes: number = 0, precision: number = 2): string {
    if (!isFinite(+bytes) || +bytes <= 0 || bytes === null) {
      return '0 bytes';
    }

    bytes = +bytes;
    let unit = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }

    return `${bytes.toFixed(unit !== 0 ? precision : 0)} ${this.units[unit]}`;
  }
}
