import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomDaterangePickerType } from '@ui/input/input.types';

@Pipe({
  name: 'getCustomDaterangePickerControl',
})
export class GetCustomDaterangePickerControlPipe implements PipeTransform {
  public transform<T>(formControl: FormControl<T>): FormControl<CustomDaterangePickerType> {
    return formControl as FormControl<CustomDaterangePickerType>;
  }
}
