import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  BankAccountConciseDTO,
  BankAccountDTO,
  ExcelTableRequestDTO,
  SearchRequestDTO,
} from '@shared/dto/accounts/models';
import { FileLoaderService } from '@shared/helpers/file-loader.service';
import { SettingsState } from '@shared/states/settings.state';
import { LazyData } from '@ui/table/table-types';

@Injectable({
  providedIn: 'root',
})
export class BankAccountsService {
  constructor(
    private settingsState: SettingsState,
    private fileLoaderService: FileLoaderService,
    private http: HttpClient,
  ) {}

  public getBankAccountsWithPagination<T = BankAccountDTO>(
    request: SearchRequestDTO,
  ): Observable<LazyData<T>> {
    let url = `${this.settingsState.apiPath}/bank-accounts/available`;

    url += '/search';

    return this.http.post<T[]>(url, request, { observe: 'response' }).pipe(
      map((res) => {
        return {
          rows: res.body,
          rowCount: Number.parseInt(res.headers.get('X-Total-Count')),
        } as LazyData<T>;
      }),
      catchError(() => of({ rows: [], rowCount: 0, errorResponse: true } as LazyData<T>)),
    );
  }

  public getById(id: string): Observable<BankAccountDTO> {
    return this.http
      .get<BankAccountDTO>(`${this.settingsState.apiPath}/bank-accounts/${id}`)
      .pipe(catchError(() => of(null)));
  }

  public getOmnibusBankAccounts(
    custodianId: string,
    companyId: string = null,
  ): Observable<BankAccountDTO[]> {
    return this.http
      .get<BankAccountDTO[]>(`${this.settingsState.apiPath}/bank-accounts/find-omnibuses`, {
        params: companyId
          ? {
              custodianId,
              companyId,
            }
          : {
              custodianId,
            },
      })
      .pipe(catchError(() => of([])));
  }

  public getTtcaBankAccounts(
    custodianId: string,
    companyId: string = null,
  ): Observable<BankAccountDTO[]> {
    return this.http
      .get<BankAccountDTO[]>(`${this.settingsState.apiPath}/bank-accounts/find-ttca`, {
        params: companyId
          ? {
              custodianId,
              companyId,
            }
          : {
              custodianId,
            },
      })
      .pipe(catchError(() => of([])));
  }

  public getAllConcise(): Observable<BankAccountConciseDTO[]> {
    return this.http
      .get<BankAccountConciseDTO[]>(`${this.settingsState.apiPath}/bank-accounts/concise`)
      .pipe(catchError(() => of(null)));
  }

  public getAll(): Observable<BankAccountDTO[]> {
    return this.http
      .get<BankAccountDTO[]>(`${this.settingsState.apiPath}/bank-accounts`)
      .pipe(catchError(() => of(null)));
  }

  public getByAccounts(accountIds: string[]): Observable<BankAccountDTO[]> {
    const params: Record<string, string> = {};

    if (accountIds) {
      params.accountIds = accountIds.join(',');
    }
    return this.http
      .get<BankAccountDTO[]>(`${this.settingsState.apiPath}/bank-accounts/accounts`, { params })
      .pipe(catchError(() => of(null)));
  }

  public create(
    account: Partial<BankAccountDTO>,
    portfolioId?: string,
    portfolioCcy?: string,
  ): Observable<BankAccountDTO> {
    const params: Record<string, string> = {};

    if (portfolioId) {
      params.portfolioId = portfolioId;
    }

    if (portfolioCcy) {
      params.portfolioCcy = portfolioCcy;
    }

    return this.http
      .post<BankAccountDTO>(`${this.settingsState.apiPath}/bank-accounts`, account, { params })
      .pipe(catchError(() => of(null)));
  }

  public update(account: unknown): Observable<BankAccountDTO> {
    return this.http
      .put<BankAccountDTO>(`${this.settingsState.apiPath}/bank-accounts`, account)
      .pipe(catchError(() => of(null)));
  }

  public loadExcel(fileName: string, searchRequest: ExcelTableRequestDTO): void {
    const url = `${this.settingsState.apiPath}/bank-accounts/generate-excel-table`;

    this.fileLoaderService.loadAndSaveBySearch(url, searchRequest, fileName);
  }
}
