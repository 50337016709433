export const ON_POST_PUT_NOTIFICATION_URLS_CONDITIONS: {
  [url: string]: NotificationCondition;
} = {
  ['/session-logout']: { canShow: false, canShowProgress: false },
  ['/preferences/property']: { canShow: false, canShowProgress: false },
  ['/preferences/theme']: { canShow: false, canShowProgress: false },
  ['/preferences/layouts']: { canShow: false, canShowProgress: false },
  ['/search']: { canShow: false, canShowProgress: false },
  ['/auth']: { canShow: false, canShowProgress: false },
  ['/client-valuation-reports']: { canShow: false, canShowProgress: false },
  ['/orders/check']: { canShow: false, canShowProgress: false },
  ['/push-notification-subscriptions']: { canShow: false, canShowProgress: false },
  ['/push-notification']: { canShow: false, canShowProgress: false },
  ['/user']: { canShow: false, canShowProgress: false },
  ['/pdf-repor']: { canShow: true, canShowProgress: false },
  ['/meta-info/current-view']: { canShow: false, canShowProgress: false },
  ['/meta-info/views/view-columns-by-search']: { canShow: false, canShowProgress: false },
  ['/meta-info/view-columns/view-column-by-search']: { canShow: false, canShowProgress: false },
  ['/meta-info/entities/entity-field']: { canShow: false, canShowProgress: false },
  ['/instruments-by-ids']: { canShow: false, canShowProgress: false },
  ['/risk-report']: { canShow: false, canShowProgress: false },
  ['/twelvedata']: { canShow: false, canShowProgress: false },
};

export interface NotificationCondition {
  canShow: boolean;
  canShowProgress: boolean;
}
