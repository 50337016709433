import { Pipe, PipeTransform } from '@angular/core';
import { isNotNull } from '@shared/base/core';

@Pipe({
  name: 'isNotNull',
})
export class IsNotNullPipe implements PipeTransform {
  public transform(value: unknown): boolean {
    return isNotNull(value);
  }
}
