import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BaseObject } from '@shared/base/base-object';
import { IconNames } from '@shared/constants/icons';
import { SvgComponent } from '@ui/svg/svg.component';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-icon-button',
    '[class.--disabled-hover]': 'disabledHover',
    '[class.--link]': `subType === 'link'`,
    '[class.--success]': `subType === 'success'`,
    '[class.--warning]': `subType === 'warning'`,
    '[class.--danger]': `subType === 'danger'`,
    '[class.--status]': `subType === 'status'`,
    '[attr.disabled]': '!!disabled',
  },
})
export class IconButtonComponent extends BaseObject {
  @Input() public src: string;
  @Input() public icon: IconNames;
  @Input() public disabledHover: boolean;
  @Input() public disabled: boolean = false;
  @Input() public subType: SvgComponent['subType'] = null;

  constructor(
    @Attribute('position') public position: 'prefix' | 'suffix' = null,
    @Attribute('type') public type: 'fill' | 'stroke' = null,
    public el: ElementRef<HTMLElement>,
  ) {
    super();

    this.type = this.type || 'fill';
  }
}
