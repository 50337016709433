/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ACLDTO } from './acldto';

export interface ProfileDTO {
  acls?: Array<ACLDTO>;
  id?: string;
  name?: string;
  status?: ProfileDTO.StatusEnum;
}
export namespace ProfileDTO {
  export type StatusEnum = 'Active' | 'Deactivated';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
  };
}
