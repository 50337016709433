import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { ViewDTO } from '@shared/dto/meta-info/models';
import { SettingsState } from '@shared/states/settings.state';
import { PreferencesDTO } from '@shared/types/preferences';
import { TABLE_CONFIG, TableConfigPreference, TableView } from '@ui/table/table-types';

export enum DashboardName {
  MainDashboard = 'dashboardItems1',
  PortfolioDashboard = 'portfoliosItems1',
  ModelPortfolioDashboard = 'modelPortfoliosItems1',
  StrategyProjection = 'strategyProjection',
  ConnectorsDashboard = 'connectorsDashboard',
}

export interface TablePreferenceObject {
  views: ViewDTO[];
  config: TableConfigPreference;
}

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(
    private http: HttpClient,
    private settingsState: SettingsState,
  ) {}

  public get futureObservations(): boolean {
    return localStorage.getItem(LocalStorageConstants.FutureObservations) === 'true';
  }

  public set futureObservations(value: boolean) {
    localStorage.setItem(LocalStorageConstants.FutureObservations, `${value}`);
  }

  public get(): Observable<PreferencesDTO> {
    return this.http
      .get<PreferencesDTO>(`${this.settingsState.apiPath}/preferences`)
      .pipe(catchError(() => of(null)));
  }

  public save(data: PreferencesDTO): Observable<void> {
    return this.http.put<void>(`${this.settingsState.apiPath}/preferences`, data);
  }

  public saveTheme(themeName: string): Observable<void> {
    return this.http.put<void>(`${this.settingsState.apiPath}/preferences/theme/${themeName}`, {});
  }

  public loadWidgetsList<T>(dashboardName: DashboardName): Observable<T[]> {
    return this.http
      .get<{ [key: string]: T[] }>(`${this.settingsState.apiPath}/preferences`)
      .pipe(map((data) => (data ? data[dashboardName] : [])));
  }

  public saveWidgetsList<T>(dashboardName: DashboardName, widgetAliases: T[]): Observable<boolean> {
    return this.http
      .put(
        `${this.settingsState.apiPath}/preferences/property/${dashboardName}`,
        {},
        {
          params: {
            propertyValue: JSON.stringify(widgetAliases),
          },
        },
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public saveTableConfig(
    tableId: string,
    config: TableConfigPreference,
  ): Observable<TableConfigPreference> {
    if (config.id) {
      return this.http
        .put<TableConfigPreference>(`${this.settingsState.apiPath}/preferences/layouts`, config)
        .pipe(catchError(() => of(null)));
    } else {
      config.tableName = tableId;
      config.name = TABLE_CONFIG;

      return this.http
        .post<TableConfigPreference>(`${this.settingsState.apiPath}/preferences/layouts`, config)
        .pipe(catchError(() => of(null)));
    }
  }

  public saveView(view: ViewDTO): Observable<ViewDTO> {
    if (view.id) {
      return this.http
        .put<TableView>(
          `${this.settingsState.apiPath}/preferences/layouts`,
          this.createTableView(view),
        )
        .pipe(
          map((data) => data.json),
          catchError(() => of(null)),
        );
    } else {
      return this.http
        .post<TableView>(
          `${this.settingsState.apiPath}/preferences/layouts`,
          this.createTableView(view),
        )
        .pipe(
          map((data) => data.json),
          catchError(() => of(null)),
        );
    }
  }

  public deleteView(view: ViewDTO): Observable<boolean> {
    const tableView = this.createTableView(view);

    return this.http
      .delete(`${this.settingsState.apiPath}/preferences/layouts/${tableView.name}`, {
        params: {
          table: tableView.tableName,
        },
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  public getTablePreferenceObject(tableId: string): Observable<TablePreferenceObject> {
    return this.http.get(`${this.settingsState.apiPath}/preferences/layouts/${tableId}`).pipe(
      map((preferences: TableView[] | TableConfigPreference[]) => {
        const res: TablePreferenceObject = {
          views: [],
          config: null,
        };

        for (const preference of preferences) {
          switch (true) {
            case (preference as TableView).json?.type === 'USER': {
              const view = preference as TableView;
              const viewDto = view.json;

              if (viewDto.id) {
                res.views.push(viewDto);
              } else {
                if (viewDto.columns?.length) {
                  viewDto.id = view.id;
                  res.views.push(viewDto);
                }
              }
              break;
            }

            case preference.name === TABLE_CONFIG:
              res.config = preference as TableConfigPreference;
              break;
          }
        }

        return res;
      }),
    );
  }

  private createTableView(view: ViewDTO): TableView {
    const tableView: TableView = {
      id: view.id,
      tableName: view.tableAlias,
      name: view.name,
      json: view,
    };

    return tableView;
  }
}
