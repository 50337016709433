import { Pipe, PipeTransform } from '@angular/core';
import { MediaType } from '@shared/constants/media-file.type';
import { getMediaFileType } from '@shared/helpers/media-file-type.helper';

@Pipe({
  name: 'getMediaViewSrcType',
})
export class MediaViewSrcTypePipePipe implements PipeTransform {
  public transform(src: string | Blob): MediaType {
    if (!src) {
      return null;
    }

    return getMediaFileType(
      typeof src === 'string' ? src.split('.').pop().replace('.', '') : src.type,
    );
  }
}
