import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { DateToggle } from '../datepicker/datepicker.types';

@Pipe({
  name: 'canShowDateToggle',
})
export class CanShowDateTogglePipe implements PipeTransform {
  private today = this.dateHelper.now();

  constructor(private dateHelper: DateHelper) {}

  public transform(value: DateToggle, minDate?: Date, maxDate?: Date): boolean {
    if (!minDate && !maxDate) {
      return true;
    }

    let date: Date;

    switch (value) {
      case DateToggle.Today:
        date = this.today;
        break;

      case DateToggle.Tomorrow:
        date = this.dateHelper.add(this.today, 1, 'Days');
        break;

      case DateToggle.Yesterday:
        date = this.dateHelper.sub(this.today, 1, 'Days');
        break;

      default:
        return true;
    }

    const inside = this.dateHelper.isBetween(date, minDate, maxDate);

    return inside;
  }
}
