<div class="app-no-data-notification__body">
  <app-svg type="skeleton"
           [subType]="isErrorState ? 'danger': null"
           [disabledHover]="true"
           [style.margin-bottom]="!small ? '10px' : '8px'"
           [style.opacity]="!isErrorState ? 0.65 : 1">
    <svg width="48"
         height="48"
         viewBox="0 0 48 48"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3ZM5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24ZM23.9731 10.0774C22.5515 10.0774 21.424 11.2049 21.424 12.6266V25.4213C21.424 26.843 22.5515 27.9705 23.9731 27.9705C25.3948 27.9705 26.5223 26.843 26.5223 25.4213V12.6266C26.5223 11.2049 25.3948 10.0774 23.9731 10.0774ZM20.6396 34.5884C20.6396 36.4022 22.1103 37.9219 23.9731 37.9219C25.836 37.9219 27.3066 36.4513 27.3066 34.5884C27.3066 32.7746 25.836 31.2549 23.9731 31.2549C22.1593 31.2549 20.6396 32.7256 20.6396 34.5884Z"
            fill="#E6EAF6" />
    </svg>
  </app-svg>

  <ng-container *ngIf="isErrorState">
    <span [class.heading-3-special]="!small"
          [class.heading-4m]="small"
          [style.margin-bottom]="!small ? '10px' : '2px'">
      {{ title || 'msg.info.somethingWentWrong' | translate }}
    </span>

    <span class="app-no-data-notification__text-wrapper"
          [class.subtitle-1]="!small"
          [class.subtitle-3]="small"
          [style.margin-bottom]="!small ? '20px' : '4px'">
      {{ message || 'msg.info.errorLoadingData' | translate }}
    </span>
  </ng-container>

  <ng-container *ngIf="!isErrorState && isEmptyState">
    <span [class.heading-3-special]="!small"
          [class.heading-4m]="small"
          [style.margin-bottom]="!small ? '10px' : '2px'">
      {{ title || 'msg.info.dataIsEmpty'|translate }}
    </span>

    <span class="app-no-data-notification__text-wrapper app-text-color-75"
          [class.subtitle-1]="!small"
          [class.subtitle-3]="small"
          [style.margin-bottom]="!small ? '20px' : '4px'">
      {{ message || ('msg.info.noDataForWidget'|translate) }}
    </span>
  </ng-container>

  <ng-content></ng-content>
</div>