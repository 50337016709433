/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddressDTO {
  addressType?: AddressDTO.AddressTypeEnum;
  apartment?: string;
  city?: string;
  country?: string;
  houseNumber?: string;
  id?: string;
  street?: string;
  zipCode?: string;
}
export namespace AddressDTO {
  export type AddressTypeEnum =
    | 'CORRESPONDENCE'
    | 'CURRENT'
    | 'OTHER'
    | 'REGISTRATION'
    | 'RESIDENTIAL'
    | 'SECONDARY';
  export const AddressTypeEnum = {
    Correspondence: 'CORRESPONDENCE' as AddressTypeEnum,
    Current: 'CURRENT' as AddressTypeEnum,
    Other: 'OTHER' as AddressTypeEnum,
    Registration: 'REGISTRATION' as AddressTypeEnum,
    Residential: 'RESIDENTIAL' as AddressTypeEnum,
    Secondary: 'SECONDARY' as AddressTypeEnum,
  };
}
