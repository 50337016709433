/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ControllingPersonDTO } from './controlling-person-dto';
import { CountryOfTaxationAndTinDTO } from './country-of-taxation-and-tin-dto';
import { AddressDTO } from './address-dto';
import { LeadDTO } from './lead-dto';
import { AccountCompanyDTO } from './account-company-dto';

export interface AccountDTO {
  accountCompanies?: Array<AccountCompanyDTO>;
  accountNumber?: number;
  accountOwnerType?: AccountDTO.AccountOwnerTypeEnum;
  accountStatus?: AccountDTO.AccountStatusEnum;
  accountType?: AccountDTO.AccountTypeEnum;
  addresses?: Array<AddressDTO>;
  birthCity?: string;
  birthDate?: string;
  childAccounts?: Array<string>;
  childCount?: number;
  clientNumber?: string;
  companyAccount?: boolean;
  companyStrategyType?: AccountDTO.CompanyStrategyTypeEnum;
  complexStructure?: boolean;
  concat?: string;
  controllingPersons?: Array<ControllingPersonDTO>;
  convicted?: boolean;
  country?: string;
  countryName?: string;
  countryOfTaxationAndTins?: Array<CountryOfTaxationAndTinDTO>;
  createdAt?: string;
  createdBy?: string;
  dateOfExpiry?: string;
  dateOfIssue?: string;
  description?: string;
  fatfHighRisk?: boolean;
  firstName?: string;
  highNewWorth?: boolean;
  highRiskThirdCountry?: boolean;
  id?: string;
  lastName?: string;
  lead?: LeadDTO;
  lei?: string;
  leiExpirationDate?: string;
  modifiable?: boolean;
  naceCode?: AccountDTO.NaceCodeEnum;
  name?: string;
  newCalc?: boolean;
  nextReviewDate?: string;
  nidn?: string;
  nominees?: boolean;
  notePrice100?: boolean;
  parent?: string;
  passport?: string;
  pep?: boolean;
  registrationNumber?: string;
  resRegCountry?: string;
  riskLevel?: AccountDTO.RiskLevelEnum;
  riskProfileId?: string;
  sanctionList?: boolean;
  sendReportsType?: AccountDTO.SendReportsTypeEnum;
  sharedIn?: number;
  status?: AccountDTO.StatusEnum;
  trust?: boolean;
  trustInStructure?: boolean;
  updatedAt?: string;
  updatedBy?: string;
  usPerson?: boolean;
}
export namespace AccountDTO {
  export type AccountOwnerTypeEnum = 'CO_OWNER' | 'OWNER';
  export const AccountOwnerTypeEnum = {
    CoOwner: 'CO_OWNER' as AccountOwnerTypeEnum,
    Owner: 'OWNER' as AccountOwnerTypeEnum,
  };
  export type AccountStatusEnum =
    | 'ELIGIBLE_COUNTERPARTY'
    | 'PER_REQUEST_PROFESSIONAL'
    | 'PER_SE_PROFESSIONAL'
    | 'RETAIL';
  export const AccountStatusEnum = {
    EligibleCounterparty: 'ELIGIBLE_COUNTERPARTY' as AccountStatusEnum,
    PerRequestProfessional: 'PER_REQUEST_PROFESSIONAL' as AccountStatusEnum,
    PerSeProfessional: 'PER_SE_PROFESSIONAL' as AccountStatusEnum,
    Retail: 'RETAIL' as AccountStatusEnum,
  };
  export type AccountTypeEnum = 'ENTITY' | 'INDIVIDUAL';
  export const AccountTypeEnum = {
    Entity: 'ENTITY' as AccountTypeEnum,
    Individual: 'INDIVIDUAL' as AccountTypeEnum,
  };
  export type CompanyStrategyTypeEnum = 'ACTIVE' | 'FI' | 'PASSIVE';
  export const CompanyStrategyTypeEnum = {
    Active: 'ACTIVE' as CompanyStrategyTypeEnum,
    Fi: 'FI' as CompanyStrategyTypeEnum,
    Passive: 'PASSIVE' as CompanyStrategyTypeEnum,
  };
  export type NaceCodeEnum =
    | 'CAPTIVE_FIN_INSTS_AND_MONEY_LENDERS'
    | 'CENTRAL_BANKS'
    | 'CENTRAL_GOVERNMENT'
    | 'EXCEPT_CENTRAL_BANKS'
    | 'FINANCIAL_AUXILIARIES'
    | 'FINANCIAL_VEHICLE_CORPORATIONS'
    | 'HOUSEHOLDS'
    | 'INSURANCE_CORPORATIONS'
    | 'LOCAL_GOVERNMENT'
    | 'MONEY_MARKET_FUNDS'
    | 'NON_FINANCIAL'
    | 'NON_MONEY_MARKET_FUNDS'
    | 'NON_PROFIT_INSTS_SERVING_HOUSEHOLDS'
    | 'OTHER_EXCEPT_FIN_VEHICLE_CORP'
    | 'PENSION_FUNDS'
    | 'SOCIAL_SECURITY_FUNDS'
    | 'STATE_GOVERNMENT';
  export const NaceCodeEnum = {
    CaptiveFinInstsAndMoneyLenders: 'CAPTIVE_FIN_INSTS_AND_MONEY_LENDERS' as NaceCodeEnum,
    CentralBanks: 'CENTRAL_BANKS' as NaceCodeEnum,
    CentralGovernment: 'CENTRAL_GOVERNMENT' as NaceCodeEnum,
    ExceptCentralBanks: 'EXCEPT_CENTRAL_BANKS' as NaceCodeEnum,
    FinancialAuxiliaries: 'FINANCIAL_AUXILIARIES' as NaceCodeEnum,
    FinancialVehicleCorporations: 'FINANCIAL_VEHICLE_CORPORATIONS' as NaceCodeEnum,
    Households: 'HOUSEHOLDS' as NaceCodeEnum,
    InsuranceCorporations: 'INSURANCE_CORPORATIONS' as NaceCodeEnum,
    LocalGovernment: 'LOCAL_GOVERNMENT' as NaceCodeEnum,
    MoneyMarketFunds: 'MONEY_MARKET_FUNDS' as NaceCodeEnum,
    NonFinancial: 'NON_FINANCIAL' as NaceCodeEnum,
    NonMoneyMarketFunds: 'NON_MONEY_MARKET_FUNDS' as NaceCodeEnum,
    NonProfitInstsServingHouseholds: 'NON_PROFIT_INSTS_SERVING_HOUSEHOLDS' as NaceCodeEnum,
    OtherExceptFinVehicleCorp: 'OTHER_EXCEPT_FIN_VEHICLE_CORP' as NaceCodeEnum,
    PensionFunds: 'PENSION_FUNDS' as NaceCodeEnum,
    SocialSecurityFunds: 'SOCIAL_SECURITY_FUNDS' as NaceCodeEnum,
    StateGovernment: 'STATE_GOVERNMENT' as NaceCodeEnum,
  };
  export type RiskLevelEnum = 'HIGH_RISK' | 'LOW_RISK' | 'NORMAL_RISK';
  export const RiskLevelEnum = {
    HighRisk: 'HIGH_RISK' as RiskLevelEnum,
    LowRisk: 'LOW_RISK' as RiskLevelEnum,
    NormalRisk: 'NORMAL_RISK' as RiskLevelEnum,
  };
  export type SendReportsTypeEnum = 'MONTHLY' | 'QUARTERLY';
  export const SendReportsTypeEnum = {
    Monthly: 'MONTHLY' as SendReportsTypeEnum,
    Quarterly: 'QUARTERLY' as SendReportsTypeEnum,
  };
  export type StatusEnum = 'Active' | 'Deactivated';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
  };
}
