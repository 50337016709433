/* eslint-disable @typescript-eslint/no-explicit-any */

import { BehaviorSubject } from 'rxjs';

/**
 * IndexDB store class.
 *
 * Stores data in browser as key-value storage.
 */
export class IndexDb {
  private dbName: string;

  private db: IDBDatabase;

  public ready$ = new BehaviorSubject<boolean>(false);

  /**
   * Initialize IndexDB
   * @param dbName db name string
   * @param collectionName collection name string
   */
  public init(dbName: string, dbVersion: number, collectionNames: string[]): Promise<void> {
    this.dbName = dbName;

    return new Promise<void>((resolve, reject) => {
      try {
        const openRequest = indexedDB.open(this.dbName, dbVersion);

        openRequest.onsuccess = (event) => {
          // eslint-disable-next-line no-console
          console.log('%cIndexDB open', 'color: gray');

          this.db = (event.target as any).result;

          resolve();
        };

        openRequest.onerror = (event) => reject(`IndexDB onerror: ${(event.target as any).error}`);

        openRequest.onupgradeneeded = (event) => {
          // eslint-disable-next-line no-console
          console.log('%cIndexDB onupgradeneeded', 'color: gray');

          this.db = (event.target as any).result;

          collectionNames.forEach((collectionName) => this.addCollection(collectionName));
        };
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`%cIndexDB open error: ${err}`, 'color: red');
        reject(err);
      }
    }).then(() => {
      this.ready$.next(true);
    });
  }

  private addCollection(collectionName: string): void {
    // create or update store object
    if (!this.db.objectStoreNames.contains(collectionName)) {
      this.db.createObjectStore(collectionName);
    }
  }

  /**
   * Read data from db by transaction
   * @param collectionName collection name string
   * @param key key string
   */
  public get<T>(collectionName: string, key: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const getRequest = this.db
        .transaction(collectionName, 'readonly')
        .objectStore(collectionName)
        .get(key);

      getRequest.onerror = (event) => reject(`IndexDB get error: ${(event.target as any).error}`);
      getRequest.onsuccess = () => resolve(getRequest.result as T);
    });
  }

  /**
   * Read all values from db by transaction
   * @param collectionName collection name string
   */
  public getAll<T>(collectionName: string): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      const getRequest = this.db
        .transaction(collectionName, 'readonly')
        .objectStore(collectionName)
        .getAll();

      getRequest.onerror = (event) =>
        reject(`IndexDB getAll error: ${(event.target as any).error}`);
      getRequest.onsuccess = () => resolve(getRequest.result as T[]);
    });
  }

  /**
   * Read all keys from db by transaction
   * @param collectionName collection name string
   */
  public getAllKeys(collectionName: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const getRequest = this.db
        .transaction(collectionName, 'readonly')
        .objectStore(collectionName)
        .getAllKeys();

      getRequest.onerror = (event) =>
        reject(`IndexDB getAllKeys error: ${(event.target as any).error}`);
      getRequest.onsuccess = () => resolve(getRequest.result as string[]);
    });
  }

  /**
   * Read all key value map from db by transaction
   * @param collectionName collection name string
   */
  public getAllKeyValueMap<T>(collectionName: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const getRequest = this.db
        .transaction(collectionName, 'readonly')
        .objectStore(collectionName)
        .openCursor();

      const result = {} as T;

      getRequest.onerror = (event) =>
        reject(`IndexDB getAllKeyValueMap error: ${(event.target as any).error}`);
      getRequest.onsuccess = () => {
        const cursor = getRequest.result;

        if (cursor) {
          result[cursor.key as string] = cursor.value;
          cursor.continue();
        } else {
          resolve(result);
        }
      };
    });
  }

  /**
   * Write data to db by transaction
   * @param collectionName collection name string
   * @param key key string
   * @param value any value to save to db
   */
  public set<T>(collectionName: string, key: string, value: T): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const setRequest = this.db
        .transaction(collectionName, 'readwrite')
        .objectStore(collectionName)
        .put(value, key);

      setRequest.onerror = (event) => reject(`IndexDB set error: ${(event.target as any).error}`);

      setRequest.onsuccess = () => resolve();
    });
  }

  /**
   * Remove data from db by transaction
   * @param collectionName collection name string
   * @param key key string
   */
  public delete(collectionName: string, key: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const deleteRequest = this.db
        .transaction(collectionName, 'readwrite')
        .objectStore(collectionName)
        .delete(key);

      deleteRequest.onerror = (event) =>
        reject(`IndexDB delete error: ${(event.target as any).error}`);

      deleteRequest.onsuccess = () => resolve();
    });
  }

  /**
   * Clear db store
   * @param collectionName collection name string
   */
  public clear(collectionName: string): void {
    if (this.db) {
      this.db.deleteObjectStore(collectionName);
    }
  }
}
