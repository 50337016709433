import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableControlDirective } from './disable-control.directive';
import { FocusErrorControlDirective } from './focus-error-control.directive';
import { InputAutofocusDirective } from './input-autofocus.directive';
import { MarkErrorsControlsDirective } from './mark-errors-controls.directive';
import { ResetControlDirective } from './reset-control.directive';
import { SetControlValidatorsDirective } from './set-control-validators.directive';
import { ValidateControlDirective } from './validate-control.directive';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  declarations: [
    DisableControlDirective,
    ResetControlDirective,
    InputAutofocusDirective,
    FocusErrorControlDirective,
    MarkErrorsControlsDirective,
    SetControlValidatorsDirective,
    ValidateControlDirective,
  ],
  exports: [
    DisableControlDirective,
    ResetControlDirective,
    InputAutofocusDirective,
    FocusErrorControlDirective,
    MarkErrorsControlsDirective,
    SetControlValidatorsDirective,
    ValidateControlDirective,
  ],
})
export class FormDirectivesModule {}
