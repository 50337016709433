/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GovernanceFieldsDTO {
  additionalCriterias?: Array<GovernanceFieldsDTO.AdditionalCriteriasEnum>;
  clientTypes?: Array<GovernanceFieldsDTO.ClientTypesEnum>;
  complexInstrumentIndicator?: boolean;
  distributionStrategies?: Array<GovernanceFieldsDTO.DistributionStrategiesEnum>;
  financialSituations?: Array<GovernanceFieldsDTO.FinancialSituationsEnum>;
  id?: string;
  investmentObjectives?: Array<GovernanceFieldsDTO.InvestmentObjectivesEnum>;
  investmentTenors?: Array<GovernanceFieldsDTO.InvestmentTenorsEnum>;
  knowledgeAndExperiences?: Array<GovernanceFieldsDTO.KnowledgeAndExperiencesEnum>;
  riskTolerances?: Array<GovernanceFieldsDTO.RiskTolerancesEnum>;
}
export namespace GovernanceFieldsDTO {
  export type AdditionalCriteriasEnum =
    | 'DESIGNED_FOR_RETIREMENT_PROVISION'
    | 'FINANCING_LEVERAGE_MARGIN_TRADING_REPOS'
    | 'HEDGING'
    | 'TO_ACHIEVE_TAX_EFFICIENCY_BASED_ON_COUNTRY';
  export const AdditionalCriteriasEnum = {
    DesignedForRetirementProvision: 'DESIGNED_FOR_RETIREMENT_PROVISION' as AdditionalCriteriasEnum,
    FinancingLeverageMarginTradingRepos:
      'FINANCING_LEVERAGE_MARGIN_TRADING_REPOS' as AdditionalCriteriasEnum,
    Hedging: 'HEDGING' as AdditionalCriteriasEnum,
    ToAchieveTaxEfficiencyBasedOnCountry:
      'TO_ACHIEVE_TAX_EFFICIENCY_BASED_ON_COUNTRY' as AdditionalCriteriasEnum,
  };
  export type ClientTypesEnum =
    | 'ELIGIBLE_COUNTERPARTY'
    | 'PER_REQUEST_PROFESSIONAL'
    | 'PER_SE_PROFESSIONAL'
    | 'RETAIL';
  export const ClientTypesEnum = {
    EligibleCounterparty: 'ELIGIBLE_COUNTERPARTY' as ClientTypesEnum,
    PerRequestProfessional: 'PER_REQUEST_PROFESSIONAL' as ClientTypesEnum,
    PerSeProfessional: 'PER_SE_PROFESSIONAL' as ClientTypesEnum,
    Retail: 'RETAIL' as ClientTypesEnum,
  };
  export type DistributionStrategiesEnum =
    | 'EXECUTION_ONLY'
    | 'INVESTMENT_ADVISE'
    | 'PORTFOLIO_MANAGEMENT'
    | 'SELF_DIRECTED';
  export const DistributionStrategiesEnum = {
    ExecutionOnly: 'EXECUTION_ONLY' as DistributionStrategiesEnum,
    InvestmentAdvise: 'INVESTMENT_ADVISE' as DistributionStrategiesEnum,
    PortfolioManagement: 'PORTFOLIO_MANAGEMENT' as DistributionStrategiesEnum,
    SelfDirected: 'SELF_DIRECTED' as DistributionStrategiesEnum,
  };
  export type FinancialSituationsEnum =
    | 'NO_TOLERANCE_FOR_LOSS'
    | 'TOLERATE_LIMITED_LOSS'
    | 'TOLERATE_LOSSES_EXCEEDING_INVESTMENT'
    | 'TOLERATE_LOSS_ENTIRE_INVESTMENT';
  export const FinancialSituationsEnum = {
    NoToleranceForLoss: 'NO_TOLERANCE_FOR_LOSS' as FinancialSituationsEnum,
    TolerateLimitedLoss: 'TOLERATE_LIMITED_LOSS' as FinancialSituationsEnum,
    TolerateLossesExceedingInvestment:
      'TOLERATE_LOSSES_EXCEEDING_INVESTMENT' as FinancialSituationsEnum,
    TolerateLossEntireInvestment: 'TOLERATE_LOSS_ENTIRE_INVESTMENT' as FinancialSituationsEnum,
  };
  export type InvestmentObjectivesEnum = 'CAPITAL_GROWTH' | 'CAPITAL_PRESERVATION' | 'INCOME';
  export const InvestmentObjectivesEnum = {
    CapitalGrowth: 'CAPITAL_GROWTH' as InvestmentObjectivesEnum,
    CapitalPreservation: 'CAPITAL_PRESERVATION' as InvestmentObjectivesEnum,
    Income: 'INCOME' as InvestmentObjectivesEnum,
  };
  export type InvestmentTenorsEnum = 'LONG' | 'MEDIUM' | 'SHORT';
  export const InvestmentTenorsEnum = {
    Long: 'LONG' as InvestmentTenorsEnum,
    Medium: 'MEDIUM' as InvestmentTenorsEnum,
    Short: 'SHORT' as InvestmentTenorsEnum,
  };
  export type KnowledgeAndExperiencesEnum =
    | 'ADVANCED_INVESTOR'
    | 'BASIC_INVESTOR'
    | 'INFORMED_INVESTOR';
  export const KnowledgeAndExperiencesEnum = {
    AdvancedInvestor: 'ADVANCED_INVESTOR' as KnowledgeAndExperiencesEnum,
    BasicInvestor: 'BASIC_INVESTOR' as KnowledgeAndExperiencesEnum,
    InformedInvestor: 'INFORMED_INVESTOR' as KnowledgeAndExperiencesEnum,
  };
  export type RiskTolerancesEnum = 'HIGH_RISK' | 'LOW_RISK' | 'MEDIUM_RISK';
  export const RiskTolerancesEnum = {
    HighRisk: 'HIGH_RISK' as RiskTolerancesEnum,
    LowRisk: 'LOW_RISK' as RiskTolerancesEnum,
    MediumRisk: 'MEDIUM_RISK' as RiskTolerancesEnum,
  };
}
