import { Pipe, PipeTransform } from '@angular/core';
import { isNotNull } from '@shared/base/core';

@Pipe({
  name: 'toString',
})
export class ToStringPipe implements PipeTransform {
  public transform(value: unknown): string {
    return isNotNull(value) ? `${value}` : null;
  }
}
