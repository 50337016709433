import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgModule } from '@ui/svg/svg.module';
import { FeedbackModule } from 'app/dialogs/feedback/feedback.module';
import { FixedControlsComponent } from './fixed-controls.component';

@NgModule({
  imports: [CommonModule, FeedbackModule, SvgModule],
  declarations: [FixedControlsComponent],
  exports: [FixedControlsComponent],
})
export class FixedControlsModule {}
