import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/helpers/date-helper.service';

@Pipe({
  name: 'appSetTimeToDate',
})
export class SetTimeToDatePipe implements PipeTransform {
  constructor(private dateHelper: DateHelper) {}

  public transform(targetDate: Date, dateWithTime: Date): Date {
    this.dateHelper.setTime(dateWithTime, targetDate);

    return targetDate;
  }
}
