import { Injectable, OnDestroy } from '@angular/core';
import * as objectHash from 'object-hash';
import { Observable, Subject, of, tap } from 'rxjs';

@Injectable()
export abstract class BaseObject implements OnDestroy {
  public destroy$: Subject<void> = new Subject();

  private methodsCach: Map<
    (...args: unknown[]) => Observable<unknown>,
    { paramsHash: string; result: unknown }
  >;

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public cachClassMethod<F extends (...args: unknown[]) => Observable<unknown>>(
    classMethod: F,
    ...params: Parameters<F>
  ): ReturnType<F> {
    if (!this.methodsCach) {
      this.methodsCach = new Map();
    }

    const _callAndCach = (callParamsHash: string): ReturnType<F> => {
      return (classMethod.bind(this)(...params) as ReturnType<F>).pipe(
        tap((result) => {
          this.methodsCach.set(classMethod, {
            paramsHash: callParamsHash,
            result: result,
          });
        }),
      ) as ReturnType<F>;
    };

    if (this.methodsCach.has(classMethod)) {
      const currentParamsHash = objectHash.MD5(params);

      if (this.methodsCach.get(classMethod).paramsHash !== currentParamsHash) {
        return _callAndCach(currentParamsHash);
      } else {
        return of(this.methodsCach.get(classMethod).result) as ReturnType<F>;
      }
    } else {
      return _callAndCach(objectHash.MD5(params));
    }
  }
}
