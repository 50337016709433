import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '@shared/translates/custom-missing-translation-handler';
import { CustomTranslateLoader } from './custom-translate-loader';

export function translateLoaderFactory(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader('/assets/i18n/', `.json`, http);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
})
export class TranslatesModule {}
