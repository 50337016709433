import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import * as uuid from 'uuid';
import { BaseObject } from '@shared/base/base-object';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.html',
  styleUrls: ['./spinner.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-spinner',
    '[class]': `theme ? '--' + theme : null`,
  },
})
export class SpinnerComponent extends BaseObject {
  @Input() public theme: 'dark' | 'light';

  public _id: string;

  constructor(
    /** размер в px */
    @Attribute('size') public size: string,
  ) {
    super();

    this._id = uuid.v4();

    this.size = this.size || '48px';
  }

  public get _margin(): string {
    const size = Number.parseInt(this.size, 10);

    if (size) {
      return `${size / 2}px`;
    } else {
      return null;
    }
  }

  public get _width(): string {
    const size = Number.parseInt(this.size, 10);

    if (size) {
      return `${size * 2.5}px`;
    } else {
      return null;
    }
  }
}
