import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  public transform<T>(
    array: T[],
    field: keyof T,
    condition: 'HAS_VALUE' | 'HAS_NOT_VALUE' = 'HAS_VALUE',
  ): T[] {
    return array?.filter((item) => (condition === 'HAS_VALUE' ? !!item[field] : !item[field]));
  }
}
