<div class="app-dialog-notification"
     style="height: 50vh;">
  <div class="app-dialog-notification__header">
    <app-svg [icon]="'Close_24'"
             (click)="_onCloseClick()"
             class="app-dialog-notification__close">
    </app-svg>
  </div>

  <div class="app-dialog-notification__body">
    <app-subscription-notification [title]="data?.title"></app-subscription-notification>
  </div>
</div>