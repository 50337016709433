/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionDTO } from './action-dto';
import { CriteriaDTO } from './criteria-dto';

export interface ProcessDTO {
  action?: Array<ActionDTO>;
  allTriggerFieldNamesContains?: boolean;
  createdAt?: string;
  createdBy?: string;
  criteria?: Array<CriteriaDTO>;
  criteriaPattern?: string;
  cron?: string;
  description?: string;
  entityType: ProcessDTO.EntityTypeEnum;
  id?: string;
  isActive?: boolean;
  name?: string;
  triggerFieldNames?: Array<string>;
  triggerType: ProcessDTO.TriggerTypeEnum;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace ProcessDTO {
  export type EntityTypeEnum =
    | 'ACCOUNT'
    | 'ACCOUNT_COMPANY'
    | 'ADDRESS'
    | 'BANK_ACCOUNT'
    | 'CLIENT'
    | 'CLIENT_REQUEST_OPPORTUNITY'
    | 'CUSTODIAN'
    | 'FEE'
    | 'INSTRUMENT'
    | 'LEAD_OPPORTUNITY'
    | 'MANAGER'
    | 'MANDATE'
    | 'MANDATE_RISK_PROFILE'
    | 'ORDER'
    | 'PORTFOLIO'
    | 'POSITION'
    | 'PRODUCT'
    | 'RECOMMENDATION'
    | 'REPORT'
    | 'STRATEGY'
    | 'TRANSACTION';
  export const EntityTypeEnum = {
    Account: 'ACCOUNT' as EntityTypeEnum,
    AccountCompany: 'ACCOUNT_COMPANY' as EntityTypeEnum,
    Address: 'ADDRESS' as EntityTypeEnum,
    BankAccount: 'BANK_ACCOUNT' as EntityTypeEnum,
    Client: 'CLIENT' as EntityTypeEnum,
    ClientRequestOpportunity: 'CLIENT_REQUEST_OPPORTUNITY' as EntityTypeEnum,
    Custodian: 'CUSTODIAN' as EntityTypeEnum,
    Fee: 'FEE' as EntityTypeEnum,
    Instrument: 'INSTRUMENT' as EntityTypeEnum,
    LeadOpportunity: 'LEAD_OPPORTUNITY' as EntityTypeEnum,
    Manager: 'MANAGER' as EntityTypeEnum,
    Mandate: 'MANDATE' as EntityTypeEnum,
    MandateRiskProfile: 'MANDATE_RISK_PROFILE' as EntityTypeEnum,
    Order: 'ORDER' as EntityTypeEnum,
    Portfolio: 'PORTFOLIO' as EntityTypeEnum,
    Position: 'POSITION' as EntityTypeEnum,
    Product: 'PRODUCT' as EntityTypeEnum,
    Recommendation: 'RECOMMENDATION' as EntityTypeEnum,
    Report: 'REPORT' as EntityTypeEnum,
    Strategy: 'STRATEGY' as EntityTypeEnum,
    Transaction: 'TRANSACTION' as EntityTypeEnum,
  };
  export type TriggerTypeEnum =
    | 'CREATE'
    | 'CREATE_OR_UPDATE'
    | 'PRE_CREATE'
    | 'PRE_CREATE_OR_PRE_UPDATE'
    | 'PRE_UPDATE'
    | 'SCHEDULER'
    | 'UPDATE';
  export const TriggerTypeEnum = {
    Create: 'CREATE' as TriggerTypeEnum,
    CreateOrUpdate: 'CREATE_OR_UPDATE' as TriggerTypeEnum,
    PreCreate: 'PRE_CREATE' as TriggerTypeEnum,
    PreCreateOrPreUpdate: 'PRE_CREATE_OR_PRE_UPDATE' as TriggerTypeEnum,
    PreUpdate: 'PRE_UPDATE' as TriggerTypeEnum,
    Scheduler: 'SCHEDULER' as TriggerTypeEnum,
    Update: 'UPDATE' as TriggerTypeEnum,
  };
}
