import { Pipe, PipeTransform } from '@angular/core';
import mean from 'lodash-es/mean';

@Pipe({
  name: 'avgNumber',
})
export class AvgNumberPipe implements PipeTransform {
  public transform(array: number[]): number {
    return mean(array);
  }
}
