/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SiteInfoDTO {
  formatType: SiteInfoDTO.FormatTypeEnum;
  id?: string;
  text: string;
  type: SiteInfoDTO.TypeEnum;
}
export namespace SiteInfoDTO {
  export type FormatTypeEnum = 'HTML' | 'TEXT';
  export const FormatTypeEnum = {
    Html: 'HTML' as FormatTypeEnum,
    Text: 'TEXT' as FormatTypeEnum,
  };
  export type TypeEnum =
    | 'COOKIE'
    | 'COPYRIGHT'
    | 'DISCLAIMER'
    | 'FOOTER'
    | 'MARKET_DATA_DISCLAIMER'
    | 'ORDER_DISCLAIMER'
    | 'PRIVACY'
    | 'TERMS_OF_USE';
  export const TypeEnum = {
    Cookie: 'COOKIE' as TypeEnum,
    Copyright: 'COPYRIGHT' as TypeEnum,
    Disclaimer: 'DISCLAIMER' as TypeEnum,
    Footer: 'FOOTER' as TypeEnum,
    MarketDataDisclaimer: 'MARKET_DATA_DISCLAIMER' as TypeEnum,
    OrderDisclaimer: 'ORDER_DISCLAIMER' as TypeEnum,
    Privacy: 'PRIVACY' as TypeEnum,
    TermsOfUse: 'TERMS_OF_USE' as TypeEnum,
  };
}
