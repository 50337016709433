import { InjectionToken } from '@angular/core';
import { NgOption } from '@ng-select/ng-select';

export interface SelectOption<D = unknown>
  extends Pick<NgOption, 'label' | 'value' | 'selected' | 'disabled'> {
  value?: D;
}

export interface SelectScrollEvent {
  at: 'middle' | 'end';
  position?: { start: number; end: number };
}

export type SearchInputType = 'text' | 'number';

export const SELECT_OPTION = new InjectionToken<SelectOption>('SELECT_OPTION');

export interface SelectOptionTemplateContext {
  $implicit: NgOption;
  searchTerm: string;
}

export interface SelectGroupTemplateContext<D = unknown> {
  $implicit: D;
}
