import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, timer } from 'rxjs';

@Pipe({
  name: 'appDuration',
})
export class DurationPipe implements PipeTransform {
  public transform<T>(value: T, duration: number = 0): Observable<T> {
    return timer(duration).pipe(map(() => value));
  }
}
