import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { isNotNull, isNull } from '@shared/base/core';
import { Breakpoint } from '../types/screen-breakpoints';

export interface ScreenSize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenRef {
  private resize$: BehaviorSubject<ScreenSize>;
  public visible$ = new BehaviorSubject<boolean>(false);

  private isListener = false;

  constructor(private zone: NgZone) {
    this.resize$ = new BehaviorSubject<ScreenSize>({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    this.listenVisibilityChange();
  }

  private listenVisibilityChange(): void {
    fromEvent(document, 'visibilitychange')
      .pipe(startWith(() => document.visibilityState))
      .subscribe(() => this.visible$.next(document.visibilityState === 'visible'));
  }

  private listenScreenResize(): void {
    if (!this.isListener) {
      this.zone.runOutsideAngular(() => {
        fromEvent(window, 'resize')
          .pipe(
            debounceTime(300),
            map((event) => {
              const screen = event.target as Window;

              return {
                width: screen.innerWidth,
                height: screen.innerHeight,
              } as ScreenSize;
            }),
          )
          .subscribe((size) => this.resize$.next(size));
      });
    }

    this.isListener = true;
  }

  public getSize(): Observable<ScreenSize> {
    this.listenScreenResize();

    return this.resize$.asObservable();
  }

  public listenBreakpoints(breakpoints: Breakpoint[]): Observable<Breakpoint> {
    this.listenScreenResize();

    const breakpoint$ = new BehaviorSubject<Breakpoint>(null);

    this.resize$
      .pipe(
        map((size) => {
          const breakpoint = breakpoints.find((breakpoint) => {
            switch (true) {
              case isNotNull(breakpoint.from) && isNotNull(breakpoint.to):
                return size.width <= breakpoint.from && size.width >= breakpoint.to;

              case isNotNull(breakpoint.from) && isNull(breakpoint.to):
                return size.width <= breakpoint.from;

              case isNull(breakpoint.from) && isNotNull(breakpoint.to):
                return size.width >= breakpoint.to;

              default:
                return false;
            }
          });

          return breakpoint;
        }),
        distinctUntilChanged(),
      )
      .subscribe((breakpoint) => this.zone.runTask(() => breakpoint$.next(breakpoint)));

    return breakpoint$;
  }
}
