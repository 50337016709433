/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CompanyHoldingDTO {
  code: string;
  createdAt?: string;
  createdBy?: string;
  id?: string;
  name: string;
  status?: CompanyHoldingDTO.StatusEnum;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace CompanyHoldingDTO {
  export type StatusEnum = 'Active' | 'Deactivated';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
  };
}
