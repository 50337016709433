import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/helpers/date-helper.service';

@Pipe({
  name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  constructor(private dateHelper: DateHelper) {}

  public transform(date: string | number): Date {
    switch (typeof date) {
      case 'string':
        return this.dateHelper.parse(date);

      case 'number':
        return this.dateHelper.getDateFromTimestamp(date);

      default:
        return null;
    }
  }
}
