/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyDTO } from './company-dto';
import { OpportunityManagerDTO } from './opportunity-manager-dto';
import { CustodianDTO } from './custodian-dto';

export interface LeadOpportunityDTO {
  accountId: string;
  availableTransitions?: Array<LeadOpportunityDTO.AvailableTransitionsEnum>;
  bankAccountExName?: string;
  cashOrPortfolio?: string;
  company: CompanyDTO;
  createdAt?: string;
  createdBy?: string;
  createdByName?: string;
  custodian?: CustodianDTO;
  hurdleRate?: number;
  id?: string;
  imaBankAccountType?: LeadOpportunityDTO.ImaBankAccountTypeEnum;
  initialInvestments?: number;
  initialInvestmentsCcy?: string;
  investment?: number;
  investmentCcy?: string;
  investmentObjective?: LeadOpportunityDTO.InvestmentObjectiveEnum;
  mfee?: number;
  modifiable?: boolean;
  needCorrection?: boolean;
  omnibusBankAccountId?: string;
  opportunityManagers?: Array<OpportunityManagerDTO>;
  opportunityNumber?: string;
  portfolioCcy?: string;
  sfee?: number;
  status: string;
  type: LeadOpportunityDTO.TypeEnum;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace LeadOpportunityDTO {
  export type AvailableTransitionsEnum =
    | 'TO_APPROVED'
    | 'TO_DOCUMENT_SIGNATURE'
    | 'TO_INVESTMENTS_PARTIALLY_RECEIVED'
    | 'TO_KYC_BANK_ACCEPTANCE'
    | 'TO_KYC_CA'
    | 'TO_KYC_CO'
    | 'TO_LOST'
    | 'TO_LOST_WITH_NOTIFICATION'
    | 'TO_NEED_CORRECTIONS'
    | 'TO_READY_TO_BE_WON'
    | 'TO_REJECT_BY_BANK'
    | 'TO_REOPEN'
    | 'TO_SUSPENDED'
    | 'TO_SUSPENDED_WITH_NOTIFICATION'
    | 'TO_WAITING_FOR_INVESTMENTS'
    | 'TO_WON';
  export const AvailableTransitionsEnum = {
    Approved: 'TO_APPROVED' as AvailableTransitionsEnum,
    DocumentSignature: 'TO_DOCUMENT_SIGNATURE' as AvailableTransitionsEnum,
    InvestmentsPartiallyReceived: 'TO_INVESTMENTS_PARTIALLY_RECEIVED' as AvailableTransitionsEnum,
    KycBankAcceptance: 'TO_KYC_BANK_ACCEPTANCE' as AvailableTransitionsEnum,
    KycCa: 'TO_KYC_CA' as AvailableTransitionsEnum,
    KycCo: 'TO_KYC_CO' as AvailableTransitionsEnum,
    Lost: 'TO_LOST' as AvailableTransitionsEnum,
    LostWithNotification: 'TO_LOST_WITH_NOTIFICATION' as AvailableTransitionsEnum,
    NeedCorrections: 'TO_NEED_CORRECTIONS' as AvailableTransitionsEnum,
    ReadyToBeWon: 'TO_READY_TO_BE_WON' as AvailableTransitionsEnum,
    RejectByBank: 'TO_REJECT_BY_BANK' as AvailableTransitionsEnum,
    Reopen: 'TO_REOPEN' as AvailableTransitionsEnum,
    Suspended: 'TO_SUSPENDED' as AvailableTransitionsEnum,
    SuspendedWithNotification: 'TO_SUSPENDED_WITH_NOTIFICATION' as AvailableTransitionsEnum,
    WaitingForInvestments: 'TO_WAITING_FOR_INVESTMENTS' as AvailableTransitionsEnum,
    Won: 'TO_WON' as AvailableTransitionsEnum,
  };
  export type ImaBankAccountTypeEnum = 'OMNIBUS' | 'SIMPLE';
  export const ImaBankAccountTypeEnum = {
    Omnibus: 'OMNIBUS' as ImaBankAccountTypeEnum,
    Simple: 'SIMPLE' as ImaBankAccountTypeEnum,
  };
  export type InvestmentObjectiveEnum =
    | 'CAPITAL_GROWTH'
    | 'CAPITAL_PRESERVATION'
    | 'DIVERSIFICATION_OF_INVESTMENT'
    | 'INCOME_GENERATION'
    | 'LONG_TERM_CAPITAL_GROWTH'
    | 'LOW_RISK_INCOME_GENERATION';
  export const InvestmentObjectiveEnum = {
    CapitalGrowth: 'CAPITAL_GROWTH' as InvestmentObjectiveEnum,
    CapitalPreservation: 'CAPITAL_PRESERVATION' as InvestmentObjectiveEnum,
    DiversificationOfInvestment: 'DIVERSIFICATION_OF_INVESTMENT' as InvestmentObjectiveEnum,
    IncomeGeneration: 'INCOME_GENERATION' as InvestmentObjectiveEnum,
    LongTermCapitalGrowth: 'LONG_TERM_CAPITAL_GROWTH' as InvestmentObjectiveEnum,
    LowRiskIncomeGeneration: 'LOW_RISK_INCOME_GENERATION' as InvestmentObjectiveEnum,
  };
  export type TypeEnum = 'BROKERAGE' | 'CLIENT_REQUEST' | 'EMA' | 'IMA' | 'RTL';
  export const TypeEnum = {
    Brokerage: 'BROKERAGE' as TypeEnum,
    ClientRequest: 'CLIENT_REQUEST' as TypeEnum,
    Ema: 'EMA' as TypeEnum,
    Ima: 'IMA' as TypeEnum,
    Rtl: 'RTL' as TypeEnum,
  };
}
