import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, interval } from 'rxjs';
import { finalize, map, startWith, takeWhile } from 'rxjs/operators';
import { VersionUpdateResult } from './version-update.types';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionUpdateComponent {
  public countdown: number = 30;
  public countdown$: Observable<number>;

  constructor(private dialogRef: MatDialogRef<VersionUpdateComponent, VersionUpdateResult>) {
    this.countdown$ = interval(1000).pipe(
      startWith(() => this.countdown),
      takeWhile(() => this.countdown > 0),
      map(() => --this.countdown),
      finalize(() => {
        if (this.countdown === 0) {
          this.dialogRef.close({ closeType: 'CLOSE' });
        }
      }),
    );
  }

  public _onCloseClick(): void {
    this.dialogRef.close({ closeType: 'CLOSE', countdownLeftSec: this.countdown });
  }

  public _onYesClick(): void {
    this.dialogRef.close({ closeType: 'YES' });
  }
}
