export enum DatepickerPage {
  Calendar = 1,
  TimeEditor,
}

export enum DateToggle {
  Today = 1,
  Tomorrow,
  Yesterday,
}
