import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, from } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';
import { isNotNull } from '@shared/base/core';
import { getDetailPageLink } from '@shared/helpers/detail-page.helper';
import { LayoutState } from 'app/layout/layout.state';
import { NotificationsService } from 'app/notifications/notifications.service';
import { NotificationPosition, NotificationType } from 'app/notifications/notifications.types';
import { DialogComponent } from '../dialog/dialog.component';
import { DialogOutletComponent } from '../dialog-outlet/dialog-outlet.component';
import {
  DIALOG_CONFIG,
  DIALOG_OPEN_IN_PAGE,
  DialogConfig,
  DialogHeaderMenuItem,
  DialogViewType,
} from '../dialog.types';
import { LazyDialogOutletComponent } from '../lazy-dialog-outlet/lazy-dialog-outlet.component';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-dialog-header app-bg-block-main',
  },
})
export class DialogHeaderComponent extends BaseObject {
  @HostBinding('class.app-dialog-header--opened-in-page') public get openedInPage(): boolean {
    return !!this.dialogOpenInPage;
  }

  @Input()
  @HostBinding('style.height')
  @HostBinding('style.min-height')
  public height: string;

  @Input() public additionalMenuItems: DialogHeaderMenuItem[];

  public currentViewType$ = new BehaviorSubject<DialogViewType>(DialogViewType.side);

  public readonly DialogViewType = DialogViewType;

  constructor(
    @Optional()
    private dialogRef: MatDialogRef<DialogOutletComponent | LazyDialogOutletComponent>,

    @Optional()
    @Inject(DIALOG_OPEN_IN_PAGE)
    public dialogOpenInPage: boolean,

    @Optional()
    @Inject(DIALOG_CONFIG)
    public dialogConfig: DialogConfig,

    public dialog: DialogComponent,
    private notificationsService: NotificationsService,
    private layoutState: LayoutState,
  ) {
    super();
  }

  public _onCloseClick(): void {
    this.dialog.closeClick.next();

    if (!this.dialog.disableCloseByBackButton) {
      this.dialog.dialogData.close();
    }
  }

  public _onOpenInPageClick(e: MouseEvent): void {
    if (e.button === 0 || e.button === 1) {
      this.dialog.openInPage();
    }
  }

  public _onShareClick(e: MouseEvent): void {
    if (e.button === 0) {
      const params: Record<string, string> = this.dialog.openInPageParams || {
        id: this.dialog.dialogData.data.id,
      };
      const fragment = isNotNull(this.dialog.currentTab) ? `tab=${this.dialog.currentTab}` : null;

      from(
        navigator.clipboard.writeText(
          getDetailPageLink(this.dialog.openInPageEntityAliases, params, fragment),
        ),
      ).subscribe(() => {
        this.notificationsService.addNotification(
          NotificationType.Success,
          'Link Copy Success',
          null,
          { timeOut: 1000, position: NotificationPosition.TopRight },
        );
      });
    }
  }

  public _onViewSwitchClick(viewType: DialogViewType): void {
    this.currentViewType$.next(viewType);

    if (this.dialogOpenInPage) {
      this.layoutState.fullScreen = this.currentViewType$.value === DialogViewType.full;
    } else {
      this.dialogRef.updateSize(
        this.currentViewType$.value === DialogViewType.full ? '100%' : this.dialogConfig.width,
      );
    }
  }
}
