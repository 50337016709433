import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ManagersService } from '@api/managers.service';
import { ManagerDTO } from '@shared/dto/accounts/models';

export interface StateOfManagers {
  managers$: BehaviorSubject<ManagerDTO[]>;
}

@Pipe({
  name: 'getManagersByCompanyId',
})
export class GetManagersByCompanyPipe implements PipeTransform {
  constructor(private managersService: ManagersService) {}

  public transform<T extends StateOfManagers>(
    companyId: string,
    state: T = null,
  ): Observable<ManagerDTO[]> {
    if (companyId) {
      if (state?.managers$?.value) {
        state.managers$.next(null);
      }

      return this.managersService.getAll(companyId).pipe(
        tap((managers) => {
          if (state?.managers$) {
            state.managers$.next(managers || []);
          }
        }),
      );
    }

    return of(null);
  }
}
