import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface GetPictureDimensionsResult {
  width: number;
  height: number;
}

@Pipe({
  name: 'getPictureDimensions',
})
export class GetPictureDimensionsPipe implements PipeTransform {
  constructor() {}

  public transform(pictureBase64: string): Observable<GetPictureDimensionsResult> {
    if (!pictureBase64) {
      return of(null);
    }

    const result$ = new BehaviorSubject<GetPictureDimensionsResult>(null);

    const img = new Image();

    img.onload = () => {
      result$.next({
        width: img.width,
        height: img.height,
      });
    };

    img.src = pictureBase64;

    return result$;
  }
}
