/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileDTO } from './profile-dto';

export interface RoleDTO {
  allowDecrypt?: boolean;
  childRoles?: Array<string>;
  code: string;
  defaultProfile?: ProfileDTO;
  externalAccess?: boolean;
  id?: string;
  ipAddresses?: Array<string>;
  name: string;
  orderTransactionTypes?: Array<RoleDTO.OrderTransactionTypesEnum>;
  parent?: string;
  status?: RoleDTO.StatusEnum;
}
export namespace RoleDTO {
  export type OrderTransactionTypesEnum =
    | 'ADJUSTMENT'
    | 'BLOCK'
    | 'BOND_REDEMPTION'
    | 'BUY'
    | 'CASH_TRANSFER'
    | 'CROSS_TRADE'
    | 'CUSTOM'
    | 'DECREASE_CLOSE_DEPOSIT'
    | 'DECREASE_CLOSE_LOAN'
    | 'EXERCISE_OPTION'
    | 'EXPIRE_OPTION'
    | 'FEE'
    | 'FX'
    | 'FX_PRODUCT'
    | 'INCOME'
    | 'INCREASE_DEPOSIT'
    | 'INCREASE_LOAN'
    | 'INTEREST'
    | 'INTERNAL_PAYMENT'
    | 'INTERNAL_SWITCH'
    | 'INTERNAL_TRANSFER'
    | 'INVESTMENT'
    | 'ISIN_CHANGE'
    | 'MARGIN'
    | 'OPEN_DEPOSIT'
    | 'OPEN_LOAN'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIPTION'
    | 'REPO'
    | 'REVERSAL'
    | 'SECURITY_TRANSFER'
    | 'SELL'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'TAX'
    | 'TRANSFER'
    | 'VIRTUAL_SECURITY_TRANSFER'
    | 'WITHDRAWAL';
  export const OrderTransactionTypesEnum = {
    Adjustment: 'ADJUSTMENT' as OrderTransactionTypesEnum,
    Block: 'BLOCK' as OrderTransactionTypesEnum,
    BondRedemption: 'BOND_REDEMPTION' as OrderTransactionTypesEnum,
    Buy: 'BUY' as OrderTransactionTypesEnum,
    CashTransfer: 'CASH_TRANSFER' as OrderTransactionTypesEnum,
    CrossTrade: 'CROSS_TRADE' as OrderTransactionTypesEnum,
    Custom: 'CUSTOM' as OrderTransactionTypesEnum,
    DecreaseCloseDeposit: 'DECREASE_CLOSE_DEPOSIT' as OrderTransactionTypesEnum,
    DecreaseCloseLoan: 'DECREASE_CLOSE_LOAN' as OrderTransactionTypesEnum,
    ExerciseOption: 'EXERCISE_OPTION' as OrderTransactionTypesEnum,
    ExpireOption: 'EXPIRE_OPTION' as OrderTransactionTypesEnum,
    Fee: 'FEE' as OrderTransactionTypesEnum,
    Fx: 'FX' as OrderTransactionTypesEnum,
    FxProduct: 'FX_PRODUCT' as OrderTransactionTypesEnum,
    Income: 'INCOME' as OrderTransactionTypesEnum,
    IncreaseDeposit: 'INCREASE_DEPOSIT' as OrderTransactionTypesEnum,
    IncreaseLoan: 'INCREASE_LOAN' as OrderTransactionTypesEnum,
    Interest: 'INTEREST' as OrderTransactionTypesEnum,
    InternalPayment: 'INTERNAL_PAYMENT' as OrderTransactionTypesEnum,
    InternalSwitch: 'INTERNAL_SWITCH' as OrderTransactionTypesEnum,
    InternalTransfer: 'INTERNAL_TRANSFER' as OrderTransactionTypesEnum,
    Investment: 'INVESTMENT' as OrderTransactionTypesEnum,
    IsinChange: 'ISIN_CHANGE' as OrderTransactionTypesEnum,
    Margin: 'MARGIN' as OrderTransactionTypesEnum,
    OpenDeposit: 'OPEN_DEPOSIT' as OrderTransactionTypesEnum,
    OpenLoan: 'OPEN_LOAN' as OrderTransactionTypesEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as OrderTransactionTypesEnum,
    ProductUnsubscription: 'PRODUCT_UNSUBSCRIPTION' as OrderTransactionTypesEnum,
    Repo: 'REPO' as OrderTransactionTypesEnum,
    Reversal: 'REVERSAL' as OrderTransactionTypesEnum,
    SecurityTransfer: 'SECURITY_TRANSFER' as OrderTransactionTypesEnum,
    Sell: 'SELL' as OrderTransactionTypesEnum,
    SpinOff: 'SPIN_OFF' as OrderTransactionTypesEnum,
    Split: 'SPLIT' as OrderTransactionTypesEnum,
    Tax: 'TAX' as OrderTransactionTypesEnum,
    Transfer: 'TRANSFER' as OrderTransactionTypesEnum,
    VirtualSecurityTransfer: 'VIRTUAL_SECURITY_TRANSFER' as OrderTransactionTypesEnum,
    Withdrawal: 'WITHDRAWAL' as OrderTransactionTypesEnum,
  };
  export type StatusEnum = 'Active' | 'Deactivated';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
  };
}
