/**
 *  This file was auto generated by angular schematics.
 *  For update use script from package.json
 */

import { ColorsMap } from './generated-types';

export const $darkPalette: ColorsMap = {
  /** as theme map variable danger-color */
  dangerColor: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable success-color */
  successColor: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable warning-color */
  warningColor: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable status-color */
  statusColor: {
    type: 'color',

    rgba: 'rgba(67, 144, 221, 1)',
    hex: '#4390DD',
    r: 67,
    g: 144,
    b: 221,
    a: 1,
  },

  /** as theme map variable bg-color */
  bgColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable bg-main-color */
  bgMainColor: {
    type: 'color',

    rgba: 'rgba(18, 18, 18, 1)',
    hex: '#121212',
    r: 18,
    g: 18,
    b: 18,
    a: 1,
  },

  /** as theme map variable bg-block-main-color */
  bgBlockMainColor: {
    type: 'color',

    rgba: 'rgba(28, 28, 30, 1)',
    hex: '#1C1C1E',
    r: 28,
    g: 28,
    b: 30,
    a: 1,
  },

  /** as theme map variable bg-block-0-color */
  bgBlock0Color: {
    type: 'color',

    rgba: 'rgba(82, 82, 82, 0.25)',
    hex: '#525252',
    r: 82,
    g: 82,
    b: 82,
    a: 0.25,
  },

  /** as theme map variable bg-block-1-color */
  bgBlock1Color: {
    type: 'color',

    rgba: 'rgba(82, 82, 82, 0.25)',
    hex: '#525252',
    r: 82,
    g: 82,
    b: 82,
    a: 0.25,
  },

  /** as theme map variable bg-block-empty-color */
  bgBlockEmptyColor: {
    type: 'color',

    rgba: 'rgba(18, 18, 18, 0.65)',
    hex: '#121212',
    r: 18,
    g: 18,
    b: 18,
    a: 0.65,
  },

  /** as theme map variable bg-toggle-color */
  bgToggleColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.05)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.05,
  },

  /** as theme map variable bg-toggle-checked-color */
  bgToggleCheckedColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.1,
  },

  /** as theme map variable bg-group-color-level-0 */
  bgGroupColorLevel0: {
    type: 'color',

    rgba: 'rgba(40, 40, 41, 1)',
    hex: '#282829',
    r: 40,
    g: 40,
    b: 41,
    a: 1,
  },

  /** as theme map variable bg-group-color-level-1 */
  bgGroupColorLevel1: {
    type: 'color',

    rgba: 'rgba(49, 49, 52, 1)',
    hex: '#313134',
    r: 49,
    g: 49,
    b: 52,
    a: 1,
  },

  /** as theme map variable bg-additional-btn-color */
  bgAdditionalBtnColor: {
    type: 'color',

    rgba: 'rgba(36, 36, 36, 1)',
    hex: '#242424',
    r: 36,
    g: 36,
    b: 36,
    a: 1,
  },

  /** as theme map variable text-color */
  textColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable text-main-color */
  textMainColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable text-inverse-color */
  textInverseColor: {
    type: 'color',

    rgba: 'rgba(18, 18, 18, 1)',
    hex: '#121212',
    r: 18,
    g: 18,
    b: 18,
    a: 1,
  },

  /** as theme map variable text-static-color */
  textStaticColor: {
    type: 'color',

    rgba: 'rgba(18, 18, 18, 1)',
    hex: '#121212',
    r: 18,
    g: 18,
    b: 18,
    a: 1,
  },

  /** as theme map variable text-static-light-color */
  textStaticLightColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable text-link-color */
  textLinkColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable field-focused-color */
  fieldFocusedColor: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 0.75)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 0.75,
  },

  /** as theme map variable border-color */
  borderColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable border-accent-color */
  borderAccentColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.25)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.25,
  },

  /** as theme map variable shadow-color */
  shadowColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,
  },

  /** as theme map variable box-shadow */
  boxShadow: {
    type: 'box-shadow',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,

    boxShadowParams: ['0px', '4px', '8px'],
  },

  /** as theme map variable early-redemption-color */
  earlyRedemptionColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable dot-color-0 */
  dotColor0: {
    type: 'color',

    rgba: 'rgba(190, 246, 234, 1)',
    hex: '#BEF6EA',
    r: 190,
    g: 246,
    b: 234,
    a: 1,
  },

  /** as theme map variable dot-color-1 */
  dotColor1: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable positive-color-1 */
  positiveColor1: {
    type: 'color',

    rgba: 'rgba(7, 61, 47, 1)',
    hex: '#073D2F',
    r: 7,
    g: 61,
    b: 47,
    a: 1,
  },

  /** as theme map variable positive-color-2 */
  positiveColor2: {
    type: 'color',

    rgba: 'rgba(10, 90, 69, 1)',
    hex: '#0A5A45',
    r: 10,
    g: 90,
    b: 69,
    a: 1,
  },

  /** as theme map variable positive-color-3 */
  positiveColor3: {
    type: 'color',

    rgba: 'rgba(12, 137, 104, 1)',
    hex: '#0C8968',
    r: 12,
    g: 137,
    b: 104,
    a: 1,
  },

  /** as theme map variable negative-color-1 */
  negativeColor1: {
    type: 'color',

    rgba: 'rgba(130, 14, 14, 1)',
    hex: '#820E0E',
    r: 130,
    g: 14,
    b: 14,
    a: 1,
  },

  /** as theme map variable negative-color-2 */
  negativeColor2: {
    type: 'color',

    rgba: 'rgba(160, 15, 15, 1)',
    hex: '#A00F0F',
    r: 160,
    g: 15,
    b: 15,
    a: 1,
  },

  /** as theme map variable negative-color-3 */
  negativeColor3: {
    type: 'color',

    rgba: 'rgba(211, 26, 26, 1)',
    hex: '#D31A1A',
    r: 211,
    g: 26,
    b: 26,
    a: 1,
  },

  /** as theme map variable line-color-0 */
  lineColor0: {
    type: 'color',

    rgba: 'rgba(228, 61, 102, 1)',
    hex: '#E43D66',
    r: 228,
    g: 61,
    b: 102,
    a: 1,
  },

  /** as theme map variable line-color-1 */
  lineColor1: {
    type: 'color',

    rgba: 'rgba(176, 161, 31, 1)',
    hex: '#B0A11F',
    r: 176,
    g: 161,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-2 */
  lineColor2: {
    type: 'color',

    rgba: 'rgba(166, 10, 48, 1)',
    hex: '#A60A30',
    r: 166,
    g: 10,
    b: 48,
    a: 1,
  },

  /** as theme map variable line-color-3 */
  lineColor3: {
    type: 'color',

    rgba: 'rgba(108, 132, 23, 1)',
    hex: '#6C8417',
    r: 108,
    g: 132,
    b: 23,
    a: 1,
  },

  /** as theme map variable line-color-4 */
  lineColor4: {
    type: 'color',

    rgba: 'rgba(221, 13, 126, 1)',
    hex: '#DD0D7E',
    r: 221,
    g: 13,
    b: 126,
    a: 1,
  },

  /** as theme map variable line-color-5 */
  lineColor5: {
    type: 'color',

    rgba: 'rgba(166, 192, 76, 1)',
    hex: '#A6C04C',
    r: 166,
    g: 192,
    b: 76,
    a: 1,
  },

  /** as theme map variable line-color-6 */
  lineColor6: {
    type: 'color',

    rgba: 'rgba(192, 76, 173, 1)',
    hex: '#C04CAD',
    r: 192,
    g: 76,
    b: 173,
    a: 1,
  },

  /** as theme map variable line-color-7 */
  lineColor7: {
    type: 'color',

    rgba: 'rgba(93, 176, 31, 1)',
    hex: '#5DB01F',
    r: 93,
    g: 176,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-8 */
  lineColor8: {
    type: 'color',

    rgba: 'rgba(132, 23, 115, 1)',
    hex: '#841773',
    r: 132,
    g: 23,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-9 */
  lineColor9: {
    type: 'color',

    rgba: 'rgba(23, 132, 51, 1)',
    hex: '#178433',
    r: 23,
    g: 132,
    b: 51,
    a: 1,
  },

  /** as theme map variable line-color-10 */
  lineColor10: {
    type: 'color',

    rgba: 'rgba(221, 13, 199, 1)',
    hex: '#DD0DC7',
    r: 221,
    g: 13,
    b: 199,
    a: 1,
  },

  /** as theme map variable line-color-11 */
  lineColor11: {
    type: 'color',

    rgba: 'rgba(76, 192, 105, 1)',
    hex: '#4CC069',
    r: 76,
    g: 192,
    b: 105,
    a: 1,
  },

  /** as theme map variable line-color-12 */
  lineColor12: {
    type: 'color',

    rgba: 'rgba(153, 76, 192, 1)',
    hex: '#994CC0',
    r: 153,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-13 */
  lineColor13: {
    type: 'color',

    rgba: 'rgba(31, 176, 115, 1)',
    hex: '#1FB073',
    r: 31,
    g: 176,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-14 */
  lineColor14: {
    type: 'color',

    rgba: 'rgba(96, 23, 132, 1)',
    hex: '#601784',
    r: 96,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-15 */
  lineColor15: {
    type: 'color',

    rgba: 'rgba(23, 98, 132, 1)',
    hex: '#176284',
    r: 23,
    g: 98,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-16 */
  lineColor16: {
    type: 'color',

    rgba: 'rgba(89, 31, 176, 1)',
    hex: '#591FB0',
    r: 89,
    g: 31,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-17 */
  lineColor17: {
    type: 'color',

    rgba: 'rgba(23, 77, 132, 1)',
    hex: '#174D84',
    r: 23,
    g: 77,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-18 */
  lineColor18: {
    type: 'color',

    rgba: 'rgba(67, 23, 132, 1)',
    hex: '#431784',
    r: 67,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-19 */
  lineColor19: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-20 */
  lineColor20: {
    type: 'color',

    rgba: 'rgba(90, 76, 192, 1)',
    hex: '#5A4CC0',
    r: 90,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-21 */
  lineColor21: {
    type: 'color',

    rgba: 'rgba(31, 80, 176, 1)',
    hex: '#1F50B0',
    r: 31,
    g: 80,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-22 */
  lineColor22: {
    type: 'color',

    rgba: 'rgba(37, 23, 132, 1)',
    hex: '#251784',
    r: 37,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable pie-color-0 */
  pieColor0: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable pie-color-1 */
  pieColor1: {
    type: 'color',

    rgba: 'rgba(200, 223, 247, 1)',
    hex: '#C8DFF7',
    r: 200,
    g: 223,
    b: 247,
    a: 1,
  },

  /** as theme map variable pie-color-2 */
  pieColor2: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable pie-color-3 */
  pieColor3: {
    type: 'color',

    rgba: 'rgba(138, 200, 187, 1)',
    hex: '#8AC8BB',
    r: 138,
    g: 200,
    b: 187,
    a: 1,
  },

  /** as theme map variable pie-color-4 */
  pieColor4: {
    type: 'color',

    rgba: 'rgba(202, 189, 246, 1)',
    hex: '#CABDF6',
    r: 202,
    g: 189,
    b: 246,
    a: 1,
  },

  /** as theme map variable pie-color-5 */
  pieColor5: {
    type: 'color',

    rgba: 'rgba(244, 202, 202, 1)',
    hex: '#F4CACA',
    r: 244,
    g: 202,
    b: 202,
    a: 1,
  },

  /** as theme map variable pie-color-6 */
  pieColor6: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 1)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 1,
  },

  /** as theme map variable pie-color-7 */
  pieColor7: {
    type: 'color',

    rgba: 'rgba(242, 218, 188, 1)',
    hex: '#F2DABC',
    r: 242,
    g: 218,
    b: 188,
    a: 1,
  },

  /** as theme map variable pie-color-8 */
  pieColor8: {
    type: 'color',

    rgba: 'rgba(212, 178, 221, 1)',
    hex: '#D4B2DD',
    r: 212,
    g: 178,
    b: 221,
    a: 1,
  },

  /** as theme map variable pie-color-9 */
  pieColor9: {
    type: 'color',

    rgba: 'rgba(212, 253, 198, 1)',
    hex: '#D4FDC6',
    r: 212,
    g: 253,
    b: 198,
    a: 1,
  },

  /** as theme map variable pie-border-color */
  pieBorderColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.5)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.5,
  },

  /** as theme map variable column-color-0 */
  columnColor0: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable column-color-1 */
  columnColor1: {
    type: 'color',

    rgba: 'rgba(110, 190, 201, 1)',
    hex: '#6EBEC9',
    r: 110,
    g: 190,
    b: 201,
    a: 1,
  },

  /** as theme map variable column-color-2 */
  columnColor2: {
    type: 'color',

    rgba: 'rgba(228, 201, 106, 1)',
    hex: '#E4C96A',
    r: 228,
    g: 201,
    b: 106,
    a: 1,
  },

  /** as theme map variable column-color-3 */
  columnColor3: {
    type: 'color',

    rgba: 'rgba(224, 170, 103, 1)',
    hex: '#E0AA67',
    r: 224,
    g: 170,
    b: 103,
    a: 1,
  },

  /** as theme map variable column-color-4 */
  columnColor4: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable default-region-color */
  defaultRegionColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable australia-color */
  australiaColor: {
    type: 'color',

    rgba: 'rgba(250, 244, 194, 1)',
    hex: '#FAF4C2',
    r: 250,
    g: 244,
    b: 194,
    a: 1,
  },

  /** as theme map variable russia-color */
  russiaColor: {
    type: 'color',

    rgba: 'rgba(255, 203, 203, 1)',
    hex: '#FFCBCB',
    r: 255,
    g: 203,
    b: 203,
    a: 1,
  },

  /** as theme map variable europe-color */
  europeColor: {
    type: 'color',

    rgba: 'rgba(169, 199, 247, 1)',
    hex: '#A9C7F7',
    r: 169,
    g: 199,
    b: 247,
    a: 1,
  },

  /** as theme map variable asia-color */
  asiaColor: {
    type: 'color',

    rgba: 'rgba(223, 179, 223, 1)',
    hex: '#DFB3DF',
    r: 223,
    g: 179,
    b: 223,
    a: 1,
  },

  /** as theme map variable africa-color */
  africaColor: {
    type: 'color',

    rgba: 'rgba(208, 191, 248, 1)',
    hex: '#D0BFF8',
    r: 208,
    g: 191,
    b: 248,
    a: 1,
  },

  /** as theme map variable canada-color */
  canadaColor: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable mexico-color */
  mexicoColor: {
    type: 'color',

    rgba: 'rgba(101, 201, 188, 1)',
    hex: '#65C9BC',
    r: 101,
    g: 201,
    b: 188,
    a: 1,
  },

  /** as theme map variable usa-color */
  usaColor: {
    type: 'color',

    rgba: 'rgba(164, 247, 235, 1)',
    hex: '#A4F7EB',
    r: 164,
    g: 247,
    b: 235,
    a: 1,
  },

  /** as theme map variable south-america-color */
  southAmericaColor: {
    type: 'color',

    rgba: 'rgba(194, 254, 198, 1)',
    hex: '#C2FEC6',
    r: 194,
    g: 254,
    b: 198,
    a: 1,
  },

  /** as theme map variable other-color-0 */
  otherColor0: {
    type: 'color',

    rgba: 'rgba(235, 117, 117, 1)',
    hex: '#EB7575',
    r: 235,
    g: 117,
    b: 117,
    a: 1,
  },

  /** as theme map variable other-color-1 */
  otherColor1: {
    type: 'color',

    rgba: 'rgba(214, 51, 51, 1)',
    hex: '#D63333',
    r: 214,
    g: 51,
    b: 51,
    a: 1,
  },

  /** as theme map variable other-color-2 */
  otherColor2: {
    type: 'color',

    rgba: 'rgba(41, 226, 204, 1)',
    hex: '#29E2CC',
    r: 41,
    g: 226,
    b: 204,
    a: 1,
  },

  /** as theme map variable other-color-3 */
  otherColor3: {
    type: 'color',

    rgba: 'rgba(39, 147, 50, 1)',
    hex: '#279332',
    r: 39,
    g: 147,
    b: 50,
    a: 1,
  },

  /** as theme map variable other-color-4 */
  otherColor4: {
    type: 'color',

    rgba: 'rgba(41, 182, 226, 1)',
    hex: '#29B6E2',
    r: 41,
    g: 182,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-5 */
  otherColor5: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable other-color-6 */
  otherColor6: {
    type: 'color',

    rgba: 'rgba(237, 191, 28, 1)',
    hex: '#EDBF1C',
    r: 237,
    g: 191,
    b: 28,
    a: 1,
  },

  /** as theme map variable other-color-7 */
  otherColor7: {
    type: 'color',

    rgba: 'rgba(229, 115, 99, 1)',
    hex: '#E57363',
    r: 229,
    g: 115,
    b: 99,
    a: 1,
  },

  /** as theme map variable other-color-8 */
  otherColor8: {
    type: 'color',

    rgba: 'rgba(45, 41, 226, 1)',
    hex: '#2D29E2',
    r: 45,
    g: 41,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-9 */
  otherColor9: {
    type: 'color',

    rgba: 'rgba(19, 17, 85, 1)',
    hex: '#131155',
    r: 19,
    g: 17,
    b: 85,
    a: 1,
  },

  /** as theme map variable other-color-10 */
  otherColor10: {
    type: 'color',

    rgba: 'rgba(144, 124, 226, 1)',
    hex: '#907CE2',
    r: 144,
    g: 124,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-11 */
  otherColor11: {
    type: 'color',

    rgba: 'rgba(115, 8, 153, 1)',
    hex: '#730899',
    r: 115,
    g: 8,
    b: 153,
    a: 1,
  },

  /** as theme map variable other-color-12 */
  otherColor12: {
    type: 'color',

    rgba: 'rgba(248, 119, 119, 1)',
    hex: '#F87777',
    r: 248,
    g: 119,
    b: 119,
    a: 1,
  },

  /** as theme map variable other-color-13 */
  otherColor13: {
    type: 'color',

    rgba: 'rgba(226, 41, 41, 1)',
    hex: '#E22929',
    r: 226,
    g: 41,
    b: 41,
    a: 1,
  },

  /** as theme map variable other-color-14 */
  otherColor14: {
    type: 'color',

    rgba: 'rgba(147, 39, 39, 1)',
    hex: '#932727',
    r: 147,
    g: 39,
    b: 39,
    a: 1,
  },

  /** as theme map variable other-color-15 */
  otherColor15: {
    type: 'color',

    rgba: 'rgba(131, 111, 255, 1)',
    hex: '#836FFF',
    r: 131,
    g: 111,
    b: 255,
    a: 1,
  },

  /** as theme map variable other-color-16 */
  otherColor16: {
    type: 'color',

    rgba: 'rgba(21, 6, 109, 1)',
    hex: '#15066D',
    r: 21,
    g: 6,
    b: 109,
    a: 1,
  },
};

export const $lightPalette: ColorsMap = {
  /** as theme map variable danger-color */
  dangerColor: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable success-color */
  successColor: {
    type: 'color',

    rgba: 'rgba(20, 118, 105, 1)',
    hex: '#147669',
    r: 20,
    g: 118,
    b: 105,
    a: 1,
  },

  /** as theme map variable warning-color */
  warningColor: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable status-color */
  statusColor: {
    type: 'color',

    rgba: 'rgba(67, 144, 221, 1)',
    hex: '#4390DD',
    r: 67,
    g: 144,
    b: 221,
    a: 1,
  },

  /** as theme map variable bg-color */
  bgColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable bg-main-color */
  bgMainColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable bg-block-main-color */
  bgBlockMainColor: {
    type: 'color',

    rgba: 'rgba(242, 242, 242, 1)',
    hex: '#F2F2F2',
    r: 242,
    g: 242,
    b: 242,
    a: 1,
  },

  /** as theme map variable bg-block-0-color */
  bgBlock0Color: {
    type: 'color',

    rgba: 'rgba(242, 242, 242, 1)',
    hex: '#F2F2F2',
    r: 242,
    g: 242,
    b: 242,
    a: 1,
  },

  /** as theme map variable bg-block-1-color */
  bgBlock1Color: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable bg-block-empty-color */
  bgBlockEmptyColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.65)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.65,
  },

  /** as theme map variable bg-toggle-color */
  bgToggleColor: {
    type: 'color',

    rgba: 'rgba(245, 245, 245, 1)',
    hex: '#F5F5F5',
    r: 245,
    g: 245,
    b: 245,
    a: 1,
  },

  /** as theme map variable bg-toggle-checked-color */
  bgToggleCheckedColor: {
    type: 'color',

    rgba: 'rgba(224, 225, 225, 1)',
    hex: '#E0E1E1',
    r: 224,
    g: 225,
    b: 225,
    a: 1,
  },

  /** as theme map variable bg-group-color-level-0 */
  bgGroupColorLevel0: {
    type: 'color',

    rgba: 'rgba(229, 231, 233, 1)',
    hex: '#E5E7E9',
    r: 229,
    g: 231,
    b: 233,
    a: 1,
  },

  /** as theme map variable bg-group-color-level-1 */
  bgGroupColorLevel1: {
    type: 'color',

    rgba: 'rgba(217, 219, 221, 1)',
    hex: '#D9DBDD',
    r: 217,
    g: 219,
    b: 221,
    a: 1,
  },

  /** as theme map variable bg-additional-btn-color */
  bgAdditionalBtnColor: {
    type: 'color',

    rgba: 'rgba(226, 226, 226, 1)',
    hex: '#E2E2E2',
    r: 226,
    g: 226,
    b: 226,
    a: 1,
  },

  /** as theme map variable text-color */
  textColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 1)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },

  /** as theme map variable text-main-color */
  textMainColor: {
    type: 'color',

    rgba: 'rgba(18, 18, 18, 1)',
    hex: '#121212',
    r: 18,
    g: 18,
    b: 18,
    a: 1,
  },

  /** as theme map variable text-inverse-color */
  textInverseColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable text-static-color */
  textStaticColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable text-static-light-color */
  textStaticLightColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable text-link-color */
  textLinkColor: {
    type: 'color',

    rgba: 'rgba(97, 142, 243, 1)',
    hex: '#618EF3',
    r: 97,
    g: 142,
    b: 243,
    a: 1,
  },

  /** as theme map variable field-focused-color */
  fieldFocusedColor: {
    type: 'color',

    rgba: 'rgba(242, 218, 188, 0.75)',
    hex: '#F2DABC',
    r: 242,
    g: 218,
    b: 188,
    a: 0.75,
  },

  /** as theme map variable border-color */
  borderColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable border-accent-color */
  borderAccentColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 0.1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 0.1,
  },

  /** as theme map variable shadow-color */
  shadowColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,
  },

  /** as theme map variable box-shadow */
  boxShadow: {
    type: 'box-shadow',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,

    boxShadowParams: ['0px', '4px', '8px'],
  },

  /** as theme map variable early-redemption-color */
  earlyRedemptionColor: {
    type: 'color',

    rgba: 'rgba(97, 142, 243, 1)',
    hex: '#618EF3',
    r: 97,
    g: 142,
    b: 243,
    a: 1,
  },

  /** as theme map variable dot-color-0 */
  dotColor0: {
    type: 'color',

    rgba: 'rgba(190, 246, 234, 1)',
    hex: '#BEF6EA',
    r: 190,
    g: 246,
    b: 234,
    a: 1,
  },

  /** as theme map variable dot-color-1 */
  dotColor1: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable positive-color-1 */
  positiveColor1: {
    type: 'color',

    rgba: 'rgba(7, 61, 47, 1)',
    hex: '#073D2F',
    r: 7,
    g: 61,
    b: 47,
    a: 1,
  },

  /** as theme map variable positive-color-2 */
  positiveColor2: {
    type: 'color',

    rgba: 'rgba(10, 90, 69, 1)',
    hex: '#0A5A45',
    r: 10,
    g: 90,
    b: 69,
    a: 1,
  },

  /** as theme map variable positive-color-3 */
  positiveColor3: {
    type: 'color',

    rgba: 'rgba(12, 137, 104, 1)',
    hex: '#0C8968',
    r: 12,
    g: 137,
    b: 104,
    a: 1,
  },

  /** as theme map variable negative-color-1 */
  negativeColor1: {
    type: 'color',

    rgba: 'rgba(130, 14, 14, 1)',
    hex: '#820E0E',
    r: 130,
    g: 14,
    b: 14,
    a: 1,
  },

  /** as theme map variable negative-color-2 */
  negativeColor2: {
    type: 'color',

    rgba: 'rgba(160, 15, 15, 1)',
    hex: '#A00F0F',
    r: 160,
    g: 15,
    b: 15,
    a: 1,
  },

  /** as theme map variable negative-color-3 */
  negativeColor3: {
    type: 'color',

    rgba: 'rgba(211, 26, 26, 1)',
    hex: '#D31A1A',
    r: 211,
    g: 26,
    b: 26,
    a: 1,
  },

  /** as theme map variable line-color-0 */
  lineColor0: {
    type: 'color',

    rgba: 'rgba(228, 61, 102, 1)',
    hex: '#E43D66',
    r: 228,
    g: 61,
    b: 102,
    a: 1,
  },

  /** as theme map variable line-color-1 */
  lineColor1: {
    type: 'color',

    rgba: 'rgba(176, 161, 31, 1)',
    hex: '#B0A11F',
    r: 176,
    g: 161,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-2 */
  lineColor2: {
    type: 'color',

    rgba: 'rgba(166, 10, 48, 1)',
    hex: '#A60A30',
    r: 166,
    g: 10,
    b: 48,
    a: 1,
  },

  /** as theme map variable line-color-3 */
  lineColor3: {
    type: 'color',

    rgba: 'rgba(108, 132, 23, 1)',
    hex: '#6C8417',
    r: 108,
    g: 132,
    b: 23,
    a: 1,
  },

  /** as theme map variable line-color-4 */
  lineColor4: {
    type: 'color',

    rgba: 'rgba(221, 13, 126, 1)',
    hex: '#DD0D7E',
    r: 221,
    g: 13,
    b: 126,
    a: 1,
  },

  /** as theme map variable line-color-5 */
  lineColor5: {
    type: 'color',

    rgba: 'rgba(166, 192, 76, 1)',
    hex: '#A6C04C',
    r: 166,
    g: 192,
    b: 76,
    a: 1,
  },

  /** as theme map variable line-color-6 */
  lineColor6: {
    type: 'color',

    rgba: 'rgba(192, 76, 173, 1)',
    hex: '#C04CAD',
    r: 192,
    g: 76,
    b: 173,
    a: 1,
  },

  /** as theme map variable line-color-7 */
  lineColor7: {
    type: 'color',

    rgba: 'rgba(93, 176, 31, 1)',
    hex: '#5DB01F',
    r: 93,
    g: 176,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-8 */
  lineColor8: {
    type: 'color',

    rgba: 'rgba(132, 23, 115, 1)',
    hex: '#841773',
    r: 132,
    g: 23,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-9 */
  lineColor9: {
    type: 'color',

    rgba: 'rgba(23, 132, 51, 1)',
    hex: '#178433',
    r: 23,
    g: 132,
    b: 51,
    a: 1,
  },

  /** as theme map variable line-color-10 */
  lineColor10: {
    type: 'color',

    rgba: 'rgba(221, 13, 199, 1)',
    hex: '#DD0DC7',
    r: 221,
    g: 13,
    b: 199,
    a: 1,
  },

  /** as theme map variable line-color-11 */
  lineColor11: {
    type: 'color',

    rgba: 'rgba(76, 192, 105, 1)',
    hex: '#4CC069',
    r: 76,
    g: 192,
    b: 105,
    a: 1,
  },

  /** as theme map variable line-color-12 */
  lineColor12: {
    type: 'color',

    rgba: 'rgba(153, 76, 192, 1)',
    hex: '#994CC0',
    r: 153,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-13 */
  lineColor13: {
    type: 'color',

    rgba: 'rgba(31, 176, 115, 1)',
    hex: '#1FB073',
    r: 31,
    g: 176,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-14 */
  lineColor14: {
    type: 'color',

    rgba: 'rgba(96, 23, 132, 1)',
    hex: '#601784',
    r: 96,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-15 */
  lineColor15: {
    type: 'color',

    rgba: 'rgba(23, 98, 132, 1)',
    hex: '#176284',
    r: 23,
    g: 98,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-16 */
  lineColor16: {
    type: 'color',

    rgba: 'rgba(89, 31, 176, 1)',
    hex: '#591FB0',
    r: 89,
    g: 31,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-17 */
  lineColor17: {
    type: 'color',

    rgba: 'rgba(23, 77, 132, 1)',
    hex: '#174D84',
    r: 23,
    g: 77,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-18 */
  lineColor18: {
    type: 'color',

    rgba: 'rgba(67, 23, 132, 1)',
    hex: '#431784',
    r: 67,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-19 */
  lineColor19: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-20 */
  lineColor20: {
    type: 'color',

    rgba: 'rgba(90, 76, 192, 1)',
    hex: '#5A4CC0',
    r: 90,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-21 */
  lineColor21: {
    type: 'color',

    rgba: 'rgba(31, 80, 176, 1)',
    hex: '#1F50B0',
    r: 31,
    g: 80,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-22 */
  lineColor22: {
    type: 'color',

    rgba: 'rgba(37, 23, 132, 1)',
    hex: '#251784',
    r: 37,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable pie-color-0 */
  pieColor0: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable pie-color-1 */
  pieColor1: {
    type: 'color',

    rgba: 'rgba(200, 223, 247, 1)',
    hex: '#C8DFF7',
    r: 200,
    g: 223,
    b: 247,
    a: 1,
  },

  /** as theme map variable pie-color-2 */
  pieColor2: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable pie-color-3 */
  pieColor3: {
    type: 'color',

    rgba: 'rgba(138, 200, 187, 1)',
    hex: '#8AC8BB',
    r: 138,
    g: 200,
    b: 187,
    a: 1,
  },

  /** as theme map variable pie-color-4 */
  pieColor4: {
    type: 'color',

    rgba: 'rgba(202, 189, 246, 1)',
    hex: '#CABDF6',
    r: 202,
    g: 189,
    b: 246,
    a: 1,
  },

  /** as theme map variable pie-color-5 */
  pieColor5: {
    type: 'color',

    rgba: 'rgba(244, 202, 202, 1)',
    hex: '#F4CACA',
    r: 244,
    g: 202,
    b: 202,
    a: 1,
  },

  /** as theme map variable pie-color-6 */
  pieColor6: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 1)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 1,
  },

  /** as theme map variable pie-color-7 */
  pieColor7: {
    type: 'color',

    rgba: 'rgba(242, 218, 188, 1)',
    hex: '#F2DABC',
    r: 242,
    g: 218,
    b: 188,
    a: 1,
  },

  /** as theme map variable pie-color-8 */
  pieColor8: {
    type: 'color',

    rgba: 'rgba(212, 178, 221, 1)',
    hex: '#D4B2DD',
    r: 212,
    g: 178,
    b: 221,
    a: 1,
  },

  /** as theme map variable pie-color-9 */
  pieColor9: {
    type: 'color',

    rgba: 'rgba(212, 253, 198, 1)',
    hex: '#D4FDC6',
    r: 212,
    g: 253,
    b: 198,
    a: 1,
  },

  /** as theme map variable pie-border-color */
  pieBorderColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 0.5)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 0.5,
  },

  /** as theme map variable column-color-0 */
  columnColor0: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable column-color-1 */
  columnColor1: {
    type: 'color',

    rgba: 'rgba(110, 190, 201, 1)',
    hex: '#6EBEC9',
    r: 110,
    g: 190,
    b: 201,
    a: 1,
  },

  /** as theme map variable column-color-2 */
  columnColor2: {
    type: 'color',

    rgba: 'rgba(228, 201, 106, 1)',
    hex: '#E4C96A',
    r: 228,
    g: 201,
    b: 106,
    a: 1,
  },

  /** as theme map variable column-color-3 */
  columnColor3: {
    type: 'color',

    rgba: 'rgba(224, 170, 103, 1)',
    hex: '#E0AA67',
    r: 224,
    g: 170,
    b: 103,
    a: 1,
  },

  /** as theme map variable column-color-4 */
  columnColor4: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable default-region-color */
  defaultRegionColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable australia-color */
  australiaColor: {
    type: 'color',

    rgba: 'rgba(255, 174, 100, 1)',
    hex: '#FFAE64',
    r: 255,
    g: 174,
    b: 100,
    a: 1,
  },

  /** as theme map variable russia-color */
  russiaColor: {
    type: 'color',

    rgba: 'rgba(255, 135, 108, 1)',
    hex: '#FF876C',
    r: 255,
    g: 135,
    b: 108,
    a: 1,
  },

  /** as theme map variable europe-color */
  europeColor: {
    type: 'color',

    rgba: 'rgba(142, 214, 255, 1)',
    hex: '#8ED6FF',
    r: 142,
    g: 214,
    b: 255,
    a: 1,
  },

  /** as theme map variable asia-color */
  asiaColor: {
    type: 'color',

    rgba: 'rgba(221, 93, 255, 1)',
    hex: '#DD5DFF',
    r: 221,
    g: 93,
    b: 255,
    a: 1,
  },

  /** as theme map variable africa-color */
  africaColor: {
    type: 'color',

    rgba: 'rgba(241, 205, 81, 1)',
    hex: '#F1CD51',
    r: 241,
    g: 205,
    b: 81,
    a: 1,
  },

  /** as theme map variable canada-color */
  canadaColor: {
    type: 'color',

    rgba: 'rgba(96, 178, 255, 1)',
    hex: '#60B2FF',
    r: 96,
    g: 178,
    b: 255,
    a: 1,
  },

  /** as theme map variable mexico-color */
  mexicoColor: {
    type: 'color',

    rgba: 'rgba(183, 157, 255, 1)',
    hex: '#B79DFF',
    r: 183,
    g: 157,
    b: 255,
    a: 1,
  },

  /** as theme map variable usa-color */
  usaColor: {
    type: 'color',

    rgba: 'rgba(44, 153, 255, 1)',
    hex: '#2C99FF',
    r: 44,
    g: 153,
    b: 255,
    a: 1,
  },

  /** as theme map variable south-america-color */
  southAmericaColor: {
    type: 'color',

    rgba: 'rgba(45, 219, 231, 1)',
    hex: '#2DDBE7',
    r: 45,
    g: 219,
    b: 231,
    a: 1,
  },

  /** as theme map variable other-color-0 */
  otherColor0: {
    type: 'color',

    rgba: 'rgba(235, 117, 117, 1)',
    hex: '#EB7575',
    r: 235,
    g: 117,
    b: 117,
    a: 1,
  },

  /** as theme map variable other-color-1 */
  otherColor1: {
    type: 'color',

    rgba: 'rgba(214, 51, 51, 1)',
    hex: '#D63333',
    r: 214,
    g: 51,
    b: 51,
    a: 1,
  },

  /** as theme map variable other-color-2 */
  otherColor2: {
    type: 'color',

    rgba: 'rgba(41, 226, 204, 1)',
    hex: '#29E2CC',
    r: 41,
    g: 226,
    b: 204,
    a: 1,
  },

  /** as theme map variable other-color-3 */
  otherColor3: {
    type: 'color',

    rgba: 'rgba(39, 147, 50, 1)',
    hex: '#279332',
    r: 39,
    g: 147,
    b: 50,
    a: 1,
  },

  /** as theme map variable other-color-4 */
  otherColor4: {
    type: 'color',

    rgba: 'rgba(41, 182, 226, 1)',
    hex: '#29B6E2',
    r: 41,
    g: 182,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-5 */
  otherColor5: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable other-color-6 */
  otherColor6: {
    type: 'color',

    rgba: 'rgba(237, 191, 28, 1)',
    hex: '#EDBF1C',
    r: 237,
    g: 191,
    b: 28,
    a: 1,
  },

  /** as theme map variable other-color-7 */
  otherColor7: {
    type: 'color',

    rgba: 'rgba(229, 115, 99, 1)',
    hex: '#E57363',
    r: 229,
    g: 115,
    b: 99,
    a: 1,
  },

  /** as theme map variable other-color-8 */
  otherColor8: {
    type: 'color',

    rgba: 'rgba(45, 41, 226, 1)',
    hex: '#2D29E2',
    r: 45,
    g: 41,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-9 */
  otherColor9: {
    type: 'color',

    rgba: 'rgba(19, 17, 85, 1)',
    hex: '#131155',
    r: 19,
    g: 17,
    b: 85,
    a: 1,
  },

  /** as theme map variable other-color-10 */
  otherColor10: {
    type: 'color',

    rgba: 'rgba(144, 124, 226, 1)',
    hex: '#907CE2',
    r: 144,
    g: 124,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-11 */
  otherColor11: {
    type: 'color',

    rgba: 'rgba(115, 8, 153, 1)',
    hex: '#730899',
    r: 115,
    g: 8,
    b: 153,
    a: 1,
  },

  /** as theme map variable other-color-12 */
  otherColor12: {
    type: 'color',

    rgba: 'rgba(248, 119, 119, 1)',
    hex: '#F87777',
    r: 248,
    g: 119,
    b: 119,
    a: 1,
  },

  /** as theme map variable other-color-13 */
  otherColor13: {
    type: 'color',

    rgba: 'rgba(226, 41, 41, 1)',
    hex: '#E22929',
    r: 226,
    g: 41,
    b: 41,
    a: 1,
  },

  /** as theme map variable other-color-14 */
  otherColor14: {
    type: 'color',

    rgba: 'rgba(147, 39, 39, 1)',
    hex: '#932727',
    r: 147,
    g: 39,
    b: 39,
    a: 1,
  },

  /** as theme map variable other-color-15 */
  otherColor15: {
    type: 'color',

    rgba: 'rgba(131, 111, 255, 1)',
    hex: '#836FFF',
    r: 131,
    g: 111,
    b: 255,
    a: 1,
  },

  /** as theme map variable other-color-16 */
  otherColor16: {
    type: 'color',

    rgba: 'rgba(21, 6, 109, 1)',
    hex: '#15066D',
    r: 21,
    g: 6,
    b: 109,
    a: 1,
  },
};

export const $lwamPalette: ColorsMap = {
  /** as theme map variable danger-color */
  dangerColor: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable success-color */
  successColor: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable warning-color */
  warningColor: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable status-color */
  statusColor: {
    type: 'color',

    rgba: 'rgba(67, 144, 221, 1)',
    hex: '#4390DD',
    r: 67,
    g: 144,
    b: 221,
    a: 1,
  },

  /** as theme map variable bg-color */
  bgColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable bg-main-color */
  bgMainColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable bg-block-main-color */
  bgBlockMainColor: {
    type: 'color',

    rgba: 'rgba(9, 29, 49, 1)',
    hex: '#091D31',
    r: 9,
    g: 29,
    b: 49,
    a: 1,
  },

  /** as theme map variable bg-block-0-color */
  bgBlock0Color: {
    type: 'color',

    rgba: 'rgba(9, 29, 49, 1)',
    hex: '#091D31',
    r: 9,
    g: 29,
    b: 49,
    a: 1,
  },

  /** as theme map variable bg-block-1-color */
  bgBlock1Color: {
    type: 'color',

    rgba: 'rgba(38, 53, 72, 0.25)',
    hex: '#263548',
    r: 38,
    g: 53,
    b: 72,
    a: 0.25,
  },

  /** as theme map variable bg-block-empty-color */
  bgBlockEmptyColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 0.65)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 0.65,
  },

  /** as theme map variable bg-toggle-color */
  bgToggleColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.05)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.05,
  },

  /** as theme map variable bg-toggle-checked-color */
  bgToggleCheckedColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.1,
  },

  /** as theme map variable bg-group-color-level-0 */
  bgGroupColorLevel0: {
    type: 'color',

    rgba: 'rgba(24, 43, 63, 1)',
    hex: '#182B3F',
    r: 24,
    g: 43,
    b: 63,
    a: 1,
  },

  /** as theme map variable bg-group-color-level-1 */
  bgGroupColorLevel1: {
    type: 'color',

    rgba: 'rgba(34, 50, 69, 1)',
    hex: '#223245',
    r: 34,
    g: 50,
    b: 69,
    a: 1,
  },

  /** as theme map variable bg-additional-btn-color */
  bgAdditionalBtnColor: {
    type: 'color',

    rgba: 'rgba(36, 36, 36, 1)',
    hex: '#242424',
    r: 36,
    g: 36,
    b: 36,
    a: 1,
  },

  /** as theme map variable text-color */
  textColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable text-main-color */
  textMainColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable text-inverse-color */
  textInverseColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable text-static-color */
  textStaticColor: {
    type: 'color',

    rgba: 'rgba(0, 21, 42, 1)',
    hex: '#00152A',
    r: 0,
    g: 21,
    b: 42,
    a: 1,
  },

  /** as theme map variable text-static-light-color */
  textStaticLightColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable text-link-color */
  textLinkColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable field-focused-color */
  fieldFocusedColor: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 0.75)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 0.75,
  },

  /** as theme map variable border-color */
  borderColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable border-accent-color */
  borderAccentColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.25)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.25,
  },

  /** as theme map variable shadow-color */
  shadowColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,
  },

  /** as theme map variable box-shadow */
  boxShadow: {
    type: 'box-shadow',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,

    boxShadowParams: ['0px', '4px', '8px'],
  },

  /** as theme map variable early-redemption-color */
  earlyRedemptionColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable dot-color-0 */
  dotColor0: {
    type: 'color',

    rgba: 'rgba(190, 246, 234, 1)',
    hex: '#BEF6EA',
    r: 190,
    g: 246,
    b: 234,
    a: 1,
  },

  /** as theme map variable dot-color-1 */
  dotColor1: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable positive-color-1 */
  positiveColor1: {
    type: 'color',

    rgba: 'rgba(7, 61, 47, 1)',
    hex: '#073D2F',
    r: 7,
    g: 61,
    b: 47,
    a: 1,
  },

  /** as theme map variable positive-color-2 */
  positiveColor2: {
    type: 'color',

    rgba: 'rgba(10, 90, 69, 1)',
    hex: '#0A5A45',
    r: 10,
    g: 90,
    b: 69,
    a: 1,
  },

  /** as theme map variable positive-color-3 */
  positiveColor3: {
    type: 'color',

    rgba: 'rgba(12, 137, 104, 1)',
    hex: '#0C8968',
    r: 12,
    g: 137,
    b: 104,
    a: 1,
  },

  /** as theme map variable negative-color-1 */
  negativeColor1: {
    type: 'color',

    rgba: 'rgba(130, 14, 14, 1)',
    hex: '#820E0E',
    r: 130,
    g: 14,
    b: 14,
    a: 1,
  },

  /** as theme map variable negative-color-2 */
  negativeColor2: {
    type: 'color',

    rgba: 'rgba(160, 15, 15, 1)',
    hex: '#A00F0F',
    r: 160,
    g: 15,
    b: 15,
    a: 1,
  },

  /** as theme map variable negative-color-3 */
  negativeColor3: {
    type: 'color',

    rgba: 'rgba(211, 26, 26, 1)',
    hex: '#D31A1A',
    r: 211,
    g: 26,
    b: 26,
    a: 1,
  },

  /** as theme map variable line-color-0 */
  lineColor0: {
    type: 'color',

    rgba: 'rgba(228, 61, 102, 1)',
    hex: '#E43D66',
    r: 228,
    g: 61,
    b: 102,
    a: 1,
  },

  /** as theme map variable line-color-1 */
  lineColor1: {
    type: 'color',

    rgba: 'rgba(176, 161, 31, 1)',
    hex: '#B0A11F',
    r: 176,
    g: 161,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-2 */
  lineColor2: {
    type: 'color',

    rgba: 'rgba(166, 10, 48, 1)',
    hex: '#A60A30',
    r: 166,
    g: 10,
    b: 48,
    a: 1,
  },

  /** as theme map variable line-color-3 */
  lineColor3: {
    type: 'color',

    rgba: 'rgba(108, 132, 23, 1)',
    hex: '#6C8417',
    r: 108,
    g: 132,
    b: 23,
    a: 1,
  },

  /** as theme map variable line-color-4 */
  lineColor4: {
    type: 'color',

    rgba: 'rgba(221, 13, 126, 1)',
    hex: '#DD0D7E',
    r: 221,
    g: 13,
    b: 126,
    a: 1,
  },

  /** as theme map variable line-color-5 */
  lineColor5: {
    type: 'color',

    rgba: 'rgba(166, 192, 76, 1)',
    hex: '#A6C04C',
    r: 166,
    g: 192,
    b: 76,
    a: 1,
  },

  /** as theme map variable line-color-6 */
  lineColor6: {
    type: 'color',

    rgba: 'rgba(192, 76, 173, 1)',
    hex: '#C04CAD',
    r: 192,
    g: 76,
    b: 173,
    a: 1,
  },

  /** as theme map variable line-color-7 */
  lineColor7: {
    type: 'color',

    rgba: 'rgba(93, 176, 31, 1)',
    hex: '#5DB01F',
    r: 93,
    g: 176,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-8 */
  lineColor8: {
    type: 'color',

    rgba: 'rgba(132, 23, 115, 1)',
    hex: '#841773',
    r: 132,
    g: 23,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-9 */
  lineColor9: {
    type: 'color',

    rgba: 'rgba(23, 132, 51, 1)',
    hex: '#178433',
    r: 23,
    g: 132,
    b: 51,
    a: 1,
  },

  /** as theme map variable line-color-10 */
  lineColor10: {
    type: 'color',

    rgba: 'rgba(221, 13, 199, 1)',
    hex: '#DD0DC7',
    r: 221,
    g: 13,
    b: 199,
    a: 1,
  },

  /** as theme map variable line-color-11 */
  lineColor11: {
    type: 'color',

    rgba: 'rgba(76, 192, 105, 1)',
    hex: '#4CC069',
    r: 76,
    g: 192,
    b: 105,
    a: 1,
  },

  /** as theme map variable line-color-12 */
  lineColor12: {
    type: 'color',

    rgba: 'rgba(153, 76, 192, 1)',
    hex: '#994CC0',
    r: 153,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-13 */
  lineColor13: {
    type: 'color',

    rgba: 'rgba(31, 176, 115, 1)',
    hex: '#1FB073',
    r: 31,
    g: 176,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-14 */
  lineColor14: {
    type: 'color',

    rgba: 'rgba(96, 23, 132, 1)',
    hex: '#601784',
    r: 96,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-15 */
  lineColor15: {
    type: 'color',

    rgba: 'rgba(23, 98, 132, 1)',
    hex: '#176284',
    r: 23,
    g: 98,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-16 */
  lineColor16: {
    type: 'color',

    rgba: 'rgba(89, 31, 176, 1)',
    hex: '#591FB0',
    r: 89,
    g: 31,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-17 */
  lineColor17: {
    type: 'color',

    rgba: 'rgba(23, 77, 132, 1)',
    hex: '#174D84',
    r: 23,
    g: 77,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-18 */
  lineColor18: {
    type: 'color',

    rgba: 'rgba(67, 23, 132, 1)',
    hex: '#431784',
    r: 67,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-19 */
  lineColor19: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-20 */
  lineColor20: {
    type: 'color',

    rgba: 'rgba(90, 76, 192, 1)',
    hex: '#5A4CC0',
    r: 90,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-21 */
  lineColor21: {
    type: 'color',

    rgba: 'rgba(31, 80, 176, 1)',
    hex: '#1F50B0',
    r: 31,
    g: 80,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-22 */
  lineColor22: {
    type: 'color',

    rgba: 'rgba(37, 23, 132, 1)',
    hex: '#251784',
    r: 37,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable pie-color-0 */
  pieColor0: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable pie-color-1 */
  pieColor1: {
    type: 'color',

    rgba: 'rgba(200, 223, 247, 1)',
    hex: '#C8DFF7',
    r: 200,
    g: 223,
    b: 247,
    a: 1,
  },

  /** as theme map variable pie-color-2 */
  pieColor2: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable pie-color-3 */
  pieColor3: {
    type: 'color',

    rgba: 'rgba(138, 200, 187, 1)',
    hex: '#8AC8BB',
    r: 138,
    g: 200,
    b: 187,
    a: 1,
  },

  /** as theme map variable pie-color-4 */
  pieColor4: {
    type: 'color',

    rgba: 'rgba(202, 189, 246, 1)',
    hex: '#CABDF6',
    r: 202,
    g: 189,
    b: 246,
    a: 1,
  },

  /** as theme map variable pie-color-5 */
  pieColor5: {
    type: 'color',

    rgba: 'rgba(244, 202, 202, 1)',
    hex: '#F4CACA',
    r: 244,
    g: 202,
    b: 202,
    a: 1,
  },

  /** as theme map variable pie-color-6 */
  pieColor6: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 1)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 1,
  },

  /** as theme map variable pie-color-7 */
  pieColor7: {
    type: 'color',

    rgba: 'rgba(242, 218, 188, 1)',
    hex: '#F2DABC',
    r: 242,
    g: 218,
    b: 188,
    a: 1,
  },

  /** as theme map variable pie-color-8 */
  pieColor8: {
    type: 'color',

    rgba: 'rgba(212, 178, 221, 1)',
    hex: '#D4B2DD',
    r: 212,
    g: 178,
    b: 221,
    a: 1,
  },

  /** as theme map variable pie-color-9 */
  pieColor9: {
    type: 'color',

    rgba: 'rgba(212, 253, 198, 1)',
    hex: '#D4FDC6',
    r: 212,
    g: 253,
    b: 198,
    a: 1,
  },

  /** as theme map variable pie-border-color */
  pieBorderColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.5)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.5,
  },

  /** as theme map variable column-color-0 */
  columnColor0: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable column-color-1 */
  columnColor1: {
    type: 'color',

    rgba: 'rgba(110, 190, 201, 1)',
    hex: '#6EBEC9',
    r: 110,
    g: 190,
    b: 201,
    a: 1,
  },

  /** as theme map variable column-color-2 */
  columnColor2: {
    type: 'color',

    rgba: 'rgba(228, 201, 106, 1)',
    hex: '#E4C96A',
    r: 228,
    g: 201,
    b: 106,
    a: 1,
  },

  /** as theme map variable column-color-3 */
  columnColor3: {
    type: 'color',

    rgba: 'rgba(224, 170, 103, 1)',
    hex: '#E0AA67',
    r: 224,
    g: 170,
    b: 103,
    a: 1,
  },

  /** as theme map variable column-color-4 */
  columnColor4: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable default-region-color */
  defaultRegionColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable australia-color */
  australiaColor: {
    type: 'color',

    rgba: 'rgba(250, 244, 194, 1)',
    hex: '#FAF4C2',
    r: 250,
    g: 244,
    b: 194,
    a: 1,
  },

  /** as theme map variable russia-color */
  russiaColor: {
    type: 'color',

    rgba: 'rgba(255, 203, 203, 1)',
    hex: '#FFCBCB',
    r: 255,
    g: 203,
    b: 203,
    a: 1,
  },

  /** as theme map variable europe-color */
  europeColor: {
    type: 'color',

    rgba: 'rgba(169, 199, 247, 1)',
    hex: '#A9C7F7',
    r: 169,
    g: 199,
    b: 247,
    a: 1,
  },

  /** as theme map variable asia-color */
  asiaColor: {
    type: 'color',

    rgba: 'rgba(223, 179, 223, 1)',
    hex: '#DFB3DF',
    r: 223,
    g: 179,
    b: 223,
    a: 1,
  },

  /** as theme map variable africa-color */
  africaColor: {
    type: 'color',

    rgba: 'rgba(208, 191, 248, 1)',
    hex: '#D0BFF8',
    r: 208,
    g: 191,
    b: 248,
    a: 1,
  },

  /** as theme map variable canada-color */
  canadaColor: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable mexico-color */
  mexicoColor: {
    type: 'color',

    rgba: 'rgba(101, 201, 188, 1)',
    hex: '#65C9BC',
    r: 101,
    g: 201,
    b: 188,
    a: 1,
  },

  /** as theme map variable usa-color */
  usaColor: {
    type: 'color',

    rgba: 'rgba(164, 247, 235, 1)',
    hex: '#A4F7EB',
    r: 164,
    g: 247,
    b: 235,
    a: 1,
  },

  /** as theme map variable south-america-color */
  southAmericaColor: {
    type: 'color',

    rgba: 'rgba(194, 254, 198, 1)',
    hex: '#C2FEC6',
    r: 194,
    g: 254,
    b: 198,
    a: 1,
  },

  /** as theme map variable other-color-0 */
  otherColor0: {
    type: 'color',

    rgba: 'rgba(235, 117, 117, 1)',
    hex: '#EB7575',
    r: 235,
    g: 117,
    b: 117,
    a: 1,
  },

  /** as theme map variable other-color-1 */
  otherColor1: {
    type: 'color',

    rgba: 'rgba(214, 51, 51, 1)',
    hex: '#D63333',
    r: 214,
    g: 51,
    b: 51,
    a: 1,
  },

  /** as theme map variable other-color-2 */
  otherColor2: {
    type: 'color',

    rgba: 'rgba(41, 226, 204, 1)',
    hex: '#29E2CC',
    r: 41,
    g: 226,
    b: 204,
    a: 1,
  },

  /** as theme map variable other-color-3 */
  otherColor3: {
    type: 'color',

    rgba: 'rgba(39, 147, 50, 1)',
    hex: '#279332',
    r: 39,
    g: 147,
    b: 50,
    a: 1,
  },

  /** as theme map variable other-color-4 */
  otherColor4: {
    type: 'color',

    rgba: 'rgba(41, 182, 226, 1)',
    hex: '#29B6E2',
    r: 41,
    g: 182,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-5 */
  otherColor5: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable other-color-6 */
  otherColor6: {
    type: 'color',

    rgba: 'rgba(237, 191, 28, 1)',
    hex: '#EDBF1C',
    r: 237,
    g: 191,
    b: 28,
    a: 1,
  },

  /** as theme map variable other-color-7 */
  otherColor7: {
    type: 'color',

    rgba: 'rgba(229, 115, 99, 1)',
    hex: '#E57363',
    r: 229,
    g: 115,
    b: 99,
    a: 1,
  },

  /** as theme map variable other-color-8 */
  otherColor8: {
    type: 'color',

    rgba: 'rgba(45, 41, 226, 1)',
    hex: '#2D29E2',
    r: 45,
    g: 41,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-9 */
  otherColor9: {
    type: 'color',

    rgba: 'rgba(19, 17, 85, 1)',
    hex: '#131155',
    r: 19,
    g: 17,
    b: 85,
    a: 1,
  },

  /** as theme map variable other-color-10 */
  otherColor10: {
    type: 'color',

    rgba: 'rgba(144, 124, 226, 1)',
    hex: '#907CE2',
    r: 144,
    g: 124,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-11 */
  otherColor11: {
    type: 'color',

    rgba: 'rgba(115, 8, 153, 1)',
    hex: '#730899',
    r: 115,
    g: 8,
    b: 153,
    a: 1,
  },

  /** as theme map variable other-color-12 */
  otherColor12: {
    type: 'color',

    rgba: 'rgba(248, 119, 119, 1)',
    hex: '#F87777',
    r: 248,
    g: 119,
    b: 119,
    a: 1,
  },

  /** as theme map variable other-color-13 */
  otherColor13: {
    type: 'color',

    rgba: 'rgba(226, 41, 41, 1)',
    hex: '#E22929',
    r: 226,
    g: 41,
    b: 41,
    a: 1,
  },

  /** as theme map variable other-color-14 */
  otherColor14: {
    type: 'color',

    rgba: 'rgba(147, 39, 39, 1)',
    hex: '#932727',
    r: 147,
    g: 39,
    b: 39,
    a: 1,
  },

  /** as theme map variable other-color-15 */
  otherColor15: {
    type: 'color',

    rgba: 'rgba(131, 111, 255, 1)',
    hex: '#836FFF',
    r: 131,
    g: 111,
    b: 255,
    a: 1,
  },

  /** as theme map variable other-color-16 */
  otherColor16: {
    type: 'color',

    rgba: 'rgba(21, 6, 109, 1)',
    hex: '#15066D',
    r: 21,
    g: 6,
    b: 109,
    a: 1,
  },
};

export const $qsfPalette: ColorsMap = {
  /** as theme map variable danger-color */
  dangerColor: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable success-color */
  successColor: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable warning-color */
  warningColor: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable status-color */
  statusColor: {
    type: 'color',

    rgba: 'rgba(67, 144, 221, 1)',
    hex: '#4390DD',
    r: 67,
    g: 144,
    b: 221,
    a: 1,
  },

  /** as theme map variable bg-color */
  bgColor: {
    type: 'color',

    rgba: 'rgba(212, 217, 233, 1)',
    hex: '#D4D9E9',
    r: 212,
    g: 217,
    b: 233,
    a: 1,
  },

  /** as theme map variable bg-main-color */
  bgMainColor: {
    type: 'color',

    rgba: 'rgba(4, 27, 77, 1)',
    hex: '#041B4D',
    r: 4,
    g: 27,
    b: 77,
    a: 1,
  },

  /** as theme map variable bg-block-main-color */
  bgBlockMainColor: {
    type: 'color',

    rgba: 'rgba(9, 33, 85, 1)',
    hex: '#092155',
    r: 9,
    g: 33,
    b: 85,
    a: 1,
  },

  /** as theme map variable bg-block-0-color */
  bgBlock0Color: {
    type: 'color',

    rgba: 'rgba(15, 38, 85, 1)',
    hex: '#0F2655',
    r: 15,
    g: 38,
    b: 85,
    a: 1,
  },

  /** as theme map variable bg-block-1-color */
  bgBlock1Color: {
    type: 'color',

    rgba: 'rgba(39, 58, 100, 1)',
    hex: '#273A64',
    r: 39,
    g: 58,
    b: 100,
    a: 1,
  },

  /** as theme map variable bg-block-empty-color */
  bgBlockEmptyColor: {
    type: 'color',

    rgba: 'rgba(4, 27, 77, 0.65)',
    hex: '#041B4D',
    r: 4,
    g: 27,
    b: 77,
    a: 0.65,
  },

  /** as theme map variable bg-toggle-color */
  bgToggleColor: {
    type: 'color',

    rgba: 'rgba(9, 33, 85, 1)',
    hex: '#092155',
    r: 9,
    g: 33,
    b: 85,
    a: 1,
  },

  /** as theme map variable bg-toggle-checked-color */
  bgToggleCheckedColor: {
    type: 'color',

    rgba: 'rgba(14, 43, 107, 1)',
    hex: '#0E2B6B',
    r: 14,
    g: 43,
    b: 107,
    a: 1,
  },

  /** as theme map variable bg-group-color-level-0 */
  bgGroupColorLevel0: {
    type: 'color',

    rgba: 'rgba(23, 46, 95, 1)',
    hex: '#172E5F',
    r: 23,
    g: 46,
    b: 95,
    a: 1,
  },

  /** as theme map variable bg-group-color-level-1 */
  bgGroupColorLevel1: {
    type: 'color',

    rgba: 'rgba(31, 52, 101, 1)',
    hex: '#1F3465',
    r: 31,
    g: 52,
    b: 101,
    a: 1,
  },

  /** as theme map variable bg-additional-btn-color */
  bgAdditionalBtnColor: {
    type: 'color',

    rgba: 'rgba(36, 36, 36, 1)',
    hex: '#242424',
    r: 36,
    g: 36,
    b: 36,
    a: 1,
  },

  /** as theme map variable text-color */
  textColor: {
    type: 'color',

    rgba: 'rgba(212, 217, 233, 1)',
    hex: '#D4D9E9',
    r: 212,
    g: 217,
    b: 233,
    a: 1,
  },

  /** as theme map variable text-main-color */
  textMainColor: {
    type: 'color',

    rgba: 'rgba(255, 255, 255, 1)',
    hex: '#FFFFFF',
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  },

  /** as theme map variable text-inverse-color */
  textInverseColor: {
    type: 'color',

    rgba: 'rgba(4, 27, 77, 1)',
    hex: '#041B4D',
    r: 4,
    g: 27,
    b: 77,
    a: 1,
  },

  /** as theme map variable text-static-color */
  textStaticColor: {
    type: 'color',

    rgba: 'rgba(4, 27, 77, 1)',
    hex: '#041B4D',
    r: 4,
    g: 27,
    b: 77,
    a: 1,
  },

  /** as theme map variable text-static-light-color */
  textStaticLightColor: {
    type: 'color',

    rgba: 'rgba(212, 217, 233, 1)',
    hex: '#D4D9E9',
    r: 212,
    g: 217,
    b: 233,
    a: 1,
  },

  /** as theme map variable text-link-color */
  textLinkColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable field-focused-color */
  fieldFocusedColor: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 0.75)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 0.75,
  },

  /** as theme map variable shadow-color */
  shadowColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,
  },

  /** as theme map variable border-color */
  borderColor: {
    type: 'color',

    rgba: 'rgba(212, 217, 233, 1)',
    hex: '#D4D9E9',
    r: 212,
    g: 217,
    b: 233,
    a: 1,
  },

  /** as theme map variable border-accent-color */
  borderAccentColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 0.25)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 0.25,
  },

  /** as theme map variable box-shadow */
  boxShadow: {
    type: 'box-shadow',

    rgba: 'rgba(0, 0, 0, 0.14)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.14,

    boxShadowParams: ['0px', '4px', '8px'],
  },

  /** as theme map variable early-redemption-color */
  earlyRedemptionColor: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable dot-color-0 */
  dotColor0: {
    type: 'color',

    rgba: 'rgba(190, 246, 234, 1)',
    hex: '#BEF6EA',
    r: 190,
    g: 246,
    b: 234,
    a: 1,
  },

  /** as theme map variable dot-color-1 */
  dotColor1: {
    type: 'color',

    rgba: 'rgba(240, 173, 78, 1)',
    hex: '#F0AD4E',
    r: 240,
    g: 173,
    b: 78,
    a: 1,
  },

  /** as theme map variable positive-color-1 */
  positiveColor1: {
    type: 'color',

    rgba: 'rgba(7, 61, 47, 1)',
    hex: '#073D2F',
    r: 7,
    g: 61,
    b: 47,
    a: 1,
  },

  /** as theme map variable positive-color-2 */
  positiveColor2: {
    type: 'color',

    rgba: 'rgba(10, 90, 69, 1)',
    hex: '#0A5A45',
    r: 10,
    g: 90,
    b: 69,
    a: 1,
  },

  /** as theme map variable positive-color-3 */
  positiveColor3: {
    type: 'color',

    rgba: 'rgba(12, 137, 104, 1)',
    hex: '#0C8968',
    r: 12,
    g: 137,
    b: 104,
    a: 1,
  },

  /** as theme map variable negative-color-1 */
  negativeColor1: {
    type: 'color',

    rgba: 'rgba(130, 14, 14, 1)',
    hex: '#820E0E',
    r: 130,
    g: 14,
    b: 14,
    a: 1,
  },

  /** as theme map variable negative-color-2 */
  negativeColor2: {
    type: 'color',

    rgba: 'rgba(160, 15, 15, 1)',
    hex: '#A00F0F',
    r: 160,
    g: 15,
    b: 15,
    a: 1,
  },

  /** as theme map variable negative-color-3 */
  negativeColor3: {
    type: 'color',

    rgba: 'rgba(211, 26, 26, 1)',
    hex: '#D31A1A',
    r: 211,
    g: 26,
    b: 26,
    a: 1,
  },

  /** as theme map variable line-color-0 */
  lineColor0: {
    type: 'color',

    rgba: 'rgba(228, 61, 102, 1)',
    hex: '#E43D66',
    r: 228,
    g: 61,
    b: 102,
    a: 1,
  },

  /** as theme map variable line-color-1 */
  lineColor1: {
    type: 'color',

    rgba: 'rgba(176, 161, 31, 1)',
    hex: '#B0A11F',
    r: 176,
    g: 161,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-2 */
  lineColor2: {
    type: 'color',

    rgba: 'rgba(166, 10, 48, 1)',
    hex: '#A60A30',
    r: 166,
    g: 10,
    b: 48,
    a: 1,
  },

  /** as theme map variable line-color-3 */
  lineColor3: {
    type: 'color',

    rgba: 'rgba(108, 132, 23, 1)',
    hex: '#6C8417',
    r: 108,
    g: 132,
    b: 23,
    a: 1,
  },

  /** as theme map variable line-color-4 */
  lineColor4: {
    type: 'color',

    rgba: 'rgba(221, 13, 126, 1)',
    hex: '#DD0D7E',
    r: 221,
    g: 13,
    b: 126,
    a: 1,
  },

  /** as theme map variable line-color-5 */
  lineColor5: {
    type: 'color',

    rgba: 'rgba(166, 192, 76, 1)',
    hex: '#A6C04C',
    r: 166,
    g: 192,
    b: 76,
    a: 1,
  },

  /** as theme map variable line-color-6 */
  lineColor6: {
    type: 'color',

    rgba: 'rgba(192, 76, 173, 1)',
    hex: '#C04CAD',
    r: 192,
    g: 76,
    b: 173,
    a: 1,
  },

  /** as theme map variable line-color-7 */
  lineColor7: {
    type: 'color',

    rgba: 'rgba(93, 176, 31, 1)',
    hex: '#5DB01F',
    r: 93,
    g: 176,
    b: 31,
    a: 1,
  },

  /** as theme map variable line-color-8 */
  lineColor8: {
    type: 'color',

    rgba: 'rgba(132, 23, 115, 1)',
    hex: '#841773',
    r: 132,
    g: 23,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-9 */
  lineColor9: {
    type: 'color',

    rgba: 'rgba(23, 132, 51, 1)',
    hex: '#178433',
    r: 23,
    g: 132,
    b: 51,
    a: 1,
  },

  /** as theme map variable line-color-10 */
  lineColor10: {
    type: 'color',

    rgba: 'rgba(221, 13, 199, 1)',
    hex: '#DD0DC7',
    r: 221,
    g: 13,
    b: 199,
    a: 1,
  },

  /** as theme map variable line-color-11 */
  lineColor11: {
    type: 'color',

    rgba: 'rgba(76, 192, 105, 1)',
    hex: '#4CC069',
    r: 76,
    g: 192,
    b: 105,
    a: 1,
  },

  /** as theme map variable line-color-12 */
  lineColor12: {
    type: 'color',

    rgba: 'rgba(153, 76, 192, 1)',
    hex: '#994CC0',
    r: 153,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-13 */
  lineColor13: {
    type: 'color',

    rgba: 'rgba(31, 176, 115, 1)',
    hex: '#1FB073',
    r: 31,
    g: 176,
    b: 115,
    a: 1,
  },

  /** as theme map variable line-color-14 */
  lineColor14: {
    type: 'color',

    rgba: 'rgba(96, 23, 132, 1)',
    hex: '#601784',
    r: 96,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-15 */
  lineColor15: {
    type: 'color',

    rgba: 'rgba(23, 98, 132, 1)',
    hex: '#176284',
    r: 23,
    g: 98,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-16 */
  lineColor16: {
    type: 'color',

    rgba: 'rgba(89, 31, 176, 1)',
    hex: '#591FB0',
    r: 89,
    g: 31,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-17 */
  lineColor17: {
    type: 'color',

    rgba: 'rgba(23, 77, 132, 1)',
    hex: '#174D84',
    r: 23,
    g: 77,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-18 */
  lineColor18: {
    type: 'color',

    rgba: 'rgba(67, 23, 132, 1)',
    hex: '#431784',
    r: 67,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable line-color-19 */
  lineColor19: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-20 */
  lineColor20: {
    type: 'color',

    rgba: 'rgba(90, 76, 192, 1)',
    hex: '#5A4CC0',
    r: 90,
    g: 76,
    b: 192,
    a: 1,
  },

  /** as theme map variable line-color-21 */
  lineColor21: {
    type: 'color',

    rgba: 'rgba(31, 80, 176, 1)',
    hex: '#1F50B0',
    r: 31,
    g: 80,
    b: 176,
    a: 1,
  },

  /** as theme map variable line-color-22 */
  lineColor22: {
    type: 'color',

    rgba: 'rgba(37, 23, 132, 1)',
    hex: '#251784',
    r: 37,
    g: 23,
    b: 132,
    a: 1,
  },

  /** as theme map variable pie-color-0 */
  pieColor0: {
    type: 'color',

    rgba: 'rgba(176, 197, 245, 1)',
    hex: '#B0C5F5',
    r: 176,
    g: 197,
    b: 245,
    a: 1,
  },

  /** as theme map variable pie-color-1 */
  pieColor1: {
    type: 'color',

    rgba: 'rgba(200, 223, 247, 1)',
    hex: '#C8DFF7',
    r: 200,
    g: 223,
    b: 247,
    a: 1,
  },

  /** as theme map variable pie-color-2 */
  pieColor2: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable pie-color-3 */
  pieColor3: {
    type: 'color',

    rgba: 'rgba(138, 200, 187, 1)',
    hex: '#8AC8BB',
    r: 138,
    g: 200,
    b: 187,
    a: 1,
  },

  /** as theme map variable pie-color-4 */
  pieColor4: {
    type: 'color',

    rgba: 'rgba(202, 189, 246, 1)',
    hex: '#CABDF6',
    r: 202,
    g: 189,
    b: 246,
    a: 1,
  },

  /** as theme map variable pie-color-5 */
  pieColor5: {
    type: 'color',

    rgba: 'rgba(244, 202, 202, 1)',
    hex: '#F4CACA',
    r: 244,
    g: 202,
    b: 202,
    a: 1,
  },

  /** as theme map variable pie-color-6 */
  pieColor6: {
    type: 'color',

    rgba: 'rgba(247, 243, 195, 1)',
    hex: '#F7F3C3',
    r: 247,
    g: 243,
    b: 195,
    a: 1,
  },

  /** as theme map variable pie-color-7 */
  pieColor7: {
    type: 'color',

    rgba: 'rgba(242, 218, 188, 1)',
    hex: '#F2DABC',
    r: 242,
    g: 218,
    b: 188,
    a: 1,
  },

  /** as theme map variable pie-color-8 */
  pieColor8: {
    type: 'color',

    rgba: 'rgba(212, 178, 221, 1)',
    hex: '#D4B2DD',
    r: 212,
    g: 178,
    b: 221,
    a: 1,
  },

  /** as theme map variable pie-color-9 */
  pieColor9: {
    type: 'color',

    rgba: 'rgba(212, 253, 198, 1)',
    hex: '#D4FDC6',
    r: 212,
    g: 253,
    b: 198,
    a: 1,
  },

  /** as theme map variable pie-border-color */
  pieBorderColor: {
    type: 'color',

    rgba: 'rgba(0, 0, 0, 0.5)',
    hex: '#000000',
    r: 0,
    g: 0,
    b: 0,
    a: 0.5,
  },

  /** as theme map variable column-color-0 */
  columnColor0: {
    type: 'color',

    rgba: 'rgba(78, 207, 189, 1)',
    hex: '#4ECFBD',
    r: 78,
    g: 207,
    b: 189,
    a: 1,
  },

  /** as theme map variable column-color-1 */
  columnColor1: {
    type: 'color',

    rgba: 'rgba(110, 190, 201, 1)',
    hex: '#6EBEC9',
    r: 110,
    g: 190,
    b: 201,
    a: 1,
  },

  /** as theme map variable column-color-2 */
  columnColor2: {
    type: 'color',

    rgba: 'rgba(228, 201, 106, 1)',
    hex: '#E4C96A',
    r: 228,
    g: 201,
    b: 106,
    a: 1,
  },

  /** as theme map variable column-color-3 */
  columnColor3: {
    type: 'color',

    rgba: 'rgba(224, 170, 103, 1)',
    hex: '#E0AA67',
    r: 224,
    g: 170,
    b: 103,
    a: 1,
  },

  /** as theme map variable column-color-4 */
  columnColor4: {
    type: 'color',

    rgba: 'rgba(255, 126, 103, 1)',
    hex: '#FF7E67',
    r: 255,
    g: 126,
    b: 103,
    a: 1,
  },

  /** as theme map variable default-region-color */
  defaultRegionColor: {
    type: 'color',

    rgba: 'rgba(230, 234, 246, 1)',
    hex: '#E6EAF6',
    r: 230,
    g: 234,
    b: 246,
    a: 1,
  },

  /** as theme map variable australia-color */
  australiaColor: {
    type: 'color',

    rgba: 'rgba(250, 244, 194, 1)',
    hex: '#FAF4C2',
    r: 250,
    g: 244,
    b: 194,
    a: 1,
  },

  /** as theme map variable russia-color */
  russiaColor: {
    type: 'color',

    rgba: 'rgba(255, 203, 203, 1)',
    hex: '#FFCBCB',
    r: 255,
    g: 203,
    b: 203,
    a: 1,
  },

  /** as theme map variable europe-color */
  europeColor: {
    type: 'color',

    rgba: 'rgba(169, 199, 247, 1)',
    hex: '#A9C7F7',
    r: 169,
    g: 199,
    b: 247,
    a: 1,
  },

  /** as theme map variable asia-color */
  asiaColor: {
    type: 'color',

    rgba: 'rgba(223, 179, 223, 1)',
    hex: '#DFB3DF',
    r: 223,
    g: 179,
    b: 223,
    a: 1,
  },

  /** as theme map variable africa-color */
  africaColor: {
    type: 'color',

    rgba: 'rgba(208, 191, 248, 1)',
    hex: '#D0BFF8',
    r: 208,
    g: 191,
    b: 248,
    a: 1,
  },

  /** as theme map variable canada-color */
  canadaColor: {
    type: 'color',

    rgba: 'rgba(229, 236, 252, 1)',
    hex: '#E5ECFC',
    r: 229,
    g: 236,
    b: 252,
    a: 1,
  },

  /** as theme map variable mexico-color */
  mexicoColor: {
    type: 'color',

    rgba: 'rgba(101, 201, 188, 1)',
    hex: '#65C9BC',
    r: 101,
    g: 201,
    b: 188,
    a: 1,
  },

  /** as theme map variable usa-color */
  usaColor: {
    type: 'color',

    rgba: 'rgba(164, 247, 235, 1)',
    hex: '#A4F7EB',
    r: 164,
    g: 247,
    b: 235,
    a: 1,
  },

  /** as theme map variable south-america-color */
  southAmericaColor: {
    type: 'color',

    rgba: 'rgba(194, 254, 198, 1)',
    hex: '#C2FEC6',
    r: 194,
    g: 254,
    b: 198,
    a: 1,
  },

  /** as theme map variable other-color-0 */
  otherColor0: {
    type: 'color',

    rgba: 'rgba(235, 117, 117, 1)',
    hex: '#EB7575',
    r: 235,
    g: 117,
    b: 117,
    a: 1,
  },

  /** as theme map variable other-color-1 */
  otherColor1: {
    type: 'color',

    rgba: 'rgba(214, 51, 51, 1)',
    hex: '#D63333',
    r: 214,
    g: 51,
    b: 51,
    a: 1,
  },

  /** as theme map variable other-color-2 */
  otherColor2: {
    type: 'color',

    rgba: 'rgba(41, 226, 204, 1)',
    hex: '#29E2CC',
    r: 41,
    g: 226,
    b: 204,
    a: 1,
  },

  /** as theme map variable other-color-3 */
  otherColor3: {
    type: 'color',

    rgba: 'rgba(39, 147, 50, 1)',
    hex: '#279332',
    r: 39,
    g: 147,
    b: 50,
    a: 1,
  },

  /** as theme map variable other-color-4 */
  otherColor4: {
    type: 'color',

    rgba: 'rgba(41, 182, 226, 1)',
    hex: '#29B6E2',
    r: 41,
    g: 182,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-5 */
  otherColor5: {
    type: 'color',

    rgba: 'rgba(76, 133, 192, 1)',
    hex: '#4C85C0',
    r: 76,
    g: 133,
    b: 192,
    a: 1,
  },

  /** as theme map variable other-color-6 */
  otherColor6: {
    type: 'color',

    rgba: 'rgba(237, 191, 28, 1)',
    hex: '#EDBF1C',
    r: 237,
    g: 191,
    b: 28,
    a: 1,
  },

  /** as theme map variable other-color-7 */
  otherColor7: {
    type: 'color',

    rgba: 'rgba(229, 115, 99, 1)',
    hex: '#E57363',
    r: 229,
    g: 115,
    b: 99,
    a: 1,
  },

  /** as theme map variable other-color-8 */
  otherColor8: {
    type: 'color',

    rgba: 'rgba(45, 41, 226, 1)',
    hex: '#2D29E2',
    r: 45,
    g: 41,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-9 */
  otherColor9: {
    type: 'color',

    rgba: 'rgba(19, 17, 85, 1)',
    hex: '#131155',
    r: 19,
    g: 17,
    b: 85,
    a: 1,
  },

  /** as theme map variable other-color-10 */
  otherColor10: {
    type: 'color',

    rgba: 'rgba(144, 124, 226, 1)',
    hex: '#907CE2',
    r: 144,
    g: 124,
    b: 226,
    a: 1,
  },

  /** as theme map variable other-color-11 */
  otherColor11: {
    type: 'color',

    rgba: 'rgba(115, 8, 153, 1)',
    hex: '#730899',
    r: 115,
    g: 8,
    b: 153,
    a: 1,
  },

  /** as theme map variable other-color-12 */
  otherColor12: {
    type: 'color',

    rgba: 'rgba(248, 119, 119, 1)',
    hex: '#F87777',
    r: 248,
    g: 119,
    b: 119,
    a: 1,
  },

  /** as theme map variable other-color-13 */
  otherColor13: {
    type: 'color',

    rgba: 'rgba(226, 41, 41, 1)',
    hex: '#E22929',
    r: 226,
    g: 41,
    b: 41,
    a: 1,
  },

  /** as theme map variable other-color-14 */
  otherColor14: {
    type: 'color',

    rgba: 'rgba(147, 39, 39, 1)',
    hex: '#932727',
    r: 147,
    g: 39,
    b: 39,
    a: 1,
  },

  /** as theme map variable other-color-15 */
  otherColor15: {
    type: 'color',

    rgba: 'rgba(131, 111, 255, 1)',
    hex: '#836FFF',
    r: 131,
    g: 111,
    b: 255,
    a: 1,
  },

  /** as theme map variable other-color-16 */
  otherColor16: {
    type: 'color',

    rgba: 'rgba(21, 6, 109, 1)',
    hex: '#15066D',
    r: 21,
    g: 6,
    b: 109,
    a: 1,
  },
};
