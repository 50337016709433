<app-dialog [canOpenInPage]="false"
            [showLoader]="loading$|async">
  <app-dialog-header>
    <span class="heading-4 app-text-not-capitalize">Submit a support ticket</span>
  </app-dialog-header>

  <app-grid [formGroup]="formGroup">
    <app-grid-tile>
      <label class="app-label app-label-required">Type</label>
      <mat-button-toggle-group class="--snack"
                               formControlName="issueType"
                               style="margin-bottom: 21px;">
        <mat-button-toggle [value]="IssueType.Bug">Bug found</mat-button-toggle>
        <mat-button-toggle [value]="IssueType.NewRequest">Improvement</mat-button-toggle>
      </mat-button-toggle-group>
    </app-grid-tile>

    <app-grid-tile>
      <app-input label="Brief Description of the Problem"
                 formControlName="summary">
      </app-input>
    </app-grid-tile>

    <app-grid-tile>
      <app-select label="Priority"
                  [options]="priorities"
                  [showSearch]="false"
                  formControlName="priority">
      </app-select>
    </app-grid-tile>

    <app-grid-tile>
      <app-input label="Description"
                 type="textarea"
                 [gap]="false"
                 formControlName="description">
      </app-input>
    </app-grid-tile>

    <app-grid-tile>
      <app-drag-and-drop-uploader *ngLet="files$|async as _files"
                                  label="Screenshots"
                                  [files]="_files"
                                  format="base64"
                                  [accept]="'.jpg,.jpeg,.png,.gif,.pdf,.hevc'"
                                  [multiple]="true"
                                  (changeFiles)="files$.next($event)">
      </app-drag-and-drop-uploader>
    </app-grid-tile>
  </app-grid>

  <app-dialog-footer>
    <app-button [disabled]="loading$|async"
                (click)="_onSubmitClick()">
      {{'common.send'|translate}}
    </app-button>
  </app-dialog-footer>
</app-dialog>