import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomDatepickerType } from '@ui/input/input.types';

@Pipe({
  name: 'getCustomDatepickerControl',
})
export class GetCustomDatepickerControlPipe implements PipeTransform {
  public transform<T>(formControl: FormControl<T>): FormControl<CustomDatepickerType> {
    return formControl as FormControl<CustomDatepickerType>;
  }
}
