import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseObject } from '@shared/base/base-object';

interface LetContext<T> {
  ngLet: T;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngLet]',
})
export class LetDirective<T> extends BaseObject {
  private context: LetContext<T> = { ngLet: null };
  private output$: Subject<T>;

  @Input()
  public set ngLet(value: T) {
    this.context.ngLet = value;
    this.output$?.next(value);
  }

  @Input()
  public set ngLetOutput(value: Subject<T>) {
    this.output$ = value;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<LetContext<T>>,
  ) {
    super();

    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility, @typescript-eslint/no-unused-vars
  static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: unknown): ctx is LetContext<T> {
    return true;
  }
}
