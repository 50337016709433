import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from './environments/environment';

/**
 * Hammerjs must be imported for gestures
 */
import 'hammerjs';

if (environment.enabledProdMode) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(`%c bootstrapModule catch error: ${err}`, 'color: red'));

// declare var module: any;
// if (module['hot']) {
//   module['hot'].accept();
//   module['hot'].dispose(() => ɵresetCompiledComponents());
// }
