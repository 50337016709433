/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TradeTypeDTO } from './trade-type-dto';
import { BankAccountAndAccountDTO } from './bank-account-and-account-dto';
import { OrderTinyDTO } from './order-tiny-dto';

export interface TransactionDTO {
  accrued: number;
  bankAccount?: BankAccountAndAccountDTO;
  bankAccountId: string;
  ccy: string;
  childCount?: number;
  commission: number;
  counterparty?: boolean;
  createdAt?: string;
  createdBy?: string;
  dealDate?: string;
  description?: string;
  duplicate?: TransactionDTO;
  emirReportable?: boolean;
  errorDescription?: string;
  externalRef?: string;
  grossAmount: number;
  ibanID?: string;
  id?: string;
  incorrectIncome?: boolean;
  initialTransactionId?: string;
  instrumentName?: string;
  instrumentRef?: string;
  isin?: string;
  marketType?: TransactionDTO.MarketTypeEnum;
  matched?: boolean;
  matchingComment?: string;
  matchingError?: TransactionDTO.MatchingErrorEnum;
  mifirReportable?: boolean;
  multileg?: boolean;
  netAmount: number;
  netAmountPortfolioCcy: number;
  newBankAccountExternalName?: string;
  newCcy?: string;
  newIbanID?: string;
  newInstrumentId?: string;
  newPortfolioCcy?: string;
  offCustodian?: boolean;
  omnibus?: TransactionDTO;
  omnibusParent?: boolean;
  order?: OrderTinyDTO;
  orderSentBy?: TransactionDTO.OrderSentByEnum;
  parent?: TransactionDTO;
  paymentDate?: string;
  portfolioCcy: string;
  price: number;
  privateTransaction?: boolean;
  productId?: string;
  qty: number;
  reversal?: boolean;
  securitiesMissed?: boolean;
  settled?: boolean;
  settlementRule?: TransactionDTO.SettlementRuleEnum;
  sovaSecurityExchange?: string;
  status?: TransactionDTO.StatusEnum;
  statusDescription?: string;
  statusReason?: TransactionDTO.StatusReasonEnum;
  subType?: TransactionDTO.SubTypeEnum;
  tradeTime?: string;
  tradeType?: TradeTypeDTO;
  transactionDate: string;
  transactionNumber?: string;
  transactionSource: TransactionDTO.TransactionSourceEnum;
  type: TransactionDTO.TypeEnum;
  updatedAt?: string;
  updatedBy?: string;
  value: number;
  valueDate: string;
  venue?: TradeTypeDTO;
}
export namespace TransactionDTO {
  export type MarketTypeEnum = 'DMA' | 'EOMS' | 'OTC';
  export const MarketTypeEnum = {
    Dma: 'DMA' as MarketTypeEnum,
    Eoms: 'EOMS' as MarketTypeEnum,
    Otc: 'OTC' as MarketTypeEnum,
  };
  export type MatchingErrorEnum =
    | 'INCORRECT_BANK_DATA'
    | 'INCORRECT_ODER_DATA'
    | 'ORDER_EXECUTED_LATE'
    | 'TECHNICAL_ERROR';
  export const MatchingErrorEnum = {
    IncorrectBankData: 'INCORRECT_BANK_DATA' as MatchingErrorEnum,
    IncorrectOderData: 'INCORRECT_ODER_DATA' as MatchingErrorEnum,
    OrderExecutedLate: 'ORDER_EXECUTED_LATE' as MatchingErrorEnum,
    TechnicalError: 'TECHNICAL_ERROR' as MatchingErrorEnum,
  };
  export type OrderSentByEnum =
    | 'BLOOMBERG'
    | 'ELECTRONIC'
    | 'EMAIL'
    | 'FIX'
    | 'OTHER'
    | 'PHONE'
    | 'QUIK';
  export const OrderSentByEnum = {
    Bloomberg: 'BLOOMBERG' as OrderSentByEnum,
    Electronic: 'ELECTRONIC' as OrderSentByEnum,
    Email: 'EMAIL' as OrderSentByEnum,
    Fix: 'FIX' as OrderSentByEnum,
    Other: 'OTHER' as OrderSentByEnum,
    Phone: 'PHONE' as OrderSentByEnum,
    Quik: 'QUIK' as OrderSentByEnum,
  };
  export type SettlementRuleEnum = 'SETTLEMENT_CONF_BASED' | 'VALUE_DATE_BASED';
  export const SettlementRuleEnum = {
    SettlementConfBased: 'SETTLEMENT_CONF_BASED' as SettlementRuleEnum,
    ValueDateBased: 'VALUE_DATE_BASED' as SettlementRuleEnum,
  };
  export type StatusEnum = 'ACTIVE' | 'DEACTIVATED' | 'ERROR' | 'PENDING';
  export const StatusEnum = {
    Active: 'ACTIVE' as StatusEnum,
    Deactivated: 'DEACTIVATED' as StatusEnum,
    Error: 'ERROR' as StatusEnum,
    Pending: 'PENDING' as StatusEnum,
  };
  export type StatusReasonEnum =
    | 'Amalgamate'
    | 'Confirmed'
    | 'Duplicate'
    | 'Error'
    | 'Matching_error'
    | 'Not_allocated'
    | 'Not_matched'
    | 'Order'
    | 'Parsing_error'
    | 'Pending_confirmation'
    | 'Potential_error'
    | 'Processing_error';
  export const StatusReasonEnum = {
    Amalgamate: 'Amalgamate' as StatusReasonEnum,
    Confirmed: 'Confirmed' as StatusReasonEnum,
    Duplicate: 'Duplicate' as StatusReasonEnum,
    Error: 'Error' as StatusReasonEnum,
    MatchingError: 'Matching_error' as StatusReasonEnum,
    NotAllocated: 'Not_allocated' as StatusReasonEnum,
    NotMatched: 'Not_matched' as StatusReasonEnum,
    Order: 'Order' as StatusReasonEnum,
    ParsingError: 'Parsing_error' as StatusReasonEnum,
    PendingConfirmation: 'Pending_confirmation' as StatusReasonEnum,
    PotentialError: 'Potential_error' as StatusReasonEnum,
    ProcessingError: 'Processing_error' as StatusReasonEnum,
  };
  export type SubTypeEnum =
    | 'ADMINISTRATION'
    | 'ADR_FEE'
    | 'AMENDMENT_FEE'
    | 'ASSIMILATION'
    | 'AUCTION_TENDER_OFFER'
    | 'BLOCKING'
    | 'BLOCKING_OF_CERTIFICATE'
    | 'BUY'
    | 'BUY_ISSUE_IPO'
    | 'CALLED'
    | 'CAPITAL_CALL'
    | 'CAPITAL_INCREASE'
    | 'CASH_TRANSFER_FEE'
    | 'COMMISSION'
    | 'COMPENSATION'
    | 'CONSENT'
    | 'CORP_ACTION'
    | 'COUPON'
    | 'CREDIT'
    | 'CUSTODY'
    | 'DEBIT'
    | 'DEPOSIT'
    | 'DIVIDEND'
    | 'DVP'
    | 'EXCHANGE_FEE'
    | 'EXCHANGE_OF_SECURITES'
    | 'EXECUTION'
    | 'EXPIRY_OF_SECURITY'
    | 'EXPOSURE_FEE'
    | 'FRACTIONAL_SHARE'
    | 'FROM_SPIN_OFF'
    | 'FULL'
    | 'HANDLE_FEE'
    | 'IN'
    | 'INCENTIVE'
    | 'INFO'
    | 'INTEREST_CALCULATION'
    | 'INTERNAL'
    | 'INVOICE'
    | 'ISIN_CHANGE'
    | 'LIQUIDATION'
    | 'LOAN'
    | 'MANAGEMENT'
    | 'MERGE'
    | 'OPERATION'
    | 'OPTIONAL_DIVIDEND'
    | 'ORDER'
    | 'OTHER'
    | 'OTHER_TAX'
    | 'OUT'
    | 'OVERNIGHT_POSITION_FEE'
    | 'PARTIAL'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIBE'
    | 'PUBLIC_OFFER'
    | 'PURCHASE_OFFER'
    | 'REIMBURSEMENT'
    | 'REPO'
    | 'RETRO'
    | 'RETROCESSION'
    | 'REVENUE'
    | 'REVERSE_SPLIT'
    | 'SALES_TAX'
    | 'SECURITIES_LANDING'
    | 'SECURITY_EXCHANGE'
    | 'SELL'
    | 'SERVICE_CHARGE_CALCULATION'
    | 'SETTLEMENT_FEE'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'SPLIT_ISIN_CHANGE'
    | 'STOCK_TRANSFER_FEE'
    | 'SUBSCRIPTION'
    | 'SUCCESS'
    | 'TECHNICAL_CORRECTION'
    | 'TRADING'
    | 'UNBLOCKING'
    | 'UNBLOCKING_BLOCKING'
    | 'VARIATION_MARGIN'
    | 'WITHDRAWAL_FEE'
    | 'WITHHOLDING_TAX';
  export const SubTypeEnum = {
    Administration: 'ADMINISTRATION' as SubTypeEnum,
    AdrFee: 'ADR_FEE' as SubTypeEnum,
    AmendmentFee: 'AMENDMENT_FEE' as SubTypeEnum,
    Assimilation: 'ASSIMILATION' as SubTypeEnum,
    AuctionTenderOffer: 'AUCTION_TENDER_OFFER' as SubTypeEnum,
    Blocking: 'BLOCKING' as SubTypeEnum,
    BlockingOfCertificate: 'BLOCKING_OF_CERTIFICATE' as SubTypeEnum,
    Buy: 'BUY' as SubTypeEnum,
    BuyIssueIpo: 'BUY_ISSUE_IPO' as SubTypeEnum,
    Called: 'CALLED' as SubTypeEnum,
    CapitalCall: 'CAPITAL_CALL' as SubTypeEnum,
    CapitalIncrease: 'CAPITAL_INCREASE' as SubTypeEnum,
    CashTransferFee: 'CASH_TRANSFER_FEE' as SubTypeEnum,
    Commission: 'COMMISSION' as SubTypeEnum,
    Compensation: 'COMPENSATION' as SubTypeEnum,
    Consent: 'CONSENT' as SubTypeEnum,
    CorpAction: 'CORP_ACTION' as SubTypeEnum,
    Coupon: 'COUPON' as SubTypeEnum,
    Credit: 'CREDIT' as SubTypeEnum,
    Custody: 'CUSTODY' as SubTypeEnum,
    Debit: 'DEBIT' as SubTypeEnum,
    Deposit: 'DEPOSIT' as SubTypeEnum,
    Dividend: 'DIVIDEND' as SubTypeEnum,
    Dvp: 'DVP' as SubTypeEnum,
    ExchangeFee: 'EXCHANGE_FEE' as SubTypeEnum,
    ExchangeOfSecurites: 'EXCHANGE_OF_SECURITES' as SubTypeEnum,
    Execution: 'EXECUTION' as SubTypeEnum,
    ExpiryOfSecurity: 'EXPIRY_OF_SECURITY' as SubTypeEnum,
    ExposureFee: 'EXPOSURE_FEE' as SubTypeEnum,
    FractionalShare: 'FRACTIONAL_SHARE' as SubTypeEnum,
    FromSpinOff: 'FROM_SPIN_OFF' as SubTypeEnum,
    Full: 'FULL' as SubTypeEnum,
    HandleFee: 'HANDLE_FEE' as SubTypeEnum,
    In: 'IN' as SubTypeEnum,
    Incentive: 'INCENTIVE' as SubTypeEnum,
    Info: 'INFO' as SubTypeEnum,
    InterestCalculation: 'INTEREST_CALCULATION' as SubTypeEnum,
    Internal: 'INTERNAL' as SubTypeEnum,
    Invoice: 'INVOICE' as SubTypeEnum,
    IsinChange: 'ISIN_CHANGE' as SubTypeEnum,
    Liquidation: 'LIQUIDATION' as SubTypeEnum,
    Loan: 'LOAN' as SubTypeEnum,
    Management: 'MANAGEMENT' as SubTypeEnum,
    Merge: 'MERGE' as SubTypeEnum,
    Operation: 'OPERATION' as SubTypeEnum,
    OptionalDividend: 'OPTIONAL_DIVIDEND' as SubTypeEnum,
    Order: 'ORDER' as SubTypeEnum,
    Other: 'OTHER' as SubTypeEnum,
    OtherTax: 'OTHER_TAX' as SubTypeEnum,
    Out: 'OUT' as SubTypeEnum,
    OvernightPositionFee: 'OVERNIGHT_POSITION_FEE' as SubTypeEnum,
    Partial: 'PARTIAL' as SubTypeEnum,
    Payment: 'PAYMENT' as SubTypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as SubTypeEnum,
    ProductUnsubscribe: 'PRODUCT_UNSUBSCRIBE' as SubTypeEnum,
    PublicOffer: 'PUBLIC_OFFER' as SubTypeEnum,
    PurchaseOffer: 'PURCHASE_OFFER' as SubTypeEnum,
    Reimbursement: 'REIMBURSEMENT' as SubTypeEnum,
    Repo: 'REPO' as SubTypeEnum,
    Retro: 'RETRO' as SubTypeEnum,
    Retrocession: 'RETROCESSION' as SubTypeEnum,
    Revenue: 'REVENUE' as SubTypeEnum,
    ReverseSplit: 'REVERSE_SPLIT' as SubTypeEnum,
    SalesTax: 'SALES_TAX' as SubTypeEnum,
    SecuritiesLanding: 'SECURITIES_LANDING' as SubTypeEnum,
    SecurityExchange: 'SECURITY_EXCHANGE' as SubTypeEnum,
    Sell: 'SELL' as SubTypeEnum,
    ServiceChargeCalculation: 'SERVICE_CHARGE_CALCULATION' as SubTypeEnum,
    SettlementFee: 'SETTLEMENT_FEE' as SubTypeEnum,
    SpinOff: 'SPIN_OFF' as SubTypeEnum,
    Split: 'SPLIT' as SubTypeEnum,
    SplitIsinChange: 'SPLIT_ISIN_CHANGE' as SubTypeEnum,
    StockTransferFee: 'STOCK_TRANSFER_FEE' as SubTypeEnum,
    Subscription: 'SUBSCRIPTION' as SubTypeEnum,
    Success: 'SUCCESS' as SubTypeEnum,
    TechnicalCorrection: 'TECHNICAL_CORRECTION' as SubTypeEnum,
    Trading: 'TRADING' as SubTypeEnum,
    Unblocking: 'UNBLOCKING' as SubTypeEnum,
    UnblockingBlocking: 'UNBLOCKING_BLOCKING' as SubTypeEnum,
    VariationMargin: 'VARIATION_MARGIN' as SubTypeEnum,
    WithdrawalFee: 'WITHDRAWAL_FEE' as SubTypeEnum,
    WithholdingTax: 'WITHHOLDING_TAX' as SubTypeEnum,
  };
  export type TransactionSourceEnum =
    | 'ADCB'
    | 'ALFA'
    | 'BKS'
    | 'BTA'
    | 'CBH'
    | 'CBH_BS'
    | 'CITI'
    | 'DASL'
    | 'DBS'
    | 'DMBL'
    | 'DOLFIN'
    | 'EBICS'
    | 'EDR'
    | 'ENBD'
    | 'EUROBANK_CYPRUS_LTD'
    | 'EXANTE'
    | 'FAB'
    | 'FREEDOM_FINANCE'
    | 'GPP'
    | 'HELLENIC'
    | 'IBKR'
    | 'IFX'
    | 'INTESA'
    | 'INVESTEC'
    | 'ITI'
    | 'JPM'
    | 'JULIUS_BAER'
    | 'LINEAR'
    | 'MARKET_SECURITIES'
    | 'MEGA_EQUITY'
    | 'MISR'
    | 'NAT_WEST'
    | 'NLB'
    | 'OTP'
    | 'QSF_PHYSICAL_SAFE'
    | 'QUEEN_STREET_FINANCE'
    | 'RJO'
    | 'SALTEDGE'
    | 'SOVA'
    | 'TAVIRA'
    | 'UBS'
    | 'UBS_special'
    | 'UNKNOWN'
    | 'VANTAGE'
    | 'VP'
    | 'VTB'
    | 'WH_IRELAND'
    | 'WIO'
    | 'XALQ';
  export const TransactionSourceEnum = {
    Adcb: 'ADCB' as TransactionSourceEnum,
    Alfa: 'ALFA' as TransactionSourceEnum,
    Bks: 'BKS' as TransactionSourceEnum,
    Bta: 'BTA' as TransactionSourceEnum,
    Cbh: 'CBH' as TransactionSourceEnum,
    CbhBs: 'CBH_BS' as TransactionSourceEnum,
    Citi: 'CITI' as TransactionSourceEnum,
    Dasl: 'DASL' as TransactionSourceEnum,
    Dbs: 'DBS' as TransactionSourceEnum,
    Dmbl: 'DMBL' as TransactionSourceEnum,
    Dolfin: 'DOLFIN' as TransactionSourceEnum,
    Ebics: 'EBICS' as TransactionSourceEnum,
    Edr: 'EDR' as TransactionSourceEnum,
    Enbd: 'ENBD' as TransactionSourceEnum,
    EurobankCyprusLtd: 'EUROBANK_CYPRUS_LTD' as TransactionSourceEnum,
    Exante: 'EXANTE' as TransactionSourceEnum,
    Fab: 'FAB' as TransactionSourceEnum,
    FreedomFinance: 'FREEDOM_FINANCE' as TransactionSourceEnum,
    Gpp: 'GPP' as TransactionSourceEnum,
    Hellenic: 'HELLENIC' as TransactionSourceEnum,
    Ibkr: 'IBKR' as TransactionSourceEnum,
    Ifx: 'IFX' as TransactionSourceEnum,
    Intesa: 'INTESA' as TransactionSourceEnum,
    Investec: 'INVESTEC' as TransactionSourceEnum,
    Iti: 'ITI' as TransactionSourceEnum,
    Jpm: 'JPM' as TransactionSourceEnum,
    JuliusBaer: 'JULIUS_BAER' as TransactionSourceEnum,
    Linear: 'LINEAR' as TransactionSourceEnum,
    MarketSecurities: 'MARKET_SECURITIES' as TransactionSourceEnum,
    MegaEquity: 'MEGA_EQUITY' as TransactionSourceEnum,
    Misr: 'MISR' as TransactionSourceEnum,
    NatWest: 'NAT_WEST' as TransactionSourceEnum,
    Nlb: 'NLB' as TransactionSourceEnum,
    Otp: 'OTP' as TransactionSourceEnum,
    QsfPhysicalSafe: 'QSF_PHYSICAL_SAFE' as TransactionSourceEnum,
    QueenStreetFinance: 'QUEEN_STREET_FINANCE' as TransactionSourceEnum,
    Rjo: 'RJO' as TransactionSourceEnum,
    Saltedge: 'SALTEDGE' as TransactionSourceEnum,
    Sova: 'SOVA' as TransactionSourceEnum,
    Tavira: 'TAVIRA' as TransactionSourceEnum,
    Ubs: 'UBS' as TransactionSourceEnum,
    UbsSpecial: 'UBS_special' as TransactionSourceEnum,
    Unknown: 'UNKNOWN' as TransactionSourceEnum,
    Vantage: 'VANTAGE' as TransactionSourceEnum,
    Vp: 'VP' as TransactionSourceEnum,
    Vtb: 'VTB' as TransactionSourceEnum,
    WhIreland: 'WH_IRELAND' as TransactionSourceEnum,
    Wio: 'WIO' as TransactionSourceEnum,
    Xalq: 'XALQ' as TransactionSourceEnum,
  };
  export type TypeEnum =
    | 'ADJUSTMENT'
    | 'BLOCK'
    | 'BOND_REDEMPTION'
    | 'BOND_REDEMPTION_PARTIAL'
    | 'BUY'
    | 'CASH_TRANSFER'
    | 'CORPORATE_ACTION'
    | 'EXERCISE_OPTION'
    | 'EXPENSE'
    | 'EXPIRE_OPTION'
    | 'FEE'
    | 'FX'
    | 'INCOME'
    | 'INFO'
    | 'INTEREST'
    | 'INTERNAL_TRANSFER'
    | 'INVESTMENT'
    | 'MARGIN'
    | 'PAYMENT'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIPTION'
    | 'REPO'
    | 'REVENUE'
    | 'REVERSAL'
    | 'SECURITY_TRANSFER'
    | 'SELL'
    | 'TAX'
    | 'TRANSFER'
    | 'UNKNOWN'
    | 'VIRTUAL_SECURITY_TRANSFER'
    | 'WITHDRAWAL';
  export const TypeEnum = {
    Adjustment: 'ADJUSTMENT' as TypeEnum,
    Block: 'BLOCK' as TypeEnum,
    BondRedemption: 'BOND_REDEMPTION' as TypeEnum,
    BondRedemptionPartial: 'BOND_REDEMPTION_PARTIAL' as TypeEnum,
    Buy: 'BUY' as TypeEnum,
    CashTransfer: 'CASH_TRANSFER' as TypeEnum,
    CorporateAction: 'CORPORATE_ACTION' as TypeEnum,
    ExerciseOption: 'EXERCISE_OPTION' as TypeEnum,
    Expense: 'EXPENSE' as TypeEnum,
    ExpireOption: 'EXPIRE_OPTION' as TypeEnum,
    Fee: 'FEE' as TypeEnum,
    Fx: 'FX' as TypeEnum,
    Income: 'INCOME' as TypeEnum,
    Info: 'INFO' as TypeEnum,
    Interest: 'INTEREST' as TypeEnum,
    InternalTransfer: 'INTERNAL_TRANSFER' as TypeEnum,
    Investment: 'INVESTMENT' as TypeEnum,
    Margin: 'MARGIN' as TypeEnum,
    Payment: 'PAYMENT' as TypeEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as TypeEnum,
    ProductUnsubscription: 'PRODUCT_UNSUBSCRIPTION' as TypeEnum,
    Repo: 'REPO' as TypeEnum,
    Revenue: 'REVENUE' as TypeEnum,
    Reversal: 'REVERSAL' as TypeEnum,
    SecurityTransfer: 'SECURITY_TRANSFER' as TypeEnum,
    Sell: 'SELL' as TypeEnum,
    Tax: 'TAX' as TypeEnum,
    Transfer: 'TRANSFER' as TypeEnum,
    Unknown: 'UNKNOWN' as TypeEnum,
    VirtualSecurityTransfer: 'VIRTUAL_SECURITY_TRANSFER' as TypeEnum,
    Withdrawal: 'WITHDRAWAL' as TypeEnum,
  };
}
