/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface PrivateAssetDocumentDTO {
  contentType?: string;
  cover?: boolean;
  createdAt?: string;
  createdBy?: string;
  documentType: PrivateAssetDocumentDTO.DocumentTypeEnum;
  fileName?: string;
  id?: string;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace PrivateAssetDocumentDTO {
  export type DocumentTypeEnum =
    | 'ASSET_PHOTO'
    | 'OTHER'
    | 'PURCHASE_CONTRACT'
    | 'SALES_CONTRACT'
    | 'VALUE_ESTIMATION';
  export const DocumentTypeEnum = {
    AssetPhoto: 'ASSET_PHOTO' as DocumentTypeEnum,
    Other: 'OTHER' as DocumentTypeEnum,
    PurchaseContract: 'PURCHASE_CONTRACT' as DocumentTypeEnum,
    SalesContract: 'SALES_CONTRACT' as DocumentTypeEnum,
    ValueEstimation: 'VALUE_ESTIMATION' as DocumentTypeEnum,
  };
}
