/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyDTO } from './company-dto';

export interface AccountCompanyDTO {
  accountId?: string;
  allowedTransactionTypes?: Array<AccountCompanyDTO.AllowedTransactionTypesEnum>;
  company?: CompanyDTO;
  number?: string;
  relationStatus?: AccountCompanyDTO.RelationStatusEnum;
  suspendedDate?: string;
  workflowStatus?: AccountCompanyDTO.WorkflowStatusEnum;
  workflowStatusReason?: string;
}
export namespace AccountCompanyDTO {
  export type AllowedTransactionTypesEnum =
    | 'ADJUSTMENT'
    | 'BLOCK'
    | 'BOND_REDEMPTION'
    | 'BUY'
    | 'CASH_TRANSFER'
    | 'CROSS_TRADE'
    | 'CUSTOM'
    | 'DECREASE_CLOSE_DEPOSIT'
    | 'DECREASE_CLOSE_LOAN'
    | 'EXERCISE_OPTION'
    | 'EXPIRE_OPTION'
    | 'FEE'
    | 'FX'
    | 'FX_PRODUCT'
    | 'INCOME'
    | 'INCREASE_DEPOSIT'
    | 'INCREASE_LOAN'
    | 'INTEREST'
    | 'INTERNAL_PAYMENT'
    | 'INTERNAL_SWITCH'
    | 'INTERNAL_TRANSFER'
    | 'INVESTMENT'
    | 'ISIN_CHANGE'
    | 'MARGIN'
    | 'OPEN_DEPOSIT'
    | 'OPEN_LOAN'
    | 'PRODUCT_SUBSCRIPTION'
    | 'PRODUCT_UNSUBSCRIPTION'
    | 'REPO'
    | 'REVERSAL'
    | 'SECURITY_TRANSFER'
    | 'SELL'
    | 'SPIN_OFF'
    | 'SPLIT'
    | 'TAX'
    | 'TRANSFER'
    | 'VIRTUAL_SECURITY_TRANSFER'
    | 'WITHDRAWAL';
  export const AllowedTransactionTypesEnum = {
    Adjustment: 'ADJUSTMENT' as AllowedTransactionTypesEnum,
    Block: 'BLOCK' as AllowedTransactionTypesEnum,
    BondRedemption: 'BOND_REDEMPTION' as AllowedTransactionTypesEnum,
    Buy: 'BUY' as AllowedTransactionTypesEnum,
    CashTransfer: 'CASH_TRANSFER' as AllowedTransactionTypesEnum,
    CrossTrade: 'CROSS_TRADE' as AllowedTransactionTypesEnum,
    Custom: 'CUSTOM' as AllowedTransactionTypesEnum,
    DecreaseCloseDeposit: 'DECREASE_CLOSE_DEPOSIT' as AllowedTransactionTypesEnum,
    DecreaseCloseLoan: 'DECREASE_CLOSE_LOAN' as AllowedTransactionTypesEnum,
    ExerciseOption: 'EXERCISE_OPTION' as AllowedTransactionTypesEnum,
    ExpireOption: 'EXPIRE_OPTION' as AllowedTransactionTypesEnum,
    Fee: 'FEE' as AllowedTransactionTypesEnum,
    Fx: 'FX' as AllowedTransactionTypesEnum,
    FxProduct: 'FX_PRODUCT' as AllowedTransactionTypesEnum,
    Income: 'INCOME' as AllowedTransactionTypesEnum,
    IncreaseDeposit: 'INCREASE_DEPOSIT' as AllowedTransactionTypesEnum,
    IncreaseLoan: 'INCREASE_LOAN' as AllowedTransactionTypesEnum,
    Interest: 'INTEREST' as AllowedTransactionTypesEnum,
    InternalPayment: 'INTERNAL_PAYMENT' as AllowedTransactionTypesEnum,
    InternalSwitch: 'INTERNAL_SWITCH' as AllowedTransactionTypesEnum,
    InternalTransfer: 'INTERNAL_TRANSFER' as AllowedTransactionTypesEnum,
    Investment: 'INVESTMENT' as AllowedTransactionTypesEnum,
    IsinChange: 'ISIN_CHANGE' as AllowedTransactionTypesEnum,
    Margin: 'MARGIN' as AllowedTransactionTypesEnum,
    OpenDeposit: 'OPEN_DEPOSIT' as AllowedTransactionTypesEnum,
    OpenLoan: 'OPEN_LOAN' as AllowedTransactionTypesEnum,
    ProductSubscription: 'PRODUCT_SUBSCRIPTION' as AllowedTransactionTypesEnum,
    ProductUnsubscription: 'PRODUCT_UNSUBSCRIPTION' as AllowedTransactionTypesEnum,
    Repo: 'REPO' as AllowedTransactionTypesEnum,
    Reversal: 'REVERSAL' as AllowedTransactionTypesEnum,
    SecurityTransfer: 'SECURITY_TRANSFER' as AllowedTransactionTypesEnum,
    Sell: 'SELL' as AllowedTransactionTypesEnum,
    SpinOff: 'SPIN_OFF' as AllowedTransactionTypesEnum,
    Split: 'SPLIT' as AllowedTransactionTypesEnum,
    Tax: 'TAX' as AllowedTransactionTypesEnum,
    Transfer: 'TRANSFER' as AllowedTransactionTypesEnum,
    VirtualSecurityTransfer: 'VIRTUAL_SECURITY_TRANSFER' as AllowedTransactionTypesEnum,
    Withdrawal: 'WITHDRAWAL' as AllowedTransactionTypesEnum,
  };
  export type RelationStatusEnum = 'ACTIVE' | 'CLOSED' | 'DEACTIVATED' | 'DRAFT';
  export const RelationStatusEnum = {
    Active: 'ACTIVE' as RelationStatusEnum,
    Closed: 'CLOSED' as RelationStatusEnum,
    Deactivated: 'DEACTIVATED' as RelationStatusEnum,
    Draft: 'DRAFT' as RelationStatusEnum,
  };
  export type WorkflowStatusEnum =
    | 'APPROVED_BY_COMPLIANCE'
    | 'APPROVED_BY_EXECUTIVE_DIRECTORS'
    | 'ON_COMPLIANCE_REVIEW'
    | 'PREPARING_FOR_REVIEW'
    | 'REJECTED'
    | 'REVIEWED_NEED_CORRECTIONS'
    | 'SUSPENDED';
  export const WorkflowStatusEnum = {
    ApprovedByCompliance: 'APPROVED_BY_COMPLIANCE' as WorkflowStatusEnum,
    ApprovedByExecutiveDirectors: 'APPROVED_BY_EXECUTIVE_DIRECTORS' as WorkflowStatusEnum,
    OnComplianceReview: 'ON_COMPLIANCE_REVIEW' as WorkflowStatusEnum,
    PreparingForReview: 'PREPARING_FOR_REVIEW' as WorkflowStatusEnum,
    Rejected: 'REJECTED' as WorkflowStatusEnum,
    ReviewedNeedCorrections: 'REVIEWED_NEED_CORRECTIONS' as WorkflowStatusEnum,
    Suspended: 'SUSPENDED' as WorkflowStatusEnum,
  };
}
