import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// TODO Ref удалить
@Pipe({
  name: 'typedFormGroup',
})
export class TypedFormGroupPipe implements PipeTransform {
  public transform(value: unknown): UntypedFormGroup {
    return value as UntypedFormGroup;
  }
}
