import { Directive, TemplateRef } from '@angular/core';

export interface SelectHeaderDefContext {
  //$implicit: any;
}

@Directive({
  selector: '[appSelectHeaderDef]',
})
export class SelectHeaderDefDirective {
  constructor(public template: TemplateRef<SelectHeaderDefContext>) {}

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  static ngTemplateContextGuard(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dir: SelectHeaderDefDirective,
    ctx: unknown,
  ): ctx is SelectHeaderDefContext {
    return true;
  }
}
