import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, combineLatest, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseObject } from '@shared/base/base-object';
import { UploaderService } from '@shared/helpers/uploader.service';
import { FileData } from '@ui/uploader/uploader.types';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploaderComponent<FileType extends File | string>
  extends BaseObject
  implements OnInit
{
  @Input() public name: string;
  @Input() public format: 'file' | 'base64' = 'file';
  @Input() public multiple: boolean = true;
  @Input() public accept: string;

  @Input() public set clickable(value: boolean) {
    if (this._clickable !== value) {
      this._clickable = value;

      if (!value) {
        this.stopLisenClick$.next(true);
        return;
      }

      if (value) {
        this.startLisenToClick();
      }
    }
  }

  public get clickable(): boolean {
    return this._clickable;
  }

  @Output() public changeData = new EventEmitter<FileData<FileType>[]>();

  @ViewChild('fileInput') public input: ElementRef<HTMLInputElement>;

  private _clickable: boolean = true;
  private stopLisenClick$ = new Subject<boolean>();

  constructor(
    private uploaderService: UploaderService,
    private el: ElementRef<HTMLElement>,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.clickable) {
      this.startLisenToClick();
    }
  }

  private startLisenToClick(): void {
    fromEvent(this.el.nativeElement, 'click')
      .pipe(takeUntil(this.stopLisenClick$), takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        const target = event.target as Node;

        if (target.nodeName !== 'INPUT') {
          this.click();
        }
      });
  }

  public _onFileChanged(fileEvent: Event): void {
    const allFiles = Object.values((fileEvent.target as HTMLInputElement).files as FileList);

    switch (this.format) {
      case 'base64':
        combineLatest(allFiles.map((file) => this.uploaderService.fileToBase64(file)))
          .pipe(takeUntil(this.destroy$))
          .subscribe((filesToUpload) => {
            (this.changeData as EventEmitter<FileData<string>[]>).emit(filesToUpload);
          });
        break;

      default:
        (this.changeData as EventEmitter<FileData<File>[]>).emit(
          allFiles.map((file) => ({ name: file.name, file })),
        );
        break;
    }
  }

  public setFiles(files: FileData<FileType>[]): void {
    const input = this.input.nativeElement;
    const dataTransfer = new DataTransfer();

    files.forEach((file) => dataTransfer.items.add(new File([file.file], file.name)));
    input.files = dataTransfer.files;
  }

  public reset(): void {
    this.setFiles([]);
    this.changeData.emit([]);
  }

  public click(): void {
    this.input.nativeElement.click();
  }
}
