import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DateHelper } from '@shared/helpers/date-helper.service';
import { AppDatePipe } from '@shared/pipes/date.pipe';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { AppInjector } from 'app/config/app-injector';
import { DEFAULT_DATE_FORMATS, DEFAULT_DATE_INPUT_FORMAT } from './input.config';

@Injectable()
export class InputDateAdapter extends NativeDateAdapter {
  private DATE_PIPE = new AppDatePipe();
  private dateHelper: DateHelper;
  private userSettingsState: UserSettingsState;

  constructor(
    @Inject(MAT_DATE_LOCALE) public matDateLocale: string,
    platform: Platform,
  ) {
    super(matDateLocale, platform);

    this.dateHelper = AppInjector.Injector.get(DateHelper);
    this.userSettingsState = AppInjector.Injector.get(UserSettingsState);
  }

  public deserialize(date: string | Date): Date | null {
    const result = this.dateHelper.parse(date);

    return result || super.deserialize(date);
  }

  public parse(date: string): Date | null {
    const result = this.dateHelper.parse(date, this.userSettingsState.getDateFormat());

    return result;
  }

  public format(date: Date, displayFormat: string): string {
    switch (displayFormat) {
      case DEFAULT_DATE_INPUT_FORMAT:
        return this.DATE_PIPE.transform(this.dateHelper.parse(date));

      case DEFAULT_DATE_FORMATS.display.monthYearLabel:
        return this.DATE_PIPE.transform(this.dateHelper.parse(date));

      default:
        return super.format(date, displayFormat);
    }
  }
}
