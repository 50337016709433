/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustodianTinyDTO } from './custodian-tiny-dto';

export interface BankAccountAndAccountDTO {
  accountId?: string;
  accountName?: string;
  accountStatus?: BankAccountAndAccountDTO.AccountStatusEnum;
  baClientType?: BankAccountAndAccountDTO.BaClientTypeEnum;
  baCompType?: BankAccountAndAccountDTO.BaCompTypeEnum;
  clientNumber?: string;
  custodian?: CustodianTinyDTO;
  externalName?: string;
  id?: string;
  mainBankAccount?: boolean;
  name?: string;
  omnibusId?: string;
  retail?: boolean;
  riskLevel?: BankAccountAndAccountDTO.RiskLevelEnum;
  simpleAccountStatus?: BankAccountAndAccountDTO.SimpleAccountStatusEnum;
  status?: BankAccountAndAccountDTO.StatusEnum;
  targetUsages?: Array<BankAccountAndAccountDTO.TargetUsagesEnum>;
}
export namespace BankAccountAndAccountDTO {
  export type AccountStatusEnum =
    | 'ELIGIBLE_COUNTERPARTY'
    | 'PER_REQUEST_PROFESSIONAL'
    | 'PER_SE_PROFESSIONAL'
    | 'RETAIL';
  export const AccountStatusEnum = {
    EligibleCounterparty: 'ELIGIBLE_COUNTERPARTY' as AccountStatusEnum,
    PerRequestProfessional: 'PER_REQUEST_PROFESSIONAL' as AccountStatusEnum,
    PerSeProfessional: 'PER_SE_PROFESSIONAL' as AccountStatusEnum,
    Retail: 'RETAIL' as AccountStatusEnum,
  };
  export type BaClientTypeEnum = 'BANK' | 'CARD' | 'PR_ASSETS' | 'VIRTUAL' | 'VIRTUAL_TTCA';
  export const BaClientTypeEnum = {
    Bank: 'BANK' as BaClientTypeEnum,
    Card: 'CARD' as BaClientTypeEnum,
    PrAssets: 'PR_ASSETS' as BaClientTypeEnum,
    Virtual: 'VIRTUAL' as BaClientTypeEnum,
    VirtualTtca: 'VIRTUAL_TTCA' as BaClientTypeEnum,
  };
  export type BaCompTypeEnum = 'OMNIBUS' | 'PROP' | 'TTCA';
  export const BaCompTypeEnum = {
    Omnibus: 'OMNIBUS' as BaCompTypeEnum,
    Prop: 'PROP' as BaCompTypeEnum,
    Ttca: 'TTCA' as BaCompTypeEnum,
  };
  export type RiskLevelEnum = 'HIGH_RISK' | 'LOW_RISK' | 'NORMAL_RISK';
  export const RiskLevelEnum = {
    HighRisk: 'HIGH_RISK' as RiskLevelEnum,
    LowRisk: 'LOW_RISK' as RiskLevelEnum,
    NormalRisk: 'NORMAL_RISK' as RiskLevelEnum,
  };
  export type SimpleAccountStatusEnum = 'Active' | 'Deactivated';
  export const SimpleAccountStatusEnum = {
    Active: 'Active' as SimpleAccountStatusEnum,
    Deactivated: 'Deactivated' as SimpleAccountStatusEnum,
  };
  export type StatusEnum = 'Active' | 'Closed' | 'Deactivated' | 'Non_active';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Closed: 'Closed' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
    NonActive: 'Non_active' as StatusEnum,
  };
  export type TargetUsagesEnum = 'CASH_HOLDING' | 'DMA' | 'OTC';
  export const TargetUsagesEnum = {
    CashHolding: 'CASH_HOLDING' as TargetUsagesEnum,
    Dma: 'DMA' as TargetUsagesEnum,
    Otc: 'OTC' as TargetUsagesEnum,
  };
}
