import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgComponent } from './svg.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SvgComponent],
  exports: [SvgComponent],
})
export class SvgModule {}
