import { Injectable } from '@angular/core';
import { Messaging, getMessaging, getToken, onMessage } from '@firebase/messaging';
import { getApps, initializeApp } from 'firebase/app';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Env } from '@environments/environment.type';
import { FirebaseMessage } from 'app/notifications/notifications.types';

@Injectable({
  providedIn: 'root',
})
export class FirebaseMessagingService {
  public message$ = new BehaviorSubject<FirebaseMessage>(null);
  public token$ = new BehaviorSubject<string>(null);
  private messaging: Messaging;

  constructor() {
    this.initializeMessaging();
  }

  private initializeMessaging(): void {
    try {
      const apps = getApps();
      if (apps.length > 1) {
        this.messaging = getMessaging(apps[0]);
      } else {
        const app = initializeApp(environment.firebaseConfig);
        this.messaging = getMessaging(app);
      }
    } catch (e) {
      console.error(
        "Firebase initializeApp error: probably you're trying to use it not over https",
        e,
      );
    }
  }

  public startListenToMessages(): void {
    from(Notification.requestPermission())
      .pipe(
        filter((permission) => permission === 'granted'),
        switchMap(() => this.registerServiceWorker()),
        switchMap((worker) =>
          getToken(this.messaging, {
            serviceWorkerRegistration: worker,
            vapidKey: environment.firebaseConfig.vapidKey,
          }),
        ),
        tap((token) => this.token$.next(token)),
      )
      .subscribe(() => {
        onMessage(this.messaging, (payload) => this.message$.next(payload as FirebaseMessage));
      });
  }

  private registerServiceWorker(): Observable<ServiceWorkerRegistration> {
    // official ServiceWorker Firebase Guid (https://firebase.google.com/docs/cloud-messaging/js/client#web-version-9_1)
    // see ServiceWorker example code and registration (https://github.com/firebase/quickstart-js/tree/master/messaging)
    // logs ServiceWorker in chrome://serviceworker-internals (find and click inspect)
    return from(
      navigator.serviceWorker.register('./firebase-messaging-sw.js', {
        scope: 'firebase-cloud-messaging-push-scope',
      }),
    ).pipe(
      tap((worker) => {
        this.sendConfigToServiceWorker(environment.firebaseConfig, worker);
      }),
    );
  }

  private sendConfigToServiceWorker(
    firebaseConfig: Env['firebaseConfig'],
    worker: ServiceWorkerRegistration,
  ): void {
    if (worker.active) {
      worker.active.postMessage({ firebaseConfig });
    } else {
      worker.installing.addEventListener('onstatechange', () => {
        worker.active.postMessage({ firebaseConfig });
      });
    }
  }
}
