import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
})
export class KebabCasePipe implements PipeTransform {
  public transform(str: string): string {
    if (!str) {
      return null;
    }

    // Проверка SNAKE_UPPERCASE строки из нескольких слов
    if (str.includes('_')) {
      return str.toLowerCase().split('_').join('-');
    }

    // Проверка обычной строки из нескольких слов
    if (str.includes(' ')) {
      return str.toLowerCase().split(' ').join('-');
    }

    const strings = str.split(/(?=[A-Z])/g);
    // Проверка обычной строки из одного слова и строки, состоящей из одного UPPERCASE слова, т.к. у него нет символа _
    if (strings.length === str.length || strings.length < 2) {
      return str.toLowerCase();
    }

    // CamelCase строка по-умолчанию
    return str
      .split(/(?=[A-Z])/g)
      .join('-')
      .toLowerCase();
  }
}
