import { Pipe, PipeTransform } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';

@Pipe({
  name: 'appClone',
  pure: false,
})
export class ClonePipe implements PipeTransform {
  public transform<T>(value: T): T {
    return value ? cloneDeep(value) : value;
  }
}
