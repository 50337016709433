import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { environment } from 'environments/environment';

export enum SiteName {
  Reluna = 'reluna',
  Lwam = 'lwam',
  Qsf = 'qsf',
}

@Injectable({
  providedIn: 'root',
})
export class SettingsState {
  private _apiPath: string;
  private _frontPath: string;
  private _wsPath: string;
  private _metaWsPath: string;
  private _metaApiPath: string;

  public siteName$ = new BehaviorSubject<SiteName>(null);
  public storagePath: string;

  private readonly allowedSiteNames = Object.values(SiteName);

  constructor(private http: HttpClient) {
    this._apiPath = `${location.origin}${environment.apiPath}`;
    this._frontPath = location.origin;

    if (environment.enabledProdMode) {
      const isHttps = location.protocol.startsWith('https');

      this._wsPath = `${isHttps ? 'wss' : 'ws'}://${location.host}${environment.apiPath}${
        environment.wsPath
      }`;
      this._metaWsPath = this._frontPath;
      this._metaApiPath = this._apiPath;
    } else {
      this._wsPath = environment.wsPath;
      this._metaWsPath = environment.metaWsPath;
      this._metaApiPath = this._apiPath;
    }

    this.storagePath = `${this._apiPath}/storage/`;

    // LOCALHOST: Нужно только для коннекта к локальному сервису меты
    // this._metaWsPath = 'http://localhost:3000';
    // this._metaApiPath = 'http://localhost:3000';
  }

  public get apiPath(): string {
    return this._apiPath;
  }

  public get frontPath(): string {
    return this._frontPath;
  }

  public get wsPath(): string {
    return this._wsPath;
  }

  public get metaApiPath(): string {
    return this._metaApiPath;
  }

  public get metaWsPath(): string {
    return this._metaWsPath;
  }

  public get version(): string {
    return localStorage.getItem(LocalStorageConstants.Version);
  }

  public set version(value: string) {
    localStorage.setItem(LocalStorageConstants.Version, value);
  }

  public get siteName(): SiteName {
    return this.siteName$.value;
  }

  public loadSiteName(): Observable<SiteName> {
    return this.http.get<{ name: SiteName }>(this.frontPath + '/site').pipe(
      map((result) =>
        result?.name
          ? this.allowedSiteNames.find((siteName) => siteName === result.name) || SiteName.Reluna
          : SiteName.Reluna,
      ),
      catchError(() => of(SiteName.Reluna)),
      tap((site) => {
        this.siteName$.next(site);
      }),
    );
  }
}
