import { MessagePayload } from '@firebase/messaging';
import { Observable } from 'rxjs';
import { PushNotificationDTO } from '@shared/dto/notification/models';

export enum NotificationType {
  Progress = 'progress',
  Success = 'success',
  Error = 'error',
}

export enum NotificationPosition {
  CenterCenter = 'toast-center-center',
  TopCenter = 'toast-top-center',
  BottomCenter = 'toast-bottom-center',
  TopFullWidth = 'toast-top-full-width',
  BottomFullWidth = 'toast-bottom-full-width',
  TopLeft = 'toast-top-left',
  TopRight = 'toast-top-right',
  BottomRight = 'toast-bottom-right',
  BottomLeft = 'toast-bottom-left',
}

export interface NotificationOptions {
  timeOut?: number;
  disableTimeOut?: boolean;
  position?: NotificationPosition;
}

export interface NotificationData {
  id: number;
  afterOpened$: Observable<unknown>;
  afterClosed$: Observable<unknown>;
}

export interface FirebaseMessage extends Omit<MessagePayload, 'notification'> {
  notification: {
    title: string;
    body: string;
    parameters: { [key: string]: unknown };
    type: PushNotificationDTO.TypeEnum;
  };
}
