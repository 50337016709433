export enum PeriodEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTHS = 'MONTHS',
  THREE_MONTHS = 'THREE_MONTHS',
  YTD = 'YTD',
  YEAR = 'YEAR',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
  FIVE_YEARS = 'FIVE_YEARS',
  ALL_TIME = 'ALL_TIME',
  CUSTOM_PERIOD = 'CUSTOM_PERIOD',
}
