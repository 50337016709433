export type InputType =
  | 'text'
  | 'textarea'
  | 'number'
  | 'integer'
  | 'date'
  | 'date-range'
  | 'datetime'
  | 'datetime-range'
  | 'password';

export type CustomDatepickerType = [InputCustomTokens, number];
export type CustomDaterangePickerType = [InputCustomTokens, number, number];

export type InputControlType =
  | string
  | number
  | Date
  | [Date, Date]
  | CustomDatepickerType
  | CustomDaterangePickerType;

export type DateInputType = Extract<InputType, 'date' | 'date-range'>;

export interface InputDateRange {
  start: Date;
  end: Date;
}

export enum InputCustomTokens {
  ADD_DAY_TO_NOW = 'ADD_DAY_TO_NOW',
}

export type InputCustomDateType = 'date-to-step' | 'date-from-to-step';
