import { Pipe, PipeTransform } from '@angular/core';
import { ToCase } from '@shared/helpers/functions';

@Pipe({
  name: 'textTransform',
})
export class TextTransformPipe implements PipeTransform {
  public transform(
    value: string,
    fromCase: 'camel' | 'kebab' | 'snake' | 'common',
    toCase: 'common' = 'common',
    toSubCase?: 'upper' | 'lower',
  ): string {
    if (!value) {
      return value;
    }

    switch (fromCase) {
      case 'camel':
        switch (toCase) {
          case 'common':
          default: {
            let result = value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

            if (toSubCase) {
              result = ToCase(result, toSubCase);
            }

            return result;
          }
        }

      case 'kebab':
        switch (toCase) {
          case 'common':
          default:
            return value.replace(/(-+)/g, ' ');
        }

      case 'snake':
        switch (toCase) {
          case 'common':
          default: {
            let result = value.replace(/(_+)/g, ' ');

            if (toSubCase) {
              result = ToCase(result, toSubCase);
            }

            return result;
          }
        }

      case 'common':
        switch (toCase) {
          case 'common':
          default:
            return toSubCase ? ToCase(value, toSubCase) : value;
        }

      default:
        return value;
    }
  }
}
