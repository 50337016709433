import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY_REPLACER } from '@shared/constants/table/grid-constants';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '@shared/types/preferences';

@Pipe({
  name: 'appPercent',
})
export class PercentPipe implements PipeTransform {
  public transform(
    value: number,
    showSign: boolean = false,
    digitsInfo: string = '1.0-1',
    returnZeroValue: boolean = true,
    showMinusSign: boolean = true,
  ): string {
    value = !isFinite(value) || value === null ? 0 : value;

    if (value === 0 && !returnZeroValue) {
      return EMPTY_REPLACER;
    }

    const decimalSeparator = DECIMAL_SEPARATOR;
    const thousandsSeparator = THOUSAND_SEPARATOR;

    let moreZeroPrefix = '';
    let transformedValue = formatNumber(value, 'en-US', digitsInfo);

    if (Math.abs(value) < 0.1) {
      if (value === 0) {
        moreZeroPrefix = '';
      } else {
        moreZeroPrefix = value > 0 ? '<' : '>';
      }

      transformedValue = `0${decimalSeparator}1`;
    }

    let res = (transformedValue || '0').split(',').join(thousandsSeparator).replace('-', '');

    if (value === 0) {
      res = `0%`;
    } else {
      res = `${
        Math.sign(value) > 0
          ? moreZeroPrefix + (showSign ? '+' : '')
          : moreZeroPrefix + (showMinusSign ? '-' : '')
      }${res}%`;
    }

    return res;
  }
}
