import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingsState } from '@shared/states/settings.state';

@Injectable({
  providedIn: 'root',
})
export class DtoInfoService {
  constructor(
    private http: HttpClient,
    private settingService: SettingsState,
  ) {}

  /**
   * Получение опций для энамов по сущности филду
   * @param entityType
   * @param fieldName
   */
  public getEnumValues(entityType: string, fieldName: string): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.settingService.apiPath}/dto-info/enum-values`, {
        params: {
          type: entityType,
          fieldName,
        },
      })
      .pipe(catchError(() => of([])));
  }
}
