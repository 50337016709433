import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@shared/translates/translate.service';
import { AppInjector } from 'app/config/app-injector';

@Pipe({
  name: 'appBooleanToWord',
})
export class BooleanToWordPipe implements PipeTransform {
  public transform(value: boolean): string {
    const translateService = AppInjector.Injector.get(TranslateService);

    let ret = '-';

    switch (true) {
      case value === true:
        ret = translateService.get('common.yes');
        break;

      case value === false:
        ret = translateService.get('common.no');
        break;
    }

    return ret;
  }
}
