import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { SelectComponent } from './select.component';

@Component({
  selector: 'app-select-value',
  template: '<ng-content></ng-content>',
  styleUrls: ['./select-value.component.scss'],
  host: {
    class: 'app-select-value',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectValueComponent {
  @HostBinding('class.app-select-value--disabled') public get disabled(): boolean {
    return this.select.disabled;
  }

  @HostBinding('class.app-select-value--in-multiple')
  public get multiple(): boolean {
    return this.select.multiple;
  }

  constructor(private select: SelectComponent) {}
}
