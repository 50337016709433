import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isNotNull } from '@shared/base/core';
import { EMPTY_REPLACER } from '@shared/constants/table/grid-constants';
import { UserSettingsState } from '@shared/states/user-settings.state';
import { DECIMALS_COUNT_AUTO, THOUSAND_SEPARATOR } from '@shared/types/preferences';
import { AppInjector } from 'app/config/app-injector';

export const ROUND_DECIMALS_CONSTANTS = new Map<number, number>([
  [0, 1],
  [1, 10],
  [2, 100],
  [3, 1000],
  [4, 10000],
  [5, 100000],
  [6, 1000000],
]);

export const ROUND_DIGITS_INFO_CONSTANTS = new Map<number, string>([
  [0, '1.0-0'],
  [1, '1.0-1'],
  [2, '1.0-2'],
  [3, '1.0-3'],
  [4, '1.0-4'],
  [5, '1.0-5'],
  [6, '1.0-6'],
]);

export function GetNumberWithoutRound(value: number, decimalsCount: number): number {
  const n = ROUND_DECIMALS_CONSTANTS.get(decimalsCount);
  return Math.floor((value + Number.EPSILON) * n) / n;
}

export function GetNumberWithRound(value: number, decimalsCount: number): number {
  const n1 = ROUND_DECIMALS_CONSTANTS.get(decimalsCount);
  const n2 = ROUND_DECIMALS_CONSTANTS.get(decimalsCount + 1);

  let result = Number((Math.round((value + Number.EPSILON) * n2) / n2).toFixed(decimalsCount + 1));
  result = Number((Math.round((result + Number.EPSILON) * n1) / n1).toFixed(decimalsCount));

  return result;
}

export function GetNumberWithRoundV2(value: number, decimalsCount: number): number {
  const n1 = ROUND_DECIMALS_CONSTANTS.get(decimalsCount);

  const result = Number((Math.round((value + Number.EPSILON) * n1) / n1).toFixed(decimalsCount));

  return result;
}

export function GetSignLiteral(value: number, showSign: boolean): string {
  if (value < 0) {
    return '-';
  } else if (!showSign || !value) {
    return '';
  }

  return '+';
}

@Pipe({
  name: 'appNumber',
})
export class NumberPipe implements PipeTransform {
  private userSettingsState: UserSettingsState;

  constructor() {
    this.userSettingsState = AppInjector.Injector.get(UserSettingsState);
  }

  public transform(
    value: number,
    decimalsCount?: number,
    showSign: boolean = false,
    trailingZeros?: boolean,
  ): string {
    if (!isFinite(value)) {
      return EMPTY_REPLACER;
    }

    let _decimalsCount: number;

    if (isNotNull(decimalsCount) && decimalsCount !== DECIMALS_COUNT_AUTO) {
      _decimalsCount = decimalsCount;
    } else {
      _decimalsCount =
        decimalsCount === DECIMALS_COUNT_AUTO
          ? this.getDecimalsCountsByAutoLogic(value)
          : this.userSettingsState.preferences.decimalsCount;
    }

    value = GetNumberWithRound(value, _decimalsCount);

    const valueStr = formatNumber(
      value,
      'en-US',
      trailingZeros ? `1.${_decimalsCount}-${_decimalsCount}` : `1.0-${_decimalsCount}`,
    );

    let res = (valueStr || '0').split(',').join(THOUSAND_SEPARATOR).replace('-', '');

    const signLiteral = GetSignLiteral(value, showSign);

    res = signLiteral + res;

    return res;
  }

  private getDecimalsCountsByAutoLogic(value: number): number {
    const absValue = Math.abs(value);
    let decimalsCount: number;

    if (absValue < 0.000001) {
      decimalsCount = 0;
    } else if (absValue < 0.01) {
      decimalsCount = 5;
    } else if (absValue >= 1000) {
      decimalsCount = 0;
    } else if (absValue < 1000) {
      decimalsCount = this.userSettingsState.preferences.decimalsCount;
    }

    return decimalsCount;
  }
}
