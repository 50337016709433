<ng-container [ngSwitch]="true">
  <ng-container *ngSwitchCase="type === 'date' || type === 'datetime'">
    <mat-form-field *ngIf="!customDate; else showCustomDateField"
                    [hideRequiredMarker]="hideRequiredMarker"
                    [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible">
      <mat-label>{{ label }}</mat-label>
      <input matInput
             [readonly]="readonly"
             [appInputAutofocus]="autofocus"
             [formControl]="formControl"
             [matDatepicker]="datePicker"
             [min]="minDate"
             [max]="maxDate"
             [placeholder]="placeholder || ('common.select'|translate)"
             autocomplete="off"
             (focus)="_onFocus($event)"
             (blur)="_onBlur($event)">
      <mat-datepicker-toggle *ngIf="calendarIconBeforePosition"
                             matPrefix
                             [for]="datePicker">
        <app-svg matDatepickerToggleIcon
                 [icon]="'Calendar_24'"
                 [disabled]="disabled"
                 style="width: 24px;">
        </app-svg>
      </mat-datepicker-toggle>

      <mat-datepicker-toggle *ngIf="!calendarIconBeforePosition"
                             matSuffix
                             [for]="datePicker">
        <app-svg matDatepickerToggleIcon
                 [icon]="'Calendar_24'"
                 [disabled]="disabled"
                 style="width: 24px;">
        </app-svg>
      </mat-datepicker-toggle>

      <ng-container *ngIf="suffixDir"
                    matSuffix>
        <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
      </ng-container>

      <mat-datepicker #datePicker
                      [panelClass]="inputState.calendarClasses$|async"
                      [calendarHeaderComponent]="calendarHeader">
        <mat-datepicker-actions>
          <app-datepicker [datepicker]="datePicker"
                          [maxDate]="maxDate"
                          [minDate]="minDate"
                          (closeEvent)="datepickerClosedEvent.next($event)">
          </app-datepicker>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>

    <ng-template #showCustomDateField>
      <mat-form-field cdkOverlayOrigin
                      #customDateFieldTrigger="cdkOverlayOrigin"
                      [hideRequiredMarker]="hideRequiredMarker"
                      [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible">
        <mat-label>{{ label }}</mat-label>
        <input [formControl]="formControl"
               matInput
               [readonly]="true"
               [appInputAutofocus]="autofocus"
               type="text"
               [placeholder]="placeholder || 'common.enter'|translate"
               [attr.autocomplete]="autocomplete"
               (focus)="_onFocus($event)"
               (blur)="_onBlur($event)"
               appInputDateCustomTransform>
        <mat-datepicker-toggle *ngIf="calendarIconBeforePosition"
                               matPrefix
                               (click)="_customDatePickerOpened = !_customDatePickerOpened">
          <app-svg matDatepickerToggleIcon
                   [icon]="'Calendar_24'"
                   [disabled]="disabled"
                   style="width: 24px;">
          </app-svg>
        </mat-datepicker-toggle>

        <mat-datepicker-toggle *ngIf="!calendarIconBeforePosition"
                               matSuffix
                               (click)="_customDatePickerOpened = !_customDatePickerOpened">
          <app-svg matDatepickerToggleIcon
                   [icon]="'Calendar_24'"
                   [disabled]="disabled"
                   style="width: 24px;">
          </app-svg>
        </mat-datepicker-toggle>

        <ng-container *ngIf="suffixDir"
                      matSuffix>
          <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
        </ng-container>

        <ng-template cdkConnectedOverlay
                     [cdkConnectedOverlayPanelClass]="'app-custom-datepicker-panel'"
                     [cdkConnectedOverlayOrigin]="customDateFieldTrigger"
                     [cdkConnectedOverlayOpen]="_customDatePickerOpened"
                     (overlayOutsideClick)="_customDatePickerOpened = !_customDatePickerOpened">
          <app-custom-datepicker [selected]="(formControl | getCustomDatepickerControl).value"
                                 (closeEvent)="_onCustomDatepickerClose($event)">
          </app-custom-datepicker>
        </ng-template>
      </mat-form-field>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="type === 'date-range' || type === 'datetime-range'">
    <mat-form-field *ngIf="!customDate; else showCustomDateField"
                    [hideRequiredMarker]="hideRequiredMarker">
      <mat-label>{{ label }}</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker"
                            [formGroup]="_dateRangeGroup"
                            [min]="minDate"
                            [max]="maxDate">
        <input matStartDate
               [placeholder]="'common.start-date'|translate"
               formControlName="start">
        <input matEndDate
               [placeholder]="'common.end-date'|translate"
               formControlName="end">
      </mat-date-range-input>

      <mat-datepicker-toggle *ngIf="calendarIconBeforePosition"
                             matPrefix
                             [for]="dateRangePicker">
        <app-svg matDatepickerToggleIcon
                 [icon]="'Calendar_24'"
                 [disabled]="disabled"
                 style="width: 24px;">
        </app-svg>
      </mat-datepicker-toggle>

      <mat-datepicker-toggle *ngIf="!calendarIconBeforePosition"
                             matSuffix
                             [for]="dateRangePicker">
        <app-svg matDatepickerToggleIcon
                 [icon]="'Calendar_24'"
                 [disabled]="disabled"
                 style="width: 24px;">
        </app-svg>
      </mat-datepicker-toggle>

      <ng-container *ngIf="suffixDir"
                    matSuffix>
        <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
      </ng-container>

      <mat-date-range-picker #dateRangePicker
                             [panelClass]="inputState.calendarClasses$|async"
                             [calendarHeaderComponent]="calendarHeader">
        <mat-datepicker-actions>
          <app-daterange-picker [datepicker]="dateRangePicker"
                                [minDate]="minDate"
                                [maxDate]="maxDate"
                                [defaultPeriod]="defaultPeriod">
          </app-daterange-picker>
        </mat-datepicker-actions>
      </mat-date-range-picker>
    </mat-form-field>

    <ng-template #showCustomDateField>
      <mat-form-field cdkOverlayOrigin
                      #customDateFieldTrigger="cdkOverlayOrigin"
                      [hideRequiredMarker]="hideRequiredMarker"
                      [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible">
        <mat-label>{{ label }}</mat-label>
        <input [formControl]="formControl"
               matInput
               [readonly]="true"
               [appInputAutofocus]="autofocus"
               type="text"
               [placeholder]="placeholder || 'common.enter'|translate"
               [attr.autocomplete]="autocomplete"
               (focus)="_onFocus($event)"
               (blur)="_onBlur($event)"
               appInputDateCustomTransform>
        <mat-datepicker-toggle *ngIf="calendarIconBeforePosition"
                               matPrefix
                               (click)="_customDatePickerOpened = !_customDatePickerOpened">
          <app-svg matDatepickerToggleIcon
                   [icon]="'Calendar_24'"
                   [disabled]="disabled"
                   style="width: 24px;">
          </app-svg>
        </mat-datepicker-toggle>

        <mat-datepicker-toggle *ngIf="!calendarIconBeforePosition"
                               matSuffix
                               (click)="_customDatePickerOpened = !_customDatePickerOpened">
          <app-svg matDatepickerToggleIcon
                   [icon]="'Calendar_24'"
                   [disabled]="disabled"
                   style="width: 24px;">
          </app-svg>
        </mat-datepicker-toggle>

        <ng-container *ngIf="suffixDir"
                      matSuffix>
          <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
        </ng-container>

        <ng-template cdkConnectedOverlay
                     [cdkConnectedOverlayPanelClass]="'app-custom-datepicker-panel'"
                     [cdkConnectedOverlayOrigin]="customDateFieldTrigger"
                     [cdkConnectedOverlayOpen]="_customDatePickerOpened"
                     (overlayOutsideClick)="_customDatePickerOpened = !_customDatePickerOpened">
          <app-custom-daterange-picker [selected]="(formControl | getCustomDaterangePickerControl).value"
                                       [maxDate]="maxDate"
                                       (closeEvent)="_onCustomDaterangePickerClose($event)">
          </app-custom-daterange-picker>
        </ng-template>
      </mat-form-field>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="type === 'textarea'">
    <mat-form-field [hideRequiredMarker]="hideRequiredMarker"
                    [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible">
      <mat-label>{{ label }}</mat-label>
      <textarea [formControl]="formControl"
                matInput
                [readonly]="readonly"
                [appInputAutofocus]="autofocus"
                [placeholder]="placeholder || 'common.enter'|translate"
                [cdkTextareaAutosize]="textareaFillContent"
                autocomplete="off"
                (focus)="_onFocus($event)"
                (blur)="_onBlur($event)"
                (input)="_onInputText($event)">
      </textarea>
      <span *ngIf="prefixDir"
            matPrefix
            appResizeObserver
            (appResizeObserver.widthChange)="hasPrefix$.next(!!$event)">
        <ng-container *ngTemplateOutlet="prefixDir.template"></ng-container>
      </span>

      <ng-container *ngIf="suffixDir"
                    matSuffix>
        <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
      </ng-container>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="type === 'password'">
    <mat-form-field [hideRequiredMarker]="hideRequiredMarker"
                    [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible"
                    [class.--has-prefix]="hasPrefix$|async">
      <mat-label>{{ label }}</mat-label>
      <input [formControl]="formControl"
             matInput
             [readonly]="readonly"
             [appInputAutofocus]="autofocus"
             [attr.type]="type === 'password' ? _passwordType : type"
             [placeholder]="placeholder || 'common.enter'|translate"
             name="password"
             mask="P*"
             [validation]="maskValidation"
             [patterns]="passwordPatterns"
             [dropSpecialCharacters]="false"
             [attr.autocomplete]="autocomplete"
             (focus)="_onFocus($event)"
             (blur)="_onBlur($event)"
             (input)="_onInputText($event)">
      <span matSuffix
            style="cursor: pointer;"
            appResizeObserver
            (appResizeObserver.widthChange)="hasPrefix$.next(!!$event)"
            (click)="showPasswordAsDots = !showPasswordAsDots">
        <app-svg [icon]="showPasswordAsDots ? 'ClosedEye_24' : 'OpenEye_24'"></app-svg>
      </span>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="type === 'integer'">
    <mat-form-field [hideRequiredMarker]="hideRequiredMarker"
                    [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible"
                    [class.--has-prefix]="hasPrefix$|async">
      <mat-label>{{ label }}</mat-label>
      <input [formControl]="formControl"
             matInput
             [readonly]="readonly"
             [appInputAutofocus]="autofocus"
             type="text"
             [placeholder]="placeholder || 'common.enter'|translate"
             mask="separator.0"
             [validation]="maskValidation"
             [thousandSeparator]="thousandSeparator"
             [allowNegativeNumbers]="allowNegativeIntegers"
             (focus)="_onFocus($event)"
             (blur)="_onBlur($event)"
             (input)="_onInputNumber($event)"
             autocomplete="off">
      <span *ngIf="allowIntegerCounterButtons && _valueIsInteger"
            matPrefix
            class="app-input__btn table-body app-bg-10"
            [attr.disabled]="disabled"
            style="position: absolute;"
            appResizeObserver
            (appResizeObserver.widthChange)="hasPrefix$.next(!!$event)"
            (click)="_onDecrement($event)">
        -
      </span>
      <span *ngIf="allowIntegerCounterButtons && _valueIsInteger"
            matSuffix
            class="app-input__btn table-body app-bg-10"
            [attr.disabled]="disabled"
            (click)="_onIncrement($event)">
        +
      </span>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="type === 'number'">
    <mat-form-field [hideRequiredMarker]="hideRequiredMarker"
                    [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible"
                    [class.--has-prefix]="hasPrefix$|async">
      <mat-label>{{ label }}</mat-label>
      <input [formControl]="formControl"
             matInput
             [readonly]="readonly"
             [appInputAutofocus]="autofocus"
             type="text"
             [mask]="numberInputMask"
             [prefix]="maskPrefix"
             [validation]="maskValidation"
             [thousandSeparator]="thousandSeparator"
             [allowNegativeNumbers]="allowNegativeNumbers"
             [placeholder]="placeholder || 'common.enter'|translate"
             autocomplete="off"
             (focus)="_onFocus($event)"
             (blur)="_onBlur($event)"
             (input)="_onInputNumber($event)">
      <span *ngIf="prefixDir"
            matPrefix
            appResizeObserver
            (appResizeObserver.widthChange)="hasPrefix$.next(!!$event)">
        <ng-container *ngTemplateOutlet="prefixDir.template"></ng-container>
      </span>

      <ng-container *ngIf="suffixDir"
                    matSuffix>
        <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
      </ng-container>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <mat-form-field [hideRequiredMarker]="hideRequiredMarker"
                    [class.--has-required-marker]="!hideRequiredMarker && _requiredMarkerIsVisible"
                    [class.--has-prefix]="hasPrefix$|async">
      <mat-label>{{ label }}</mat-label>
      <input [formControl]="formControl"
             matInput
             [readonly]="readonly"
             [appInputAutofocus]="autofocus"
             [attr.type]="type"
             [placeholder]="placeholder || 'common.enter'|translate"
             [mask]="mask"
             [prefix]="maskPrefix"
             [validation]="maskValidation"
             placeHolderCharacter="_"
             [showMaskTyped]="true"
             [dropSpecialCharacters]="true"
             [attr.autocomplete]="autocomplete"
             (focus)="_onFocus($event)"
             (blur)="_onBlur($event)"
             (input)="_onInputText($event)">
      <span *ngIf="prefixDir"
            matPrefix
            appResizeObserver
            (appResizeObserver.widthChange)="hasPrefix$.next(!!$event)">
        <ng-container *ngTemplateOutlet="prefixDir.template"></ng-container>
      </span>

      <ng-container *ngIf="suffixDir"
                    matSuffix>
        <ng-container *ngTemplateOutlet="suffixDir.template"></ng-container>
      </ng-container>
    </mat-form-field>
  </ng-container>
</ng-container>

<div *ngIf="!!formControl?.errors"
     class="app-input-errors">
  <ng-content select="app-input-error"></ng-content>
</div>