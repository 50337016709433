/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankAccountTinyDTO } from './bank-account-tiny-dto';
import { CustodianDTO } from './custodian-dto';

export interface BankAccountDTO {
  accountId: string;
  accountName?: string;
  baClientType?: BankAccountDTO.BaClientTypeEnum;
  baCompType?: BankAccountDTO.BaCompTypeEnum;
  ccy?: string;
  closingDate?: string;
  createdAt?: string;
  createdBy?: string;
  custodian: CustodianDTO;
  externalName: string;
  iban?: string;
  id?: string;
  multyCcy?: boolean;
  name: string;
  openingDate: string;
  parent?: BankAccountTinyDTO;
  pendingStatus?: BankAccountDTO.PendingStatusEnum;
  retail?: boolean;
  sovaTradingType?: BankAccountDTO.SovaTradingTypeEnum;
  status: BankAccountDTO.StatusEnum;
  targetUsages?: Array<BankAccountDTO.TargetUsagesEnum>;
  unallocated?: boolean;
  updatedAt?: string;
  updatedBy?: string;
}
export namespace BankAccountDTO {
  export type BaClientTypeEnum = 'BANK' | 'CARD' | 'PR_ASSETS' | 'VIRTUAL' | 'VIRTUAL_TTCA';
  export const BaClientTypeEnum = {
    Bank: 'BANK' as BaClientTypeEnum,
    Card: 'CARD' as BaClientTypeEnum,
    PrAssets: 'PR_ASSETS' as BaClientTypeEnum,
    Virtual: 'VIRTUAL' as BaClientTypeEnum,
    VirtualTtca: 'VIRTUAL_TTCA' as BaClientTypeEnum,
  };
  export type BaCompTypeEnum = 'OMNIBUS' | 'PROP' | 'TTCA';
  export const BaCompTypeEnum = {
    Omnibus: 'OMNIBUS' as BaCompTypeEnum,
    Prop: 'PROP' as BaCompTypeEnum,
    Ttca: 'TTCA' as BaCompTypeEnum,
  };
  export type PendingStatusEnum =
    | 'APPROVED'
    | 'NEW'
    | 'PENDING_CLOSE_APPROVAL'
    | 'PENDING_DEACTIVATED_APPROVAL'
    | 'REJECTED';
  export const PendingStatusEnum = {
    Approved: 'APPROVED' as PendingStatusEnum,
    New: 'NEW' as PendingStatusEnum,
    PendingCloseApproval: 'PENDING_CLOSE_APPROVAL' as PendingStatusEnum,
    PendingDeactivatedApproval: 'PENDING_DEACTIVATED_APPROVAL' as PendingStatusEnum,
    Rejected: 'REJECTED' as PendingStatusEnum,
  };
  export type SovaTradingTypeEnum = 'CASS' | 'TTCA';
  export const SovaTradingTypeEnum = {
    Cass: 'CASS' as SovaTradingTypeEnum,
    Ttca: 'TTCA' as SovaTradingTypeEnum,
  };
  export type StatusEnum = 'Active' | 'Closed' | 'Deactivated' | 'Non_active';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Closed: 'Closed' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
    NonActive: 'Non_active' as StatusEnum,
  };
  export type TargetUsagesEnum = 'CASH_HOLDING' | 'DMA' | 'OTC';
  export const TargetUsagesEnum = {
    CashHolding: 'CASH_HOLDING' as TargetUsagesEnum,
    Dma: 'DMA' as TargetUsagesEnum,
    Otc: 'OTC' as TargetUsagesEnum,
  };
}
