import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { SiteInfoService } from '@api/site-info.service';
import { BaseObject } from '@shared/base/base-object';
import { isNotNull } from '@shared/base/core';
import { LayoutState } from 'app/layout/layout.state';
import { NotificationsService } from 'app/notifications/notifications.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-layout',
  },
})
export class LayoutComponent extends BaseObject implements OnInit {
  public footerData$ = this.siteInfoService.getByType('FOOTER');
  public needSubscription: boolean;

  // для отключения скролла после клика средней кнопкой мыши
  @HostListener('mousedown', ['$event'])
  public _onMousedown(e: MouseEvent): boolean {
    if (e.button === 1) {
      return false;
    } else {
      return true;
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public layoutState: LayoutState,
    private siteInfoService: SiteInfoService,
    private notificationsService: NotificationsService,
  ) {
    super();

    this.notificationsService.createPushNotificationsContainer();
    this.notificationsService.subscribeToPushNotifications();
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.changeOptions());

    this.changeOptions();
  }

  private changeOptions(): void {
    const currentRoute = this.getLastChild();

    currentRoute.data.pipe(take(1)).subscribe((data) => {
      this.notificationsService.openCookieAcceptDialog();

      if (data.isRoutedTableLeafNode) {
        const parentData = currentRoute.parent.snapshot.data;

        this.layoutState.showFixedControls$.next(
          isNotNull(parentData.showFixedControls) ? parentData.showFixedControls : true,
        );

        this.layoutState.showNotifications$.next(
          isNotNull(parentData.showNotifications) ? parentData.showNotifications : true,
        );
      }
    });
  }

  private getLastChild(): ActivatedRoute {
    let lastChild = this.activatedRoute.firstChild;

    while (lastChild.children && lastChild.children.length > 0) {
      lastChild = lastChild.firstChild;
    }

    return lastChild;
  }
}
