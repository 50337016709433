import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UploaderComponent } from './uploader.component';
import { FileData } from './uploader.types';

@Component({
  selector: 'app-drag-and-drop-uploader',
  templateUrl: './drag-and-drop-uploader.component.html',
  styleUrls: ['./drag-and-drop-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragAndDropUploaderComponent<FileType = unknown> implements OnInit {
  @HostBinding('class.--is-new-design') public _isNewDesign = false;

  @Input() public label: string;
  @Input() public description: string;
  @Input() public format: 'file' | 'base64' = 'file';
  @Input() public accept: string;
  @Input() public multiple: boolean = true;
  @Input() public dndPlaceHeight: string;
  @Input() public disabled: boolean = false;
  @Input() public errorMessage: string[];

  @Input({ required: true })
  public set files(value: FileData<FileType>[]) {
    this.files$.next(value);
  }

  @Input()
  public set isNewDesign(value: boolean) {
    this._isNewDesign = value;
  }

  public get isNewDesign(): boolean {
    return this._isNewDesign;
  }

  @Output() public changeFiles = new EventEmitter<FileData<FileType>[]>();

  public _trackByIndex = (index: number): number => index;
  public files$ = new BehaviorSubject<FileData<FileType>[]>([]);

  @ViewChild(UploaderComponent) public uploaderComponent: UploaderComponent<File>;

  constructor() {}

  public ngOnInit(): void {
    if (this.accept) {
      this.accept = this.accept.split('.').join(' ');
    }
  }

  public _onDeleteFile(event: MouseEvent, index: number): void {
    event.preventDefault();
    event.stopPropagation();

    this.files$.value.splice(index, 1);

    this.uploaderComponent.setFiles(this.files$.value as FileData<File>[]);
    this.changeFiles.emit(this.files$.value);
  }

  public _onChangeData(files: FileData<File | string>[]): void {
    if (this.disabled) {
      return;
    }

    if (this.accept) {
      const validFiles: FileData<FileType>[] = [];

      for (const file of files) {
        if (this.accept.includes(this.getFileExtensionFromName(file.name))) {
          validFiles.push(file as FileData<FileType>);
        }
      }

      this.files$.next(this.multiple ? [...this.files$.value, ...validFiles] : validFiles);
    } else {
      this.files$.next(
        this.multiple
          ? [...this.files$.value, ...(files as FileData<FileType>[])]
          : (files as FileData<FileType>[]),
      );
    }

    this.changeFiles.emit(this.files$.value);
  }

  public _onDndClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName !== 'INPUT') {
      this.uploaderComponent?.click();
    }
  }

  private getFileExtensionFromName(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
  }
}
