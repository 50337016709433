import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getCcySvgSrc',
})
export class GetCcySvgSrcPipe implements PipeTransform {
  public transform(ccy: string): string {
    let src: string;

    switch (ccy) {
      case 'USD':
        src = '/assets/img/flags/US.svg';
        break;

      case 'EUR':
        src = '/assets/img/flags/EU.svg';
        break;

      case 'GBP':
      case 'GBp':
        src = '/assets/img/flags/GB.svg';
        break;

      case 'RUB':
        src = '/assets/img/flags/RU.svg';
        break;

      case 'AED':
        src = '/assets/img/flags/AE.svg';
        break;

      case 'AUD':
        // TODO https://logo.twelvedata.com/forex/aud.png
        src = '/assets/img/flags/AU.svg';
        break;

      case 'CAD':
        src = '/assets/img/flags/CA.svg';
        break;

      case 'CHF':
        src = '/assets/img/flags/CH.svg';
        break;

      case 'CNY':
        src = '/assets/img/flags/CN.svg';
        break;

      case 'DKK':
        src = '/assets/img/flags/DK.svg';
        break;

      case 'HKD':
        src = '/assets/img/flags/HK.svg';
        break;

      case 'JPY':
        src = '/assets/img/flags/JP.svg';
        break;

      case 'MXN':
        src = '/assets/img/flags/MX.svg';
        break;

      case 'NOK':
        src = '/assets/img/flags/NO.svg';
        break;

      case 'NZD':
        // TODO https://logo.twelvedata.com/forex/nzd.png
        src = '/assets/img/flags/NZ.svg';
        break;

      case 'PLN':
        src = '/assets/img/flags/PL.svg';
        break;

      case 'SEK':
        src = '/assets/img/flags/SE.svg';
        break;

      case 'SGD':
        src = '/assets/img/flags/SG.svg';
        break;

      case 'TRY':
        src = '/assets/img/flags/TR.svg';
        break;

      case 'ZAR':
        src = '/assets/img/flags/ZA.svg';
        break;

      default:
        break;
    }

    return src;
  }
}
