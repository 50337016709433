<ng-container *ngIf="data"
              @contentAnimation>
  <ng-container *ngIf="data.formatType === 'TEXT'; else showHtml">
    {{data.text}}
  </ng-container>

  <ng-template #showHtml>
    <div [style.height]="showExpander && !expanded ? '20px' : 'auto'"
         [innerHTML]="data.text | safeHtml">
    </div>

    <app-icon-button *ngIf="showExpander"
                     (click)="expanded = !expanded"
                     class="app-bg-main">
      <span class="button-text-2 app-text-color-50">
        {{ expanded ? ('common.hide'|translate) : ('common.show'|translate) }}
      </span>

      <app-svg [icon]="'ArrowShortDown_16'"
               class="app-expander"
               [class.app-expander--up]="expanded">
      </app-svg>
    </app-icon-button>
  </ng-template>
</ng-container>