import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn } from '@angular/router';
import { capitalizeString } from '@shared/base/core';
import { SeoRouteData, SeoService } from '@shared/helpers/seo.service';
import { TranslateService } from '@shared/translates/translate.service';

export const SeoCanActivateGuard: CanActivateFn = (route) => {
  applySeoData(route);
  return true;
};

export const SeoCanActivateChildGuard: CanActivateChildFn = (route) => {
  applySeoData(route);
  return true;
};

function applySeoData(route: ActivatedRouteSnapshot): void {
  const seoService = inject(SeoService);
  const translateService = inject(TranslateService);

  const seo = route.data.seo as SeoRouteData;

  if (seo) {
    if (seo.pageTitle) {
      const title = translateService.get(seo.pageTitle);
      seoService.setPageTitle(capitalizeString(title));

      seoService.setMetaTags([
        {
          property: 'og:title',
          content: title,
        },
      ]);
    }

    if (seo.description) {
      seoService.setMetaTags([
        { name: 'description', content: seo.description },
        {
          property: 'og:description',
          content: seo.description,
        },
      ]);
    }
  }
}
