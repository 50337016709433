import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';

const PRESERVE_QUERY_PARAMS = ['portfolioId', 'accountId'];

export const QueryParamsSaverCanActivateChildGuard: CanActivateChildFn = (route, state) => {
  const searchParams = new URLSearchParams(window.location.search);
  let preservingQueryParams = {};
  let needPreserve: boolean = false;

  for (const key of PRESERVE_QUERY_PARAMS) {
    if (searchParams.get(key) && !route.queryParams[key]) {
      needPreserve = true;
      preservingQueryParams[key] = searchParams.get(key);
    }
  }

  if (needPreserve) {
    preservingQueryParams = { ...preservingQueryParams, ...route.queryParams };
    const urlTree = inject(Router).parseUrl(state.url);
    urlTree.queryParams = preservingQueryParams;

    return urlTree;
  }

  return true;
};
