import { ErrorHandler, Injectable } from '@angular/core';

type ErrorInside = { error: Error };

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}

  public handleError<ErrorType = Error | ErrorInside>(error: ErrorType): ErrorType {
    console.error('GlobalErrorHandler:', (error as ErrorInside)?.error || error);
    // TODO вурнуть google analytics когда будет нужен (GoogleAnalytics service)
    // this.googleAnalytics.sendError(error.name, error?.stack || error.message, error?.status);
    return error;
  }
}
