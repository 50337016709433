/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FieldMetaAssetTypeDTO } from './field-meta-asset-type-dto';

export interface FieldMetaDTO {
  bloombergCode?: string;
  description?: string;
  editable?: boolean;
  fieldType?: FieldMetaDTO.FieldTypeEnum;
  format?: string;
  name?: string;
  tags?: Array<string>;
  type?: FieldMetaDTO.TypeEnum;
  types?: Array<FieldMetaAssetTypeDTO>;
  updateFrequency?: number;
}
export namespace FieldMetaDTO {
  export type FieldTypeEnum = 'DAILY' | 'NORMAL' | 'STATIC';
  export const FieldTypeEnum = {
    Daily: 'DAILY' as FieldTypeEnum,
    Normal: 'NORMAL' as FieldTypeEnum,
    Static: 'STATIC' as FieldTypeEnum,
  };
  export type TypeEnum =
    | 'ARRAY'
    | 'BOOLEAN'
    | 'DATE'
    | 'DOUBLE'
    | 'ENUM'
    | 'ENUM_ARRAY'
    | 'FLOAT'
    | 'INT'
    | 'LONG'
    | 'MAP'
    | 'OBJECT'
    | 'STRING'
    | 'TIMESTAMP'
    | 'UUID';
  export const TypeEnum = {
    Array: 'ARRAY' as TypeEnum,
    Boolean: 'BOOLEAN' as TypeEnum,
    Date: 'DATE' as TypeEnum,
    Double: 'DOUBLE' as TypeEnum,
    Enum: 'ENUM' as TypeEnum,
    EnumArray: 'ENUM_ARRAY' as TypeEnum,
    Float: 'FLOAT' as TypeEnum,
    Int: 'INT' as TypeEnum,
    Long: 'LONG' as TypeEnum,
    Map: 'MAP' as TypeEnum,
    Object: 'OBJECT' as TypeEnum,
    String: 'STRING' as TypeEnum,
    Timestamp: 'TIMESTAMP' as TypeEnum,
    Uuid: 'UUID' as TypeEnum,
  };
}
