import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModuleAccessDTO, ModuleAccessProductDTO } from '@shared/dto/gateway-secured/models';
import { SettingsState } from '@shared/states/settings.state';

@Injectable({
  providedIn: 'root',
})
export class AuthoritiesService {
  constructor(
    private settingsState: SettingsState,
    private http: HttpClient,
  ) {}

  public getAllAuthorities(): Observable<ModuleAccessDTO[]> {
    return this.http.get<ModuleAccessDTO[]>(`${this.settingsState.apiPath}/authorities`);
  }

  public getDisabledAuthorities(): Observable<ModuleAccessProductDTO[]> {
    return this.http.get<ModuleAccessProductDTO[]>(
      `${this.settingsState.apiPath}/authorities-disabled`,
    );
  }
}
