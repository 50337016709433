import { Pipe, PipeTransform } from '@angular/core';
import { RoleState } from '@shared/states/role.state';
import { AuthoritiesCondition, Authority } from '@shared/types/authority';

@Pipe({
  name: 'hasAuthorities',
})
export class HasAuthoritiesPipe implements PipeTransform {
  constructor(private roleState: RoleState) {}

  public transform(
    checkAuthorities: Authority[],
    condition: AuthoritiesCondition = 'all',
  ): boolean {
    return this.roleState.hasAuthorities(checkAuthorities, condition);
  }
}
