import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { LayoutRouteData, LayoutState } from 'app/layout/layout.state';

export const CanShowFullScreenCanActivateGuard: CanActivateFn = (route) => {
  inject(LayoutState).canShowFullScreen = (route?.data?.layout as LayoutRouteData)
    ?.canShowFullScreen
    ? true
    : false;

  return true;
};

export const CanShowFullScreenCanActivateChildGuard: CanActivateChildFn = (route) => {
  inject(LayoutState).canShowFullScreen = (route?.data?.layout as LayoutRouteData)
    ?.canShowFullScreen
    ? true
    : false;

  return true;
};
