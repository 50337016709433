import { MatDateFormats } from '@angular/material/core';

export const DEFAULT_DATE_INPUT_FORMAT = 'CUSTOM_FORMAT_KEY';

export const DEFAULT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: DEFAULT_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: DEFAULT_DATE_INPUT_FORMAT,
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};
